import React from "react";
import "gantt-task-react/dist/index.css";
import { ViewMode } from "gantt-task-react";
import "./Gantt.css";

export const ViewSwitcher = ({
  onViewModeChange,
  onViewListChange,
  isChecked,
  viewMode,
}) => {
  return (
    <div className="ViewContainer">
      <button
        className={`Button ${
          viewMode === ViewMode.Day ? "active-view" : "view-button"
        }`}
        onClick={() => onViewModeChange(ViewMode.Day)}
      >
        Day
      </button>
      <button
        className={`Button ${
          viewMode === ViewMode.Week ? "active-view" : "view-button"
        }`}
        onClick={() => onViewModeChange(ViewMode.Week)}
      >
        Week
      </button>
      <button
        className={`Button ${
          viewMode === ViewMode.Month ? "active-view" : "view-button"
        }`}
        onClick={() => onViewModeChange(ViewMode.Month)}
      >
        Month
      </button>
      <button
        className={`Button ${
          viewMode === ViewMode.Year ? "active-view" : "view-button"
        }`}
        onClick={() => onViewModeChange(ViewMode.Year)}
      >
        Year
      </button>

      {/* <div className="Switch">
        <label className="Switch_Toggle">
          <input
            type="checkbox"
            defaultChecked={isChecked}
            onClick={() => onViewListChange(!isChecked)}
          />
          <span className="Slider" />
        </label>
        Show Task List
      </div> */}
    </div>
  );
};
