export const DeleteOutlined = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props?.size ? props?.size : "24"}
      height={props?.size ? props?.size : "24"}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M18 6V18.75C18 19.993 16.973 21 15.731 21H8.231C6.988 21 6 19.993 6 18.75V6"
        stroke="#EF4870"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5 6H4.5"
        stroke="#EF4870"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 3H14"
        stroke="#EF4870"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 10V17"
        stroke="#EF4870"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 17V10"
        stroke="#EF4870"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
