export const QuestionCompleted = () => {
  return (
    <svg
      width="117"
      height="117"
      viewBox="0 0 117 117"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="58.5" cy="58.5" r="58.5" fill="#251F34" />
      <path
        d="M50.3334 73.3748H42.6667"
        stroke="#fff"
        strokeWidth="2.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M66.1458 60.4375C70.6442 60.4375 74.2917 64.0849 74.2917 68.5833C74.2917 73.0817 70.6442 76.7292 66.1458 76.7292C61.6474 76.7292 58 73.0817 58 68.5833C58 64.0849 61.6474 60.4375 66.1458 60.4375"
        stroke="#fff"
        strokeWidth="2.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69.314 67L65.3542 70.9598L62.9775 68.5832"
        stroke="#fff"
        strokeWidth="2.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.3334 63.7918H42.6667"
        stroke="#fff"
        strokeWidth="2.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M73.3334 54.2083H42.6667"
        stroke="#fff"
        strokeWidth="2.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M73.3334 44.6248H42.6667"
        stroke="#fff"
        strokeWidth="2.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
