import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import "./chart.css";

const data = [
  { date: "Jul 1", instagram: 60, spotify: 20 },
  { date: "Jul 2", instagram: 30, spotify: 40 },
  { date: "Jul 3", instagram: 58, spotify: 50 },
  { date: "Jul 4", instagram: 70, spotify: 45 },
  { date: "Jul 5", instagram: 60, spotify: 60 },
  { date: "Jul 6", instagram: 65, spotify: 80 },
];

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#333",
          color: "#fff",
          padding: "10px",
          borderRadius: "5px",
        }}
      >
        {/* <p className="label">{`Count : ${payload[0].value}`}</p> */}
        {payload?.map((entry, index) => {
          if (entry.name === "instagram") {
            return (
              <p key={index} className="label">{`Instagram: ${entry.value}`}</p>
            );
          }
          if (entry.name === "spotify") {
            return (
              <p key={index} className="label">{`Spotify: ${entry.value}`}</p>
            );
          }
          return null;
        })}
      </div>
    );
  }

  return null;
};

const Chart = ({ chartData, height }) => {
  const processedData = chartData?.map((item) => ({
    ...item,
    spotify: item.spotify === null ? 0 : item.spotify,
  }));

  return (
    <ResponsiveContainer width="100%" height={height ? height : 300}>
      <LineChart
        data={processedData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" stroke="#444" />
        <XAxis dataKey="date" stroke="#888" width={20} />
        <YAxis stroke="#888" />
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        <Line
          type="monotone"
          dataKey="instagram"
          stroke="#FF4500"
          dot={{ r: 0 }}
          activeDot={{ r: 4 }}
          yAxisId={0}
        />
        <Line
          type="monotone"
          dataKey="spotify"
          stroke="#32CD32"
          dot={{ r: 0 }}
          activeDot={{ r: 4 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default Chart;
