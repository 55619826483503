export const EditOutlined = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 10.8893V15.8102C17.5 16.7435 16.7492 17.5002 15.8225 17.5002H4.1775C3.25083 17.5002 2.5 16.7435 2.5 15.8102V5.0235C2.5 4.09016 3.25083 3.3335 4.1775 3.3335H10"
        stroke={props?.color ? props?.color : "#9E9CAE"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.52344 14.31L9.6326 13.5325C9.77927 13.4958 9.9126 13.42 10.0201 13.3133L17.0118 6.32162C17.6626 5.67079 17.6626 4.61579 17.0118 3.96495L16.8684 3.82162C16.2176 3.17079 15.1626 3.17079 14.5118 3.82162L7.5201 10.8133C7.41344 10.92 7.3376 11.0541 7.30094 11.2008L6.52344 14.31"
        stroke={props?.color ? props?.color : "#9E9CAE"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.1919 5.1416L15.6919 7.6416"
        stroke={props?.color ? props?.color : "#9E9CAE"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.62153 13.5349C9.6332 13.4466 9.6482 13.3591 9.6482 13.2674C9.6482 12.1166 8.7157 11.1841 7.56486 11.1841C7.4732 11.1841 7.3857 11.1999 7.29736 11.2107"
        stroke={props?.color ? props?.color : "#9E9CAE"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
