import axios from "axios";
import { USER_DETAILS } from "../utils/constants";
const userDetails = localStorage.getItem(USER_DETAILS);
let userDetailsJson = userDetails ? JSON.parse(userDetails) : undefined;
const token = userDetailsJson?.token;

export const addTaskToProject = async (params) => {
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_API_ENDPOINT}/tasks?${
        params?.projectId ? "projectId" : "taskId"
      }=${params?.projectId ? params?.projectId : params?.taskId}`,

      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: params,
    });
    return response?.data;
  } catch (err) {
    console.log("err", err);
  }
};
