import React from "react";

export const DownArrow = () => (
    <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2036_346)">
            <path
                d="M0.269969 1.98004L6.66997 9.45004C7.05997 9.91004 7.75997 9.96004 8.21997 9.57004C8.25997 9.53004 8.29997 9.49004 8.33997 9.45004L14.73 1.98004C15.34 1.27004 14.83 0.170044 13.9 0.170044H1.09997C0.159969 0.170044 -0.340031 1.27004 0.269969 1.98004Z"
                fill="#2B3138"/>
        </g>
        <defs>
            <clipPath id="clip0_2036_346">
                <rect width="15" height="9.66" fill="white" transform="translate(0 0.170044)"/>
            </clipPath>
        </defs>
    </svg>
);