export const LocationIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.27246 8.37083V8.2275C4.27246 5.06417 6.83663 2.5 9.99996 2.5V2.5C13.1633 2.5 15.7275 5.06417 15.7275 8.2275V8.37083C15.7275 11.255 12.0758 15.5967 10.6041 17.23C10.28 17.59 9.71996 17.59 9.39579 17.23C7.92413 15.5967 4.27246 11.255 4.27246 8.37083Z"
        stroke="#9E9CAE"
        strokeWidth="1.4468"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.3335 8.29564C8.3335 9.21648 9.07933 9.96231 10.0002 9.96231V9.96231C10.921 9.96231 11.6668 9.21648 11.6668 8.29564V8.26481C11.6668 7.34398 10.921 6.59814 10.0002 6.59814V6.59814C9.07933 6.59814 8.3335 7.34398 8.3335 8.26481"
        stroke="#9E9CAE"
        strokeWidth="1.4468"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
