import { SearchOutlined } from "@ant-design/icons";
import React from "react";
import styled from "styled-components";
import Button from "../Button";

// const { Option } = Select;

const ContentTitleP = styled.p`
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
`;

const StyledSearch = styled.input`
  width: 100%;
`;

const ContentCardWrapperDiv = styled.div`
  margin-top: 20px;
`;

const ContentCardTitleSpan = styled.span`
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
`;

const CardWrapperDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ContentCard = styled.div`
  width: 182px;
  padding: 26px 0 26px 0;
  cursor: pointer;
  border: ${({ selectedProfessional, item }) =>
    selectedProfessional?.fullName === item?.fullName
      ? "1px solid rgba(26, 255, 216, 1)"
      : "1px solid rgba(158, 156, 174, 0.3)"};
  margin: 10px 16px 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(37, 31, 52, 1);
  border-radius: 8px;
`;

const ContentCardImageWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const ContentCardServiceTitleSpan = styled.span`
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: ${({ selectedProfessional, item }) =>
    selectedProfessional?.fullName === item?.fullName
      ? "rgba(255, 255, 255, 1)"
      : "rgba(158, 156, 174, 1)"};
`;

const DividerDiv = styled.div`
  border: 0.5px solid rgba(70, 68, 83, 1);
  margin-top: 38px;
`;

const ButtonWrapper = styled(Button)`
  background-color: rgba(0, 229, 190, 1);
  color: rgba(14, 38, 46, 1);
  border: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  margin-top: 107px;
`;

const ChooseProfessionalStep = ({
  currentStep,
  setCurrentStep,
  setSelectedProfessional,
  selectedProfessional,
  selectedService,
}) => {
  const handleSelectProfessional = (item) => {
    setSelectedProfessional(item);
  };

  // useEffect(() => {
  //   dispatch(
  //     getProfessionalByServiceId({
  //       serviceId: selectedService?._id,
  //     }),
  //   );
  // }, []);

  return (
    <div>
      <ContentTitleP>Choose an professional</ContentTitleP>
      {/* <StyledSearch
        placeholder="Search professional"
        // suffix={<SearchOutlined />}
        suffix={<SearchOutlined style={{ color: "rgba(0, 0, 0, 0.25)" }} />}
        onPressEnter={(e) => console.log(e.target.value)}
      /> */}
      <ContentCardWrapperDiv>
        <CardWrapperDiv>
          {selectedService?.professionals?.map((item, index) => (
            <ContentCard
              onClick={() => handleSelectProfessional(item)}
              selectedProfessional={selectedProfessional}
              item={item}
            >
              <div>
                <ContentCardImageWrapper>
                  <img
                    src={item.imageUrl}
                    className="professional-image"
                    alt="service-banner"
                  />
                </ContentCardImageWrapper>
                <ContentCardServiceTitleSpan
                  selectedProfessional={selectedProfessional}
                  item={item}
                >
                  {item?.fullName}
                </ContentCardServiceTitleSpan>
              </div>
            </ContentCard>
          ))}
        </CardWrapperDiv>
        <DividerDiv />

        <ButtonWrapper
          // disabled={!selectedService}
          onClick={() => setCurrentStep(currentStep + 1)}
          variety="filled"
          className="w-100 p-12"
        >
          Choose event time
        </ButtonWrapper>
      </ContentCardWrapperDiv>
    </div>
  );
};

export default ChooseProfessionalStep;
