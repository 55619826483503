import { CheckIcon } from "@radix-ui/react-icons";
import { toast } from "react-toastify";
import { ErrorIcon, SuccessIcon } from "../../assets/svg/ToastComponentIcon";
import "./Toast.css";

export const notify = (message, type = "default") => {
  const options = {
    position: "bottom-center", // Position the toast at the bottom
    // className: "full-width-toast", // Add a custom class to the toast
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    progressClassName: "toast-progress-bar",
  };

  switch (type) {
    case "info":
      toast.info(message, options);
      break;
    case "success":
      toast(
        <div className="toast-icon-container">
          <SuccessIcon /> <span className="toast-message"> {message}</span>
        </div>,
        { ...options, className: "success-toast" }
      );
      break;
      break;
    case "warning":
      toast.warn(message, options);
      break;
    case "error":
      toast(
        <div className="toast-icon-container">
          <ErrorIcon /> <span className="toast-message"> {message}</span>
        </div>,
        { ...options, className: "error-toast" }
      );
      break;
    default:
      toast(message, options);
      break;
  }
};
