import React, { useState } from "react";
import "./PaymentPlan.css";
import { Logo } from "../../assets/svg/Logo";
import { cardData } from "./data";
import { CheckCircle } from "../../assets/svg/CheckCircle";
import Button from "../../components/Button";
import { Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import { useNavigate } from "react-router-dom";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const PaymentPlan = () => {
  const navigate = useNavigate();
  const stripe = useStripe();
  const [stripeLoaded, setStripeLoaded] = useState(false);
  const elements = useElements();
  const [secretKey, setSecretKey] = useState("");
  const [message, setMessage] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [getStarted, setGetStarted] = useState(false);
  const handleOnClickPlan = (card) => {
    setSelectedPlan(card);
  };

  const handleGetStarted = () => {
    setGetStarted(true);
  };

  return (
    <>
      {!getStarted ? (
        <div className="payment-container">
          <div className="content-title">
            <Logo />
            <h4 className="h4">Choose flexible pricing plan for your team</h4>
          </div>
          <div
            className="card-wrapper align-center"
            style={{ paddingRight: 110, paddingLeft: 110 }}
          >
            {cardData?.map((card, index) => {
              return (
                <div
                  onClick={() => handleOnClickPlan(card)}
                  className={`card ${
                    index === 1 ? "card-selected" : "card"
                  } p-relative`}
                >
                  <div>
                    {index === 1 && (
                      <div
                        className="bg-green-400 text-align-center b-r-t-8 justify-center align-center"
                        style={{ height: 44 }}
                      >
                        <span className="black boldM">POPULAR</span>
                      </div>
                    )}
                    <div
                      className="card-header m-t-8"
                      style={{
                        paddingBottom: 18,
                        paddingTop: index !== 1 ? 8 : 0,
                      }}
                    >
                      <span className="h4">{card.planType}</span>
                      <span className="gray-300 regularS">{card.subtitle}</span>
                    </div>
                    <div className="price">
                      <span className="regularS green-400">$</span>
                      <span className="boldXL green-400">{card?.price} </span>
                      <span className="regularS green-400">/mo</span>
                    </div>
                    <div className="m-t-24">
                      {card?.pros?.map((item, index) => (
                        <div className="card-list">
                          <CheckCircle />{" "}
                          <span className="white regularS padding-h-8">
                            {item?.title}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div
                    className="p-absolute w-90"
                    style={{ bottom: index === 1 ? 50 : 10 }}
                  >
                    <Button
                      className={`flex-row margin-h-16 w-100 boldL  ${
                        index === 1
                          ? "black bg-green-400"
                          : "green-400 bg-transparent border-green-400"
                      }`}
                      onClick={handleGetStarted}
                      height="56px"
                      // variety={`${
                      //   selectedPlan?.id === card?.id ? "filled" : "outlined"
                      // }`}
                    >
                      Get Started
                    </Button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="payment-container">
          <div className="content-title">
            <Logo />
            <div className="m-t-64 flex-column align-center">
              <span className="h4">Payment</span>
              <span className="gray-300">
                Selected plan: {selectedPlan?.planType}
              </span>
            </div>
            <Elements stripe={stripePromise}>
              <CheckoutForm selectedPlan={selectedPlan} navigate={navigate} />
            </Elements>
            <div
              className="m-t-24 cursor-pointer"
              onClick={() => setGetStarted(false)}
            >
              <span className="green-400 regularS">Back to plans</span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PaymentPlan;
