import moment from "moment";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Card from "../Card/Card";
import { RightArrow } from "../../assets/svg/RightArrow";
import { LeftArrow } from "../../assets/svg/LeftArrow";

const CalendarContainer = styled.div`
  font-family: Arial, sans-serif;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
`;

const CalendarTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const CalendarHeader = styled.thead`
  color: white;
`;

const CalendarHeaderCell = styled.th`
  padding: 10px;
  text-align: center;
  color: #5e656b;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
`;

const CalendarBody = styled.tbody``;

const CalendarDay = styled.td`
  color: #5e656b;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  border-radius: 8px;
  background-color: ${(props) =>
    props.isToday
      ? "rgba(26, 255, 216, 1)"
      : "transparent"}; /* Highlight today's date */

  &.empty-cell {
    background-color: #f2f2f2;
  }

  &:hover {
    background-color: #eee;
  }
`;

const WeekButton = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 24px;
  padding: 4px 5px 4px 3px;
  border: 1px solid #5e656b;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 16px;
`;

const TimeTitle = styled.span`
  color: white;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 133.333% */
`;
const AvailableSlots = styled.div`
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const SlotItem = styled.div`
  padding: 8px;
  font-size: 16px;
  border: ${(props) =>
    props?.disabled
      ? "#9E9CAE4D"
      : props.selected
      ? "1px solid #5554A4"
      : "1px solid #ccc"};
  border-radius: 5px;
  cursor: ${(props) => (props?.disabled ? "not-allowed" : "pointer")};
  background-color: ${(props) =>
    props?.disabled ? "#251F34" : " rgba(37, 31, 52, 1)"};
  display: flex;
  flex-wrap: wrap;
  width: 156px;
  justify-content: center;
  margin: 10px 10px 10px 0;
`;

const SelectOptions = styled.div`
  margin-top: 10px;
`;

const SelectButton = styled.button`
  margin-right: 10px;
  background-color: ${(props) => (props.active ? "#007bff" : "transparent")};
  color: ${(props) => (props.active ? "white" : "inherit")};
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;

  &:hover {
    background-color: #007bff;
    color: white;
  }
`;

const TitleSpan = styled.span`
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: #e6e6ea;
`;

const DateChangerDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

const MiniCalendar = ({
  data,
  selectedSlots,
  setSelectedSlots,
  selectedDate,
  setSelectedDate,
  isLoading,
}) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [availableSlots, setAvailableSlots] = useState([]);
  const [selectedOption, setSelectedOption] = useState("allday");
  const [selectedDuration, setSelectedDuration] = useState("3");

  useEffect(() => {
    setAvailableSlots(data?.availableSlots);
  }, [data]);

  const generateCalendarDays = (date) => {
    const calendarDays = [];

    for (let i = -3; i < 4; i++) {
      const day = moment(currentDate).add(i, "days");
      calendarDays.push({
        title: day.format("dd"),
        day: day.format("YYYY-MM-DD"),
      });
    }
    return calendarDays;
  };
  const handlePrevWeek = () => {
    const newDate = new Date(currentDate);
    newDate.setDate(currentDate.getDate() - 7);
    setCurrentDate(newDate);
  };

  const handleNextWeek = () => {
    const newDate = new Date(currentDate);
    newDate.setDate(currentDate.getDate() + 7);
    setCurrentDate(newDate);
  };

  //handle selecting slots
  const handleSlotClick = (slot) => {
    // Toggle the selected state of the slot
    setSelectedSlots(slot);
  };

  return (
    <CalendarContainer>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "16px 0",
        }}
      >
        <TitleSpan>Choose your event time</TitleSpan>
        <DateChangerDiv>
          <WeekButton onClick={handlePrevWeek}>
            <LeftArrow />
          </WeekButton>
          <span>{moment(currentDate).format("MMMM YYYY")}</span>
          <WeekButton onClick={handleNextWeek}>
            <RightArrow />
          </WeekButton>
        </DateChangerDiv>
      </div>
      <CalendarTable>
        <CalendarHeader>
          <tr>
            {generateCalendarDays()?.map((item) => (
              <CalendarHeaderCell>{item.title}</CalendarHeaderCell>
            ))}
          </tr>
        </CalendarHeader>
        <CalendarBody>
          {/* Render the calendar days for the current week */}
          {/* {generateCalendarDays(currentDate)} */}
          {generateCalendarDays()?.map((item) => (
            <CalendarDay
              onClick={(e) => setSelectedDate(item?.day)}
              isToday={selectedDate === moment(item?.day).format("YYYY-MM-DD")}
            >
              {moment(item.day).format("D")}
            </CalendarDay>
          ))}
        </CalendarBody>
      </CalendarTable>
      {/* <SelectOptions>
        <SelectButton
          active={selectedOption === "allday"}
          onClick={() => handleSelectOption("allday")}
        >
          All-Day
        </SelectButton>
      </SelectOptions>
       */}
      <TimeTitle>Time</TimeTitle>
      <Card
        style={{ backgroundColor: "transparent" }}
        borderless
        loading={isLoading}
      >
        <AvailableSlots>
          {/* Render available slots data here */}
          {availableSlots?.map((slot, index) => (
            <SlotItem
              key={index}
              disabled={!slot?.status}
              selected={selectedSlots === slot?.time}
              onClick={() => handleSlotClick(slot?.time)}
            >
              {slot?.time}
            </SlotItem>
          ))}
        </AvailableSlots>
      </Card>
    </CalendarContainer>
  );
};

export default MiniCalendar;
