import { ChevronRightIcon, ChevronUpIcon } from "@radix-ui/react-icons";
import React, { useState } from "react";
import Card from "../../components/Card/Card";
import { BadgeData } from "./DATA";

const BadgePage = () => {
  const [show, setShow] = useState(false);
  const [showIndex, setShowIndex] = useState(null);

  return (
    <div>
      <span className="gray-400 regularL">Badges List</span>
      <div className="flex-row justify-space-between ">
        <Card className={"w-70 p-12"}>
          {BadgeData?.map((item, index) => (
            <Card className={"quick-add-task-container m-8 p-8 "}>
              <div>
                <div className="flex-row justify-space-between">
                  <div className="flex-row align-center">
                    {item?.icon}
                    <div className="flex-column m-4">
                      <span className="gray-300 regularS">{item?.label}</span>
                      <span className="white boldL">{item?.title}</span>
                    </div>
                  </div>
                  <div
                    className={`cursor-pointer animate ${
                      show ? "max-h-full" : "max-h-0"
                    }`}
                    onClick={() => {
                      setShow(show && showIndex === index ? false : true);
                      setShowIndex(index);
                    }}
                  >
                    {showIndex === index && show ? (
                      <ChevronUpIcon color="white" />
                    ) : (
                      <ChevronRightIcon color="white" />
                    )}
                  </div>
                </div>
                <div
                  className={`animate ${
                    show && showIndex === index ? "opacity-100" : "opacity-0"
                  }`}
                >
                  <span className="gray-300">{item?.description}</span>
                </div>
                {/* )} */}
              </div>
            </Card>
          ))}
        </Card>
        <Card className="w-20 p-12">
          <span className="gray-300">All Badges</span>
        </Card>
      </div>
    </div>
  );
};

export default BadgePage;
