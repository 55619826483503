export const PlusIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clipPath="url(#clip0_83_3742)">
        <rect width="20" height="20" rx="10" fill="#1AFFD8" />
        <path
          fillRule="evenodd"
          clip-rule="evenodd"
          d="M9.45817 15.6251C9.45817 16.0393 9.79396 16.3751 10.2082 16.3751C10.6224 16.3751 10.9582 16.0393 10.9582 15.6251V10.9584H15.6248C16.0391 10.9584 16.3748 10.6226 16.3748 10.2084C16.3748 9.7942 16.0391 9.45842 15.6248 9.45842H10.9582V4.79175C10.9582 4.37753 10.6224 4.04175 10.2082 4.04175C9.79396 4.04175 9.45817 4.37753 9.45817 4.79175V9.45842H4.7915C4.37729 9.45842 4.0415 9.7942 4.0415 10.2084C4.0415 10.6226 4.37729 10.9584 4.7915 10.9584H9.45817V15.6251Z"
          fill="#0E262E"
        />
      </g>
      <defs>
        <clipPath id="clip0_83_3742">
          <rect width="20" height="20" rx="10" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const MinusIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_83_3833)">
        <rect width="20" height="20" rx="10" fill="#1AFFD8" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.04175 10.2083C4.04175 9.79404 4.37753 9.45825 4.79175 9.45825H15.6251C16.0393 9.45825 16.3751 9.79404 16.3751 10.2083C16.3751 10.6225 16.0393 10.9583 15.6251 10.9583H4.79175C4.37753 10.9583 4.04175 10.6225 4.04175 10.2083Z"
          fill="#0E262E"
        />
      </g>
      <defs>
        <clipPath id="clip0_83_3833">
          <rect width="20" height="20" rx="10" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
