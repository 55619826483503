export const API_END_POINTS = {
  login: "/auth/login",
  register: "/auth/register",
  forgotPassword: "/auth/forgot-password",
  otpVerification: "/auth/verify-otp",
  resendOtp: "/auth/request-otp",
  forgotPasswordVerify: "/auth/verify-reset-password-otp",
  resetPassword: "/auth/reset-password",
  onboarding: "/auth/onboarding",
  dashboard: "/dashboard",
  profile: "/users/profile",
  project: "/tasks/projects",
  getServiceProfessionals: "/booking/service-professionals",
  getAvailableSlots: "/booking/available-slots",
  booking: "/booking",
  projectCategory: "/categories",
  paymentIntent: "/stripe/create-payment-intent",
  tasks: "/tasks",
  events: "/events",
  storeInstagramToken: "/social/instagram/refresh-token",
  storeSpotifyToken: "/social/spotify/auth-token",
  instagramOverview: "/social/overview/instagram",
  spotifyOverview: "/social/overview/spotify",
  socialOverview: "/social/overview/all",
  uploadProfileImage: "/users/profile-image",
  stripeSubscription: "/stripe/create-subscription",
  googleSignIn: "/auth/google-auth",
  unlinkSocial: "/social/unlink",
  charts: "/charts/progress-chart",
  timeline: "/timeline",
  googleCalendarAuth: "/social/google/calendar-oauth",
  googleCalendarLinkStatus: "/social/google/calendar-status",
};
