import React from "react";
import List from "../../components/Booking/List";

const BookingPage = () => {
  return (
    <div>
      <List />
    </div>
  );
};

export default BookingPage;
