export const AddPreIcon = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.4999 21C7.65359 21.0239 3.65854 17.206 3.46279 12.3635C3.26705 7.52107 6.94091 3.39314 11.7734 3.02579C16.6058 2.65845 20.8615 6.18361 21.3999 11"
        stroke="#0E262E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.6929 20.7071L22.2071 17.1929C22.5976 16.8024 22.5976 16.1692 22.2071 15.7787L20.7213 14.2928C20.3307 13.9023 19.6976 13.9023 19.3071 14.2928L15.7929 17.8071C15.6054 17.9946 15.5 18.249 15.5 18.5142V21H17.9858C18.251 21 18.5054 20.8946 18.6929 20.7071Z"
        stroke="#0E262E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6213 12H10.3787"
        stroke="#0E262E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 9.87869V14.1213"
        stroke="#0E262E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
