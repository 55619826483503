import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useRef, useState } from "react";
import CheckoutForm from "./CheckoutForm";
import { useMutationCreate } from "../../hooks/crud/mutation.create";
import { API_END_POINTS } from "../../utils/api-endpoint";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Payment = ({ availableListPayload, postBookingResponse }) => {
  const paymentElementRef = useRef();
  const [secretKey, setSecretKey] = useState("");
  const [isPaymentComplete, setPaymentComplete] = useState(false);
  const stripe = useStripe();
  const [stripeLoaded, setStripeLoaded] = useState(false);
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  // const { payload: postBookingResponse } = useSelector(
  //   (state) => state.postBooking
  // );

  const { mutate: createPaymentIntent } = useMutationCreate(
    API_END_POINTS.paymentIntent
  );

  const options = {
    clientSecret: secretKey,
  };

  const handlePaymentChange = async (event) => {
    setPaymentComplete(event.complete);
  };

  const handlePay = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      console.log(
        "Stripe or Elements is not initialized. Please check the Elements provider setup."
      );
      return;
    }
    setIsLoading(true);

    try {
      const paymentElement = elements.getElement(PaymentElement);

      if (!paymentElement) {
        console.log(
          "PaymentElement is not mounted. Please check if the PaymentElement is rendered and the Elements provider is properly set up."
        );
        return;
      }

      const result = await stripe.confirmPayment({
        elements: paymentElement,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: "",
        },
        redirect: "if_required",
      });

      console.log(result);
      setIsLoading(false);
    } catch (error) {
      console.error("Error confirming payment:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (postBookingResponse?.data?.bookingId) {
      createPaymentIntent(
        {
          items: availableListPayload?.data?.price.toString(),
          bookingId: postBookingResponse?.data?.bookingId,
        },
        {
          onSuccess(data) {
            setSecretKey(data?.data?.clientSecret);
          },
        }
      );
    }
  }, [postBookingResponse]);

  const paymentElementOptions = {
    layout: "tabs",
  };

  useEffect(() => {
    if (stripe) {
      setStripeLoaded(true);
    }
  }, [stripe]);

  return (
    <div>
      {stripeLoaded && stripePromise && secretKey && (
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm
            message={message}
            setMessage={setMessage}
            availableListPayload={availableListPayload}
          />
        </Elements>
      )}
      {message && <div id="payment-message">{message}</div>}
    </div>
  );
};

export default Payment;
