import React from "react";
import "./Button.css";
import Loader from "../Loader/Loader";

/**
 * Button component
 * @param {Object} props - Button properties
 * @param {string} props.className - Additional CSS classes
 * @param {("filled"|"outlined")} props.variety - Button variety. Can be either 'filled' or 'outlined'
 * @param {React.ReactNode} props.children - Button content
 * @param {Object} props.otherProps - Other properties
 * @param {CSSPropertyRule} props.otherProps.style - Button style
 * @returns {React.Component} Button component
 */
const Button = (props) => {
  const { className, variety, height, isLoading, ...otherProps } = props;
  return (
    <button
      className={`${className} button ${variety}-button text-sm md:text-md lg:text-lg`}
      {...otherProps}
      style={{ height: height ? height : "40px", ...props.style }}
    >
      {isLoading ? <Loader /> : props.children}
    </button>
  );
};

export default Button;
