import React from "react";
import { Audio, BallTriangle, Rings } from "react-loader-spinner";
import "./Loader.css";

const Loader = ({ size }) => {
  return (
    <Rings
      visible={true}
      height={size ? size : "80"}
      width={size ? size : "80"}
      color="var(--Genesis-White)"
      ariaLabel="rings-loading"
      wrapperStyle={{}}
      wrapperClass=""
    />
  );
};

export default Loader;

export const BallTriangleLoader = () => {
  return (
    <BallTriangle
      height={100}
      width={100}
      radius={5}
      color="var(--Genesis-Blue-200)"
      ariaLabel="ball-triangle-loading"
      wrapperStyle={{}}
      wrapperClass=""
      visible={true}
    />
  );
};

export const AudioLoader = () => {
  return (
    <Audio
      height="50"
      width="50"
      color="var(--Genesis-Blue-200)"
      ariaLabel="audio-loading"
      wrapperStyle={{}}
      wrapperClass="wrapper-class"
      visible={true}
    />
  );
};
