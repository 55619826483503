import { Logo, MobileLogo } from "../../assets/svg/Logo";
import React, { useEffect, useState } from "react";
import { sidebarData } from "../../utils/sidebar-menu";
import { useLocation, useNavigate } from "react-router-dom";
import "./Sidebar.css";
import { SidebarHideIcon } from "../../assets/svg/SidebarHideIcon";
import { useIsSidebarOpen } from "../../hooks/ui/IsSidebarOpen";
import useWindowSize from "../../hooks/ui/useWindowSize";
import Sizes from "../../utils/break-points";

const Sidebar = (props) => {
  const { isSidebarOpen, setIsSidebarOpen } = useIsSidebarOpen();

  const { width } = useWindowSize();

  // const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isSmallWindow, setIsSmallWindow] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallWindow(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      <div className={`sidebar `} {...props}>
        {/*logo*/}
        {isSmallWindow ? (
          <div
            style={{
              margin: "1.37rem",
            }}
          >
            <MobileLogo />
          </div>
        ) : (
          <div
            style={{
              margin: "1.37rem",
            }}
          >
            <Logo width={width <= Sizes.Large.value ? "120px" : "153px"} />
          </div>
        )}

        {sidebarData.map((item) => {
          if (item.type === "divider") {
            return <hr />;
          }
          return <SidebarItem item={item} isSmallWindow={isSmallWindow} />;
        })}
      </div>
    </div>
  );
};

export { Sidebar };

const SidebarItem = ({ item, isSmallWindow }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  let iconStyle;

  if (location.pathname === item.path) {
    iconStyle = {
      fill: "black",
    };
  }

  if (isHovered && location.pathname !== item.path) {
    iconStyle = {
      fill: "white",
    };
  }

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className="sidebar-item"
      style={{
        backgroundColor: location.pathname.includes(item.path)
          ? "#654DF6"
          : null,
      }}
      onClick={() => {
        navigate(item.path);
      }}
    >
      <div className="sidebar-icon">
        {React.cloneElement(item.icon, iconStyle)}
      </div>
      {!isSmallWindow && item.label}
    </div>
  );
};
