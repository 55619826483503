import React from "react";
import { Navigate } from "react-big-calendar";

const CustomToolbar = ({ label, onNavigate, onView }) => {
  const navigate = (action) => {
    onNavigate(action);
  };

  const view = (viewName) => {
    onView(viewName);
  };

  return (
    <div className="rbc-toolbar">
      <span className="rbc-btn-group">
        <button type="button" onClick={() => navigate(Navigate.TODAY)}>
          Today
        </button>
        <button type="button" onClick={() => navigate(Navigate.PREVIOUS)}>
          Back
        </button>
        <button type="button" onClick={() => navigate(Navigate.NEXT)}>
          Next
        </button>
      </span>
      <span className="rbc-toolbar-label">{label}</span>
      <span className="rbc-btn-group">
        <button type="button" onClick={() => view("month")}>
          Month
        </button>
        <button type="button" onClick={() => view("week")}>
          Week
        </button>
        <button type="button" onClick={() => view("day")}>
          Day
        </button>
        {/* Remove or comment out the following line to remove the Agenda view button */}
        {/* <button type="button" onClick={() => view('agenda')}>Agenda</button> */}
      </span>
    </div>
  );
};

export default CustomToolbar;
