import React, { useEffect } from "react";
import styled from "styled-components";
import Button from "../Button";
import { useDataQuery } from "../../hooks/crud/data.query";
import { API_END_POINTS } from "../../utils/api-endpoint";

// const { Option } = Select;

const ContentTitleP = styled.p`
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
`;

const ContentSubTitleP = styled.p`
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
`;

const ContentCardWrapperDiv = styled.div`
  margin-top: 20px;
`;

const ContentCardTitleSpan = styled.span`
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
`;

const CardWrapperDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ContentCard = styled.div`
  width: 182px;
  padding: 26px 0 26px 0;
  cursor: pointer;
  border: ${({ selectedService, item }) =>
    selectedService?.title === item?.title
      ? "1px solid rgba(26, 255, 216, 1)"
      : "1px solid rgba(158, 156, 174, 0.3)"};
  margin: 10px 16px 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(37, 31, 52, 1);
  border-radius: 8px;
`;

const ContentCardImageWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const ContentCardServiceTitleSpan = styled.span`
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: ${({ selectedService, item }) =>
    selectedService?.title === item?.title
      ? "rgba(255, 255, 255, 1)"
      : "rgba(158, 156, 174, 1)"};
`;

const DividerDiv = styled.div`
  border: 0.5px solid rgba(70, 68, 83, 1);
  margin-top: 38px;
`;

const ButtonWrapper = styled(Button)`
  background-color: rgba(0, 229, 190, 1);
  color: rgba(14, 38, 46, 1);
  border: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  margin-top: 107px;
`;

const SelectHoursWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  align-items: center;
`;

const SelectTitleSpan = styled.span`
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
`;

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
  margin-left: 25px;
`;

const hoursData = [
  {
    id: 1,
    label: "1",
  },
  { id: 2, label: "2" },
  { id: 3, label: "3" },
  { id: 4, label: "4" },
  { id: 5, label: "5" },
  { id: 6, label: "6" },
];
const ServiceStep = ({
  currentStep,
  setCurrentStep,
  selectedService,
  setSelectedService,
  handleHoursChange,
}) => {
  // const dispatch = useDispatch();
  // const { payload, loading: serviceListLoading } = useSelector(
  //   (state) => state.services,
  // );

  const { data: serviceData } = useDataQuery({
    url: API_END_POINTS.getServiceProfessionals,
    params: {},
  });
  const handleSelectService = (item) => {
    setSelectedService(item);
  };

  useEffect(() => {
    // dispatch(getAllService());
  }, []);
  return (
    <div>
      <ContentTitleP>
        Booking a producer or visual artist for a creative service.
      </ContentTitleP>
      <ContentSubTitleP>
        We always make our customers happy by providing many choices.
      </ContentSubTitleP>
      <ContentCardWrapperDiv>
        <ContentCardTitleSpan>Choose Service</ContentCardTitleSpan>
        <CardWrapperDiv>
          {serviceData?.data?.map((item, index) => (
            <ContentCard
              onClick={() => handleSelectService(item)}
              selectedService={selectedService}
              item={item}
            >
              <div>
                <ContentCardImageWrapper>
                  <img
                    src={item.logoUrl}
                    className="professional-image"
                    alt="service-banner"
                  />
                </ContentCardImageWrapper>
                <ContentCardServiceTitleSpan
                  selectedService={selectedService}
                  item={item}
                >
                  {item?.title}
                </ContentCardServiceTitleSpan>
              </div>
            </ContentCard>
          ))}
        </CardWrapperDiv>
        <DividerDiv />
        <SelectHoursWrapper>
          <SelectTitleSpan>Select number of hours</SelectTitleSpan>
          <SelectWrapper>
            <select
              id="hours"
              labelInValue
              // status={status.service}
              placeholder="Select Services"
              onChange={handleHoursChange}
              style={{
                width: "100%",
                backgroundColor: "rgba(37, 31, 52, 1)",
                color: "rgba(255, 255, 255, 1)",
                padding: 12,
              }}
              required
            >
              {hoursData?.map((item, index) => (
                <option
                  style={{ fontWeight: 300 }}
                  value={item?.label}
                  label={item?.label}
                >
                  {item?.label} hour
                </option>
              ))}

              {/* Add more options as needed */}
            </select>
          </SelectWrapper>
        </SelectHoursWrapper>
        <ButtonWrapper
          disabled={!selectedService}
          onClick={() => setCurrentStep(currentStep + 1)}
          variety="filled"
          className="w-100 p-12"
        >
          Choose an professional
        </ButtonWrapper>
      </ContentCardWrapperDiv>
    </div>
  );
};

export default ServiceStep;
