export const PaymentCompletedIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
    >
      <path
        d="M23.0003 41.6877C17.7084 41.6877 13.417 37.3962 13.417 32.1043C13.417 26.8124 17.7084 22.521 23.0003 22.521C28.2942 22.521 32.5837 26.8124 32.5837 32.1043C32.5837 37.3962 28.2942 41.6877 23.0003 41.6877"
        stroke="#1AFFD8"
        strokeWidth="2.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.2599 29.9751L21.9354 35.2996L18.7422 32.1045"
        stroke="#1AFFD8"
        strokeWidth="2.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.5837 32.1042H37.3753C39.4932 32.1042 41.2087 30.3887 41.2087 28.2708V11.0208C41.2087 8.90292 39.4932 7.1875 37.3753 7.1875H8.62533C6.50741 7.1875 4.79199 8.90292 4.79199 11.0208V28.2708C4.79199 30.3887 6.50741 32.1042 8.62533 32.1042H13.417"
        stroke="#1AFFD8"
        strokeWidth="2.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.79199 14.5667H41.2087"
        stroke="#1AFFD8"
        strokeWidth="2.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
