import React from "react";

export const RightArrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
  >
    <path d="M11.5 8L5.5 12V4L11.5 8Z" fill="#5E656B" />
  </svg>
);
