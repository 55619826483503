import "../Signup/Signup.css";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import loginImage from "../../../assets/png/login.png";
import { Logo } from "../../../assets/svg/Logo";
import "react-image-crop/dist/ReactCrop.css";
import Button from "../../../components/Button";
import { questionData } from "./DATA";
import { Controller, useForm } from "react-hook-form";
import Modal from "../../../components/Model/Model";
import { QuestionCompleted } from "../../../assets/svg/QuestionCompleted";
import { APP_ROUTES } from "../../../utils/app-routes";
import { API_END_POINTS } from "../../../utils/api-endpoint";
import { useMutationCreate } from "../../../hooks/crud/mutation.create";
import { USER_DETAILS } from "../../../utils/constants";
import { InfoCircledIcon } from "@radix-ui/react-icons";
import "./Tooltip.css";

const OnboardingQuestion = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [value, setValue] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    const storedPage = localStorage.getItem("onboardingPage");
    if (storedPage !== null && storedPage !== undefined && storedPage !== "undefined") {
      setPage(parseInt(storedPage));
    }
  }, []);

  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    reset,
  } = useForm({});
  const { mutate: submitData } = useMutationCreate(API_END_POINTS.onboarding);

  const onSubmit = (data) => {
    submitData({
      questionLabel: questionData?.[page]?.label,
      answer: data[questionData?.[page]?.name],
    });
    if (page === questionData.length - 1) {
      let userDetails = localStorage.getItem(USER_DETAILS);
      userDetails = JSON.parse(userDetails);
      userDetails.data.isOnboarded = true;
      localStorage.setItem(USER_DETAILS, JSON.stringify(userDetails));
      localStorage.removeItem("onboardingPage");
      setIsModalVisible(true);
    } else {
      localStorage.setItem('onboardingPage', page + 1);
      setPage(page + 1);
      reset();
    }
  };

  return (
    <>
      <div className="w-full flex">
        <div className="login-banner w-50">
          <img className="w-full h-screen" src={loginImage} alt="Login"></img>
        </div>

        <div className="box w-1/2">
          <div className="form-side">
            <div
              style={{
                height: "3.125rem;",
                width: "11.53rem",
                marginBottom: "3.12rem",
              }}
            >
              <Logo />
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div>
                <label className="question-label">
                  {questionData?.[page]?.label}
                  <span className="tooltip-container">
                    <InfoCircledIcon className="info-icon" />
                    <span className="tooltip-box">
                      {questionData?.[page]?.tooltipText || "This question helps us understand your preferences."}
                    </span>
                  </span>
                </label>
                <Controller
                  name="username"
                  control={control}
                  render={({ field }) => (
                    <input
                      className="w-full mt-4"
                      placeholder={questionData?.[page]?.placeholder}
                      type="text"
                      onChange={(e) => setValue(e.target.value)}
                      {...register(questionData?.[page]?.name, {
                        required: "This field is required",
                        validate: (value) => {
                          if (value.startsWith(" ")) {
                            return questionData?.[page]?.validationMessages?.startsWithSpace;
                          } else if (value === "") {
                            return questionData?.[page]?.validationMessages?.blank;
                          }
                          return true;
                        }
                      })}
                      required
                    />
                    )}
                />
                <p
                  style={{
                    color: "var(--App-Red)",
                  }}
                >
                  {errors[questionData?.[page]?.name] && (
                    <p>{errors[questionData?.[page]?.name]?.message}</p>
                  )}
                </p>
              </div>
              <Button type="submit" variety="primary" className="w-100 mt-4">
                Next
              </Button>
            </form>
          </div>
          <Modal
            show={isModalVisible}
            title="Thank you"
            titleIcon={<QuestionCompleted />}
            onClose={() => setIsModalVisible(false)}
          >
            <div className="text-align-center">
              <span className="white p-24">
                Thank you for submitting your answers!
              </span>
            </div>
            <div className="justify-center align-center m-t-24">
              <Button
                variety="primary"
                onClick={() => navigate(APP_ROUTES.root)}
                className="w-60"
              >
                Go Home
              </Button>
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default OnboardingQuestion;