import { useMutation, useQueryClient } from "react-query";
import { baseRepository } from "../../repositories/base";

export const useMutationDelete = (url, id) => {
  const mutationRes = useMutation((variables) => {
    return baseRepository
      .delete(`${url}/${id}`, variables)
      .then((res) => res.data);
  });

  const mutate = (variables, options) => {
    mutationRes.mutate(variables, { ...options });
  };

  const queryClient = useQueryClient();

  const invalidateQueries = (querykey) => {
    queryClient.invalidateQueries([querykey?.url]);
  };

  const returnItem = {
    data: mutationRes.data,
    isLoading: mutationRes.isLoading,
    invalidateQueries,
    mutate,
  };

  return returnItem;
};
