export const priorityData = [
  { id: 1, label: "Low", value: "Low" },
  { id: 2, label: "Medium", value: "Medium" },
  { id: 3, label: "High", value: "High" },
];

export const statusData = [
  { id: 1, label: "Incomplete", value: "Incomplete" },
  { id: 2, label: "Completed", value: "Completed" },
];
