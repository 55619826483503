// Keys for local storage
export const USER_DETAILS = "user_details";
// UI state
export const SIGNUP_UI = "signup";
export const CONFIRM_SIGNUP_UI = "confirm_signip";
export const EMAIL_INPUT_UI = "input_email";
export const NEW_PASSWORD_INPUT_UI = "input_new_password";

export const NEW_PASSWORD_REQUIRED = "NEW_PASSWORD_REQUIRED";

export const ROLES_KEYS = "roles";

export const TAX_RATE = 6;

export const GENESIS_USER_TOKEN = "GENESIS_USER_TOKEN";
export const GENESIS_USER_EMAIL = "GENESIS_USER_EMAIL";
export const GENESIS_USER_SIGNUP = "GENESIS_USER_SIGNUP";

export const INSTAGRAM_TOKEN = "INSTAGRAM_TOKEN";
