import React, { useState } from "react";
import { useNavigate } from "react-router";
import { Outlet } from "react-router-dom";
import Card from "../../components/Card/Card";
import { MetricsCardComponent } from "../../components/Metrics/MetricsCard";
import { useDataQuery } from "../../hooks/crud/data.query";
import { API_END_POINTS } from "../../utils/api-endpoint";
import Chart from "../../components/Chart/LineChart";
import moment from "moment";
import FormSelect from "../../components/Select/FormSelect";
import { useForm } from "react-hook-form";
import { ChartDropDown } from "../../constants/constant";
import BoxReveal from "src/components/magicui/box-reveal";
const Social = () => {
  const navigate = useNavigate();
  const [selectedChartValue, setSelectedChartValue] = useState("Week");
  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    reset,
  } = useForm({});
  const { data: userData, isFetching } = useDataQuery({
    url: API_END_POINTS.profile,
    params: {},
  });
  const { data: socialOverviewData } = useDataQuery({
    url: API_END_POINTS.socialOverview,
    params: {},
  });
  const { data: chartData } = useDataQuery({
    url: API_END_POINTS.charts,
    params: {
      startDate:
        selectedChartValue === "Monthly"
          ? moment().subtract(1, "month").format("YYYY-MM-DD")
          : selectedChartValue === "Yearly"
          ? moment().subtract(1, "year").format("YYYY-MM-DD")
          : moment().subtract(6, "days").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
    },
  });

  return (
    <div>
      <Outlet></Outlet>
      <span className="gray-400 regularL">
        Task lists, event vibes, and social beats
      </span>
      <div>
        <Card className={"card-second-wrapper"}>
          <span className="white regularL p-24">Overview</span>
          <div className="flex-row justify-space-between">
            {
              // isFetching ? (
              //   <div className="flex-row align-center">
              //     <Loader />
              //   </div>
              // ) : (
              socialOverviewData?.data
                ?.filter((filter) => filter?.isActive)
                ?.map((item, index) => (
                  <MetricsCardComponent
                    platformName={item.platformName}
                    audienceCount={item?.audienceCount}
                    navigate={navigate}
                    isLinked={item?.isLinked}
                  />
                ))
              // )
            }
          </div>
        </Card>
        <div className="card-second-wrapper p-8">
          <div className="flex justify-between align-top">
            <span className="white text-2xl">Your Progress</span>
            <div className="w-20 mr-10">
              <FormSelect
                control={control}
                label="Status"
                id="status"
                options={ChartDropDown}
                onChange={(e) => {
                  if (e) {
                    setSelectedChartValue(e.value);
                  } else {
                    setSelectedChartValue("");
                  }
                }}
                // value={"Low"}
                name="interval"
                placeholder={selectedChartValue}
              />
            </div>
          </div>
          {chartData?.data && (
            <BoxReveal width="100%" boxColor={"transparent"} duration={0.5}>
              <div className="mt-4">
                <Chart chartData={chartData?.data} height={500} />
              </div>
            </BoxReveal>
          )}
        </div>
      </div>
    </div>
  );
};

export default Social;
