import { yupResolver } from "@hookform/resolvers/yup";
import { useGoogleLogin } from "@react-oauth/google";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router";
import * as yup from "yup";
import loginImage from "../../../assets/png/login.png";
import {
  EyeCloseOutlined,
  EyeOpenOutlined,
} from "../../../assets/svg/EyeOutlined";
import { Logo } from "../../../assets/svg/Logo";
import Button from "../../../components/Button";
import { notify } from "../../../components/Toast/ToastComponent";
import { useMutationCreate } from "../../../hooks/crud/mutation.create";
import { API_END_POINTS } from "../../../utils/api-endpoint";
import { APP_ROUTES } from "../../../utils/app-routes";
import {
  GENESIS_USER_EMAIL,
  GENESIS_USER_SIGNUP,
  GENESIS_USER_TOKEN,
  USER_DETAILS,
} from "../../../utils/constants";
import { saveState } from "../../../utils/storageUtil";
import "../LoginPage/LoginPage.css";

const PasswordReset = () => {
  const navigate = useNavigate();
  const [headers, setHeaders] = useState({}); //[1
  const { mutate: googleAuth } = useMutationCreate(
    API_END_POINTS.googleSignIn,
    headers
  );

  const login = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      setHeaders({ Authorization: `Bearer ${codeResponse.access_token}` });
      setTimeout(() => {
        googleAuth(
          {},
          {
            onSuccess(res) {
              if (
                res?.data?.isFirstTimeSocialLogin ||
                !res?.data?.isPaymentVerified
              ) {
                localStorage.setItem(USER_DETAILS, JSON.stringify(res));
                localStorage.setItem(GENESIS_USER_TOKEN, res?.token);
                saveState(GENESIS_USER_SIGNUP, true);
                saveState(GENESIS_USER_EMAIL, res?.data?.emailAddress);
                navigate(APP_ROUTES.paymentPlan, {
                  email: res?.data?.emailAddress,
                  token: res?.token,
                });
                notify("Please complete your payment to continue", "success");
              } else {
                saveState(GENESIS_USER_TOKEN, res?.token);
                saveState(GENESIS_USER_SIGNUP, false);
                saveState(GENESIS_USER_EMAIL, res?.data?.emailAddress);
                navigate(APP_ROUTES.root);
                localStorage.setItem(USER_DETAILS, JSON.stringify(res));
                notify(res?.message, "success");
              }
            },
          },
          {
            onError(err) {
              notify(err?.message, "error");
              console.log("err", err);
            },
          }
        );
      }, [400]);
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  return (
    <>
      <div className="w-full flex">
        <div className="login-banner w-50">
          <img className="w-full h-screen" src={loginImage} alt="Login"></img>
        </div>

        <div className="box">
          <div className="form-side">
            <div
              style={{
                height: "3.125rem;",
                width: "11.53rem",
                marginBottom: "3.12rem",
              }}
            >
              <Logo />
            </div>
            <div>
              <span className="h4">Reset Password</span>
            </div>

            <div className="w-full">
              <PasswordResetForm navigate={navigate} />
            </div>
            <div
              className="text-align-center cursor-pointer m-t-12"
              onClick={() => navigate(APP_ROUTES.signup)}
            >
              <span className="gray-300">Don't have an account?</span>
              <span className="green-400 semiboldM"> Sign Up</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PasswordReset;

const schema = yup.object().shape({
  newPassword: yup.string().required("New Password is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

const PasswordResetForm = ({ navigate }) => {
  const { state } = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const {
    mutate: resetPassword,
    isLoading,
    error,
  } = useMutationCreate(API_END_POINTS.resetPassword);
  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    resetPassword(
      { newPassword: data?.newPassword, otp: state?.otp, email: state?.email },
      {
        onSuccess(res) {
          navigate(APP_ROUTES.login);

          notify(res?.message, "success");
        },
        onError: (err) => {
          notify(err?.error?.message, "error");
        },
      }
    );
  };

  return (
    <form className="m-t-24 w-full" onSubmit={handleSubmit(onSubmit)}>
      <div>
        <Controller
          name="newPassword"
          control={control}
          render={({ field }) => (
            <div style={{ position: "relative", marginTop: 24, width: "100%" }}>
              <input
                className="w-full"
                placeholder="New Password"
                type={showPassword ? "text" : "password"}
                {...field}
                {...register("newPassword")}
              />
              <span
                style={{
                  position: "absolute",
                  right: "15px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
                onClick={() => setShowPassword(!showPassword)}
              >
                {!showPassword ? <EyeOpenOutlined /> : <EyeCloseOutlined />}{" "}
                {/* Change the icon based on the state variable */}
              </span>
            </div>
          )}
        />
        <p
          style={{
            color: "var(--App-Red)",
          }}
        >
          {errors.newPassword?.message}
        </p>
      </div>

      <div>
        <Controller
          name="confirmPassword"
          control={control}
          render={({ field }) => (
            <div style={{ position: "relative", marginTop: 24, width: "100%" }}>
              <input
                className="w-full"
                placeholder="Confirm Password"
                type={showConfirmPassword ? "text" : "password"}
                {...field}
                {...register("confirmPassword")}
              />
              <span
                style={{
                  position: "absolute",
                  right: "15px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
                onClick={() => setConfirmShowPassword(!showConfirmPassword)}
              >
                {!showConfirmPassword ? (
                  <EyeOpenOutlined />
                ) : (
                  <EyeCloseOutlined />
                )}{" "}
                {/* Change the icon based on the state variable */}
              </span>
            </div>
          )}
        />
        <p
          style={{
            color: "var(--App-Red)",
          }}
        >
          {errors.confirmPassword?.message}
        </p>
      </div>

      <Button
        type="submit"
        variety="filled"
        className="primary-button w-100 black m-t-24"
        height={"56px"}
        isLoading={isLoading}
      >
        Continue
      </Button>
    </form>
  );
};
