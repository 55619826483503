import { Cross1Icon } from "@radix-ui/react-icons";
import React from "react";
import { CheckCircle } from "../../assets/svg/CheckCircle";
import { DeleteOutlined } from "../../assets/svg/DeleteOutlined";
import Button from "../Button";

const Modal = ({
  isOpen,
  onClose,
  children,
  className,
  title,
  handleMark,
  completed,
  handleDeleteTask,
  isEditButton,
  onEditClick,
  completionStatus,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 999,
      }}
    >
      <div
        className={`${className}`}
        style={{
          width: "60%",
          position: "relative",
          background: "rgb(30,26,42)",
          height: "70%",
        }}
      >
        <div
          className={`${className} flex-row align-center justify-space-between p-20 border-b-1`}
          style={{}}
        >
          {title && <span className="white">{title}</span>}
          <div
            className={`flex-row align-center justify-space-between ${
              completionStatus ? "w-50" : "w-15"
            }`}
          >
            {completionStatus && (
              <div
                className="flex-row align-center cursor-pointer"
                onClick={handleMark}
              >
                <CheckCircle />
                <span className="white p-4">
                  {completed ? "Completed" : "Mark as complete"}
                </span>
              </div>
            )}
            {isEditButton && (
              <div>
                <Button onClick={onEditClick} variety="filled">
                  {" "}
                  Edit{" "}
                </Button>
              </div>
            )}
            <div
              className="cursor-pointer p-8 align-center"
              onClick={handleDeleteTask}
            >
              <DeleteOutlined />
            </div>
            <button
              onClick={onClose}
              style={{
                //   position: "absolute",
                //   right: "0px",
                //   top: "10px",
                //   fontSize: "20px",
                border: "none",
                background: "none",
                cursor: "pointer",
                color: "white",
              }}
            >
              <Cross1Icon></Cross1Icon>
            </button>
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

export default Modal;

export const CreateModal = ({
  isOpen,
  onClose,
  children,
  className,
  title,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 99,
      }}
    >
      <div
        className={`${className}`}
        style={{
          position: "relative",
          background: "rgb(30,26,42)",
          borderRadius: 8,
        }}
      >
        <div
          className={`flex-row align-center justify-space-between p-20 border-b-1`}
        >
          {title && <span className="white">{title}</span>}
          <div
            className="flex-row align-center justify-space-between cursor-pointer"
            onClick={onClose}
          >
            <Cross1Icon color="white" />
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};
