export const CustomProjectIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35 21.7782V31.6198C35 33.4865 33.4983 34.9998 31.645 34.9998H8.355C6.50167 34.9998 5 33.4865 5 31.6198V10.0465C5 8.17984 6.50167 6.6665 8.355 6.6665H20"
        stroke="#00E6BE"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.0469 28.6199L19.2652 27.0649C19.5585 26.9916 19.8252 26.8399 20.0402 26.6266L34.0235 12.6432C35.3252 11.3416 35.3252 9.23158 34.0235 7.92991L33.7369 7.64324C32.4352 6.34158 30.3252 6.34158 29.0235 7.64324L15.0402 21.6266C14.8269 21.8399 14.6752 22.1082 14.6019 22.4016L13.0469 28.6199"
        stroke="#00E6BE"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M26.3833 10.2832L31.3833 15.2832"
        stroke="#00E6BE"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.2435 27.0698C19.2669 26.8932 19.2969 26.7182 19.2969 26.5348C19.2969 24.2332 17.4319 22.3682 15.1302 22.3682C14.9469 22.3682 14.7719 22.3998 14.5952 22.4215"
        stroke="#00E6BE"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const SuggestedProjectIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.0062 14.9978V12.4968C35.0062 8.35292 31.6469 4.99365 27.503 4.99365H12.4968C8.35292 4.99365 4.99365 8.35292 4.99365 12.4968V27.503C4.99365 31.6469 8.35292 35.0062 12.4968 35.0062H14.9978"
        stroke="#00E6BE"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M25.1029 30.7372C26.0794 31.7138 26.0794 33.2971 25.1029 34.2737C24.1263 35.2502 22.543 35.2502 21.5664 34.2737C20.5898 33.2971 20.5898 31.7138 21.5664 30.7372C22.0353 30.268 22.6714 30.0044 23.3346 30.0044C23.9979 30.0044 24.634 30.268 25.1029 30.7372"
        stroke="#00E6BE"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M34.2733 29.0697C35.2499 30.0463 35.2499 31.6296 34.2733 32.6062C33.2967 33.5827 31.7134 33.5827 30.7368 32.6062C29.7603 31.6296 29.7603 30.0463 30.7368 29.0697C31.2057 28.6005 31.8418 28.3369 32.5051 28.3369C33.1683 28.3369 33.8044 28.6005 34.2733 29.0697"
        stroke="#00E6BE"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M25.835 32.2875V24.8365C25.8346 24.1546 26.2502 23.5414 26.8837 23.2892L32.7195 20.9549C33.233 20.7493 33.815 20.8118 34.2731 21.1219C34.7311 21.4319 35.0054 21.949 35.0054 22.5022V30.5881"
        stroke="#00E6BE"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.6672 10.8296V20.0001"
        stroke="#00E6BE"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M26.6696 15.8314V16.6651"
        stroke="#00E6BE"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.6652 24.1683V15.8315"
        stroke="#00E6BE"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.6633 21.6672V18.3325"
        stroke="#00E6BE"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
