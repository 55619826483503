import React, { FC, forwardRef } from "react";
import Select, {
  IndicatorSeparatorProps,
  Props,
  components,
} from "react-select";
import { customStyle } from "./style";
import { SelectArrowDownIcon } from "../../assets/svg/SelectArrowDownIcon";
const { ValueContainer, Placeholder, IndicatorsContainer } = components;

const CustomValueContainer = ({ children, ...props }) => {
  // @ts-ignore
  return (
    <ValueContainer {...props}>
      {/* @ts-ignore */}
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) =>
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  );
};

const CustomIndicatorContainer = ({ children, ...props }) => {
  return (
    <IndicatorsContainer
      style={{
        paddingRight: 6,
      }}
      {...props}
    >
      <SelectArrowDownIcon /> &nbsp;
    </IndicatorsContainer>
  );
};

const IndicatorSeparator = ({ innerProps }) => {
  return (
    <span
      style={{
        display: "none",
      }}
      {...innerProps}
    />
  );
};

const RSelect = forwardRef((props, ref) => {
  return (
    <Select
      className="react-select"
      value={"Low"}
      components={{
        // ValueContainer: CustomValueContainer,
        IndicatorSeparator: IndicatorSeparator,
        // IndicatorsContainer: CustomIndicatorContainer,
      }}
      styles={customStyle}
      {...props}
      ref={ref}
    />
  );
});

export default RSelect;
