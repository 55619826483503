import React, { useRef, useState } from "react";
import DashboardComponent from "../../components/Dashboard/DashboardEventComponent";
import { useDataQuery } from "../../hooks/crud/data.query";
import { API_END_POINTS } from "../../utils/api-endpoint";
import { GanttChart } from "../../components/Gantt/GanttChart";
import { BallTriangleLoader } from "src/components/Loader/Loader";
import GoogleCalendar from "src/components/GoogleCalendar/GoogleCalendar";
import {
  EventListViewComponent,
  ListViewComponent,
} from "src/components/ListView/ListViewComponent";
import moment from "moment";

const EventAndTaskPage = () => {
  const [googleEvents, setGoogleEvents] = useState([]);
  const {
    data: timelineData,
    isFetching: isFetchingTimeline,
    isLoading: isLoadingTimeline,
  } = useDataQuery({
    url: API_END_POINTS.timeline,
    params: {},
  });
  const { data: googleCalendarLinkedStatus } = useDataQuery({
    url: API_END_POINTS.googleCalendarLinkStatus,
    params: {},
  });

  return (
    <div>
      <span className="gray-400 regularL">
        Task lists, event vibes, and social beats
      </span>

      <div className="flex-row justify-space-between ">
        {/* <GanttChart timelineData={timelineData?.data} /> */}
        <DashboardComponent
          className="w-100"
          isEventTab={true}
          timelineData={timelineData}
          isLoadingTimeline={isLoadingTimeline}
          googleCalendarStatus={googleCalendarLinkedStatus?.data}
        />
      </div>
      {/* {googleEvents?.map((item, index) => (
        <EventListViewComponent
          title={item?.summary}
          location={item?.location}
          description={truncateText(item?.description, 30)}
          date={
            moment(item?.start?.dateTime).format("MMM DD, YYYY: HH:mm") +
            "-" +
            moment(item?.end?.dateTime).format("MMM DD, YYYY : HH:mm")
          }
          // duration={"20"}
          onClick={() => console.log("clicked")}
          handleMark={() => console.log("clicked")}
          completed={false}
        />
      ))} */}
    </div>
  );
};

export default EventAndTaskPage;
