import React from "react";

export const EventIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <g clip-path="url(#clip0_650_491)">
        <path
          d="M9.0835 4.28302V2.5"
          stroke="#9E9CAE"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M15.9575 4.28302V2.5"
          stroke="#9E9CAE"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M4.93734 4.28302H20.0608L21.0704 5.32977V21.0097L20.0608 22.0565H4.93734C4.54205 21.6466 4.32131 21.4178 3.92773 21.0097V5.32977C4.32302 4.91994 4.54376 4.69107 4.93734 4.28302Z"
          stroke="#9E9CAE"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M3.92773 10.5192H21.0721"
          stroke="#9E9CAE"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M10.8018 22.0565V10.5192"
          stroke="#9E9CAE"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_650_491">
          <rect
            width="18"
            height="20"
            fill="white"
            transform="translate(3.5 2.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
