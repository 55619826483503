import React, { useState } from "react";

// import { useDispatch } from "react-redux";
import styled from "styled-components";
import BookingBanner from "../../assets/png/login.png";
import { PaymentCompletedIcon } from "../../assets/svg/PaymentConmpleted";
import StepperComponent from "./StepperComponent";
import "./index.css";
import Button from "../Button";

const MainDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const CardWrapperDiv = styled.div`
  width: 55%;
  background-color: #1f1a2b;
  border-radius: 8px;
  color: #fff;
`;
const SecondBannerContainer = styled.div`
  width: 40%;
`;

const StepperWrapper = styled.div`
  background-color: rgba(90, 75, 126, 0.1);
  height: 134px;
  border-bottom: 0.6px solid rgba(158, 156, 174, 0.12);
  display: flex;
  justify-content: center;
`;

const ContentWrapper = styled.div`
  margin-top: 50px;
  padding: 0 24px 24px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const TitleText = styled.span`
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  text-align: center;
  margin-top: 32px;
`;
const DescriptionText = styled.span`
  font-size: 14px;
  font-weight: 300;
  line-height: 18px;
  text-align: center;
  margin-top: 16px;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #251f34;
  width: 48px;
  height: 48px;
  border-radius: 50%;
`;

const PaymentSuccess = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedProfessional, setSelectedProfessional] = useState(null);
  const [selectedSlots, setSelectedSlots] = useState(null);
  const [selectedDate, setSelectedDate] = useState();
  const [selectedHours, setSelectedHours] = useState();

  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
  };

  return (
    <MainDiv>
      <CardWrapperDiv title={"card"} borderless>
        <StepperWrapper>
          <StepperComponent
            currentStep={4}
            onNext={handleNext}
            onPrev={handlePrev}
            setCurrentStep={setCurrentStep}
          />
        </StepperWrapper>
        <ContentWrapper>
          <IconWrapper>
            <PaymentCompletedIcon />
          </IconWrapper>
          <TitleText>Payment done successfully </TitleText>
          <DescriptionText>
            You will be redirected to the home page shortly or click here to
            return to home page
          </DescriptionText>
          <Button
            variety="filled"
            className="white"
            onClick={() => window.location.replace("/home")}
          >
            Go to Home
          </Button>
        </ContentWrapper>
      </CardWrapperDiv>
      <SecondBannerContainer>
        <img
          style={{ width: "100%" }}
          src={BookingBanner}
          alt="Booking Banner"
        />
      </SecondBannerContainer>
    </MainDiv>
  );
};

export default PaymentSuccess;
