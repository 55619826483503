import { ChevronDownIcon, ChevronUpIcon } from "@radix-ui/react-icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { AddPreIcon } from "../../assets/svg/AddPreIcon";
import { DeleteOutlined } from "../../assets/svg/DeleteOutlined";
import { MinusIcon, PlusIcon } from "../../assets/svg/PlusIcon";
import { PlusIconOutlined } from "../../assets/svg/PlusIconOutlined";
import { SearchOutlined } from "../../assets/svg/SearchOutliined";
import Button from "../../components/Button";
import Card from "../../components/Card/Card";
import {
  EventListViewComponent,
  ListViewComponent,
} from "../../components/ListView/ListViewComponent";
import {
  DashboardTab,
  EventViewType,
  ViewType,
} from "../../constants/constant";
import { useDataQuery } from "../../hooks/crud/data.query";
import { useMutationCreate } from "../../hooks/crud/mutation.create";
import { useMutationDelete } from "../../hooks/crud/mutation.delete";
import { useMutationPatch } from "../../hooks/crud/mutation.patch";
import { addTaskToProject } from "../../services/taskService";
import { API_END_POINTS } from "../../utils/api-endpoint";
import Calendar from "../Calendar/Calendar";
import Dialog from "../Dialog/Dialog";
import Modal, { CreateModal } from "../Modal/Modal";
import { EventModalViewDetails, ModalViewDetails } from "./ModalView";
import { QuickAddComponent } from "./QuickAddComponent";

import { CreateEventForm } from "../CreateEvent/CreateEvent";
import { CreateProjectForm } from "../CreateProject/CreateProject";
import { CreateTaskForm } from "../CreateTask/CreateTask";
import FormSelect from "../Select/FormSelect";
import { notify } from "../Toast/ToastComponent";
import { priorityData, statusData } from "../CreateTask/constant";
import { ganttConfig } from "../Gantt/GanttConfig";
import BoxReveal from "../magicui/box-reveal";
import { GanttChart } from "../Gantt/GanttChart";
import { BallTriangleLoader } from "../Loader/Loader";
import GoogleCalendar from "../GoogleCalendar/GoogleCalendar";
import { ProjectListViewComponent } from "../ListView/ProjectListViewComponent";
const DashboardComponent = ({
  className,
  isEventTab,
  timelineData,
  isLoadingTimeline,
  googleCalendarStatus,
  ...props
}) => {
  const [justUpdated, setJustUpdated] = useState(false);
  const [showTasksType, setShowTasksType] = useState({
    overdue: true,
    upcoming: false,
  });
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [selectedTab, setSeletedTab] = useState("Tasks");
  const [showProjectTask, setShowProjectTask] = useState(false);
  const [selectedProjectIndex, setSelectedProjectIndex] = useState(-1);
  const [selectedViewType, setSelectedViewType] = useState("List View");
  const [selectedProjectId, setSelectedProjectId] = useState("");
  const [data, setData] = useState();
  const [selectedData, setSelectedData] = useState();
  const [events, setEvents] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEventModalOpen, setIsEventModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [startTime, setStartTime] = useState("12:00");
  const [endTime, setEndTime] = useState("22:00");
  const [isEdit, setIsEdit] = useState(false);
  const [isEditButton, setIsEditButton] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedPriority, setSelectedPriority] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const [googleEvents, setGoogleEvents] = useState([]);

  const [confirmationDialog, setConfirmationDialog] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    reset,
  } = useForm({});

  const {
    data: dashboardData,
    refetch: refetchDashboardData,
    error: dashboardError,
  } = useDataQuery({
    url: API_END_POINTS.dashboard,
    params: {},
  });

  const {
    data: tasksData,
    refetch: refetchTaskData,
    error: taskError,
  } = useDataQuery({
    url: API_END_POINTS.tasks,
    params: {
      search: searchText,
    },
    skip: !searchText,
  });

  const {
    data: projectData,
    refetch: refetchProjectData,
    error: projectError,
  } = useDataQuery({
    url: API_END_POINTS.project,
    params: {},
  });

  const { data: userData } = useDataQuery({
    url: API_END_POINTS.profile,
    params: {},
  });

  // data query for list of project category
  const { data: projectCategoryData } = useDataQuery({
    url: API_END_POINTS.projectCategory,
    params: {},
  });

  // update the task
  const { mutate: patchTask } = useMutationPatch(
    `${API_END_POINTS.tasks}/${selectedData?._id}`
  );

  // delete the task
  const { mutate: deleteTask } = useMutationDelete(
    API_END_POINTS.tasks,
    selectedData?._id
  );

  // create a task
  const { mutate: createTask } = useMutationCreate(API_END_POINTS.tasks);

  //create a event
  const { mutate: createEvent } = useMutationCreate(API_END_POINTS.events);

  // delete the event
  const { mutate: deleteEvent } = useMutationDelete(
    API_END_POINTS.events,
    selectedData?._id
  );

  // delete the project
  const { mutate: deleteProject } = useMutationDelete(
    API_END_POINTS.project,
    selectedData?._id
  );

  const handleTaskClick = (e) => {
    setIsModalOpen(true);
    setModalTitle("Task Details");
    setSelectedData(e);
  };

  const handleProjectClick = (e) => {
    setIsModalOpen(true);
    setModalTitle("Project Details");
    setSelectedData(e);
    setIsEditButton(false);
  };

  const handleEventClick = (e) => {
    setIsEventModalOpen(true);
    setModalTitle("Event Details");
    setSelectedData(e);
    setIsEditButton(false);
  };

  const handleSelectedTab = (e) => {
    setSeletedTab(e.target.innerText);
  };

  const handleSelectedViewType = (e) => {
    setSelectedViewType(e.target.innerText);
  };

  //toggle function for project task

  const handleProjectTask = (index) => {
    setShowProjectTask(!showProjectTask);
    // setSelectedProjectIndex(index);
    setSelectedProjectIndex((prevIndex) => (prevIndex === index ? -1 : index));
  };

  const completedTasks = (index) => {
    return data?.[index]?.tasksList?.filter((task) => task?.completed);
  };

  const completedFilteredTasks = (index) => {
    return filteredData?.[index]?.tasksList?.filter((task) => task?.completed);
  };
  const handleCreateTask = (clickedIndex) => {
    const isEditingExists = data.some((item) =>
      item?.tasksList?.some((task) => task.isEditing)
    );

    // If there is a task being edited, return early and do not add a new task
    if (isEditingExists) {
      return;
    }
    if (selectedTab === "Tasks") {
      const newTask = { date: new Date(), title: "", isEditing: true };
      setData([...data, newTask]);
    } else if (selectedTab === "Projects") {
      const newTask = {
        date: new Date(),
        title: "",
        isEditing: true,
        description: "",
      };
      const updatedData = data?.map((item, index) => {
        // If the item has a taskLists array, add the new task to it
        if (index === clickedIndex && item?.tasksList) {
          return {
            ...item,
            tasksList: [...item.tasksList, newTask],
          };
        }
        // If the item doesn't have a taskLists array, just return the item
        return item;
      });
      setData(updatedData);
    }
  };

  // handleEditTask function
  const handleEditTask = (index, title, description, date) => {
    if (selectedTab === "Projects") {
      const updatedData = data?.map((item, i) => {
        // If the item has a taskLists array, add the new task to it
        if (i === selectedProjectIndex && item?.tasksList) {
          const updatedTasks = [...item?.tasksList];
          updatedTasks[index].title = title;
          updatedTasks[index].description = description;
          updatedTasks[index].date = date;
        }
        return item;
      });

      setData(updatedData);
    } else {
      const updatedTasks = [...data];
      updatedTasks[index].title = title;
      updatedTasks[index].description = description;
      updatedTasks[index].date = date;
      setData(updatedTasks);
    }
  };

  // handle cancel task function
  const handleCancelTask = (index) => {
    if (selectedTab === "Projects") {
      const updatedData = data?.map((item, i) => {
        // If the item has a taskLists array, remove the task with isEditing set to true
        if (i === selectedProjectIndex && item?.tasksList) {
          const updatedTasks = item?.tasksList?.filter(
            (task) => !task.isEditing
          );
          return {
            ...item,
            tasksList: updatedTasks,
          };
        }
        return item;
      });
      setData(updatedData);
    } else {
      const updatedTasks = data?.filter((task) => !task.isEditing);
      setData(updatedTasks);
    }
  };

  // handleSaveTask function
  const handleSaveTask = async (index) => {
    if (selectedTab === "Projects") {
      const updatedData = data?.map((item, i) => {
        // If the item has a taskLists array, add the new task to it
        if (item?.tasksList?.length > 0) {
          const updatedTasks = [...item?.tasksList];
          const editingTask = data[selectedProjectIndex]?.tasksList?.find(
            (task) => task.isEditing === true
          );
          if (editingTask) {
            editingTask.isEditing = false;
          }
          // updatedTasks[index].isEditing = false;
        }
        return item;
      });
      setData(updatedData);

      try {
        const response = await addTaskToProject({
          ...updatedData[selectedProjectIndex]?.tasksList[
            updatedData[selectedProjectIndex]?.tasksList?.length - 1
          ],
          projectId: data?.[selectedProjectIndex]?._id,
        });
        notify("Task added successfully", "success");
        refetchProjectData();
        return response;
      } catch (err) {
        console.log("err", err);
      }
    } else {
      const updatedTasks = [...data];
      updatedTasks[index].isEditing = false;
      setData(updatedTasks);
      try {
        if (selectedProjectId) {
          const response = await addTaskToProject(
            {
              ...updatedTasks[index],
              projectId: selectedProjectId,
            },
            {
              onSuccess(res) {
                notify("Task added successfully", "success");
                refetchDashboardData();
              },
            }
          );
          return response;
        }
        const response = await createTask(updatedTasks[index]);
        return response;
      } catch (err) {
        notify(err, "success");
        console.log("err", err);
      }
    }
  };

  const updateTask = (updatedData) => {
    const { _id, ...patchData } = updatedData;
    patchTask(patchData, {
      onSuccess(res) {
        if (selectedViewType === "List View") {
          setJustUpdated(true);
        }
        setIsModalOpen(false);
        refetchDashboardData();
        notify("Task updated successfully", "success");
      },
      onError: (err) => {
        notify(err?.error?.message, "error");
      },
    });
  };

  const handleMarkAsComplete = () => {
    const updatedData = { 
      ...selectedData, 
      completed: true,
      status: "COMPLETED" 
    };
    
    patchTask(updatedData, {
      onSuccess(res) {
        setJustUpdated(true);
        setIsModalOpen(false);
        refetchDashboardData();
        notify("Task marked as complete", "success");
      },
      onError: (err) => {
        notify(err?.error?.message, "error");
      },
    });
  };

  const handleSelectProject = (value) => {
    setSelectedProjectId(value);
  };

  // section dividing for overdue tasks
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0); // Set the time to 00:00:00 for accurate comparison

  const overdueTasks = data?.filter(
    (item) => new Date(item?.endDate) < currentDate && !item?.completed
  );
  const upcomingTasks = data?.filter(
    (item) => !item?.isEditing && new Date(item?.endDate) >= currentDate
  );

  const completedTasksData = data?.filter(
    (item) => !item?.isEditing && item?.completed
  );

  const handleCreate = () => {
    if (selectedTab === "Projects") {
      setIsCreateModalOpen(true);
      setModalTitle(`Create ${selectedTab}`);
      setIsEdit(false);
      setSelectedData({});
    } else if (selectedTab === "Tasks") {
      setIsCreateModalOpen(true);
      setSelectedData({});
      setModalTitle(`Create ${selectedTab}`);
      setIsEdit(false);
    } else {
      selectedTab === "Events" && setIsCreateModalOpen(true);
      setModalTitle(`Create ${selectedTab}`);
    }
  };

  // Define a function to reset the form
  const resetForm = () => {
    setStartDate("");
    setEndDate("");
    setEndTime("");
    setStartTime("");
  };

  const handleDeleteTask = () => {
    setConfirmationDialog(true);
  };
  const handleDeleteProject = () => {
    setConfirmationDialog(true);
  };

  const handleDeleteEvent = () => {
    setConfirmationDialog(true);
  };

  const handleDeleteConfirmationTask = () => {
    setIsButtonLoading(true);
    if (selectedTab === "Tasks") {
      deleteTask(
        {},
        {
          onSuccess(res) {
            setIsModalOpen(false);
            setJustUpdated(true);
            refetchDashboardData();
            setConfirmationDialog(false);
            setIsButtonLoading(false);
            notify("Task deleted successfully", "success");
          },
        }
      );
    } else if (selectedTab === "Events") {
      deleteEvent(
        {},
        {
          onSuccess(res) {
            setIsEventModalOpen(false);
            refetchDashboardData();
            setConfirmationDialog(false);
            setIsButtonLoading(false);
            notify("Event deleted successfully", "success");
          },
        }
      );
    } else if (selectedTab === "Projects") {
      deleteProject(
        {},
        {
          onSuccess(res) {
            setIsModalOpen(false);
            setJustUpdated(true);
            refetchProjectData();
            setConfirmationDialog(false);
            setIsButtonLoading(false);
            notify("Project deleted successfully", "success");
          },
        }
      );
    }
  };
  const onSubmit = (data) => {
    const startTime = data?.time?.map((item) =>
      new Date(item)?.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      })
    )?.[0];
    const endTime = data?.time?.map((item) =>
      new Date(item)?.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      })
    )?.[1];
    const startDateTime = new Date(
      moment(data?.startDate).format("YYYY-MM-DD") + " " + startTime
    );
    const endDateTime = new Date(
      moment(data?.endDate).format("YYYY-MM-DD") + " " + endTime
    );

    createEvent(
      {
        ...data,
        startDateTime: startDateTime.toISOString(),
        endDateTime: endDateTime.toISOString(),
        startTime: startTime,
        endTime: endTime,
        tags: [],
        eventType: "Meeting",
        collaboration: "Public",
      },
      {
        onSuccess(res) {
          setIsCreateModalOpen(false);
          refetchDashboardData();
          notify("Event created successfully", "success");
          reset();
          resetForm();
        },
      }
    );
  };

  const handleEventChange = (updatedEvents) => {
    setEvents(updatedEvents);
  };

  const transformGoogleEvent = (googleEvent) => {
    return {
      _id: googleEvent?.id,
      title: googleEvent?.summary,
      eventDetails: googleEvent?.description || "",
      collaboration: "Public", // Assuming all Google events are public
      tags: [], // Assuming no tags are provided
      location:
        googleEvent?.hangoutLink || googleEvent?.location || "Google Meet",
      eventType: googleEvent?.eventType || "Meeting",
      startDate: googleEvent?.start?.dateTime?.split("T")[0],
      startTime: googleEvent?.start?.dateTime?.split("T")[1].split("+")[0],
      endDate: googleEvent?.end?.dateTime?.split("T")[0],
      endTime: googleEvent?.end?.dateTime?.split("T")[1].split("+")[0],
      user: googleEvent?.creator?.email,
      createdAt: googleEvent?.created,
      updatedAt: googleEvent?.updated,
      __v: 0, // Assuming version is 0 for new events
    };
  };
  useEffect(() => {
    if (justUpdated) {
      setSelectedViewType("List View");
      if (selectedTab === "Tasks") {
        // setData(dashboardData?.data?.tasks);
        setData(tasksData?.data);
      } else if (selectedTab === "Events") {
        // setData(dashboardData?.data?.events);
        data = dashboardData?.data?.events || [];
        if (googleEvents) {
          const transformedGoogleEvents =
            googleEvents.map(transformGoogleEvent);
          data = [...data, ...transformedGoogleEvents];
          setData(data);
        }
      } else if (selectedTab === "Projects") {
        setData(projectData?.data);
      }
    } else {
      if (selectedTab === "Tasks") {
        // setData(dashboardData?.data?.tasks);
        setData(tasksData?.data);
        setSelectedViewType("List View");
      } else if (selectedTab === "Events") {
        // setData(dashboardData?.data?.events);
        setSelectedViewType("List View");
        let data;
        data = dashboardData?.data?.events || [];
        if (googleEvents) {
          const transformedGoogleEvents =
            googleEvents.map(transformGoogleEvent);
          data = [...data, ...transformedGoogleEvents];
          setData(data);
        }
      } else if (selectedTab === "Projects") {
        setData(projectData?.data);
        setSelectedViewType("List View");
      }
    }
  }, [
    dashboardData,
    selectedTab,
    projectData,
    justUpdated,
    tasksData,
    searchText,
    googleEvents,
  ]);

  useEffect(() => {
    if (projectError) {
      notify(projectError?.error?.message, "error");
    } else if (dashboardError) {
      notify(dashboardError?.error?.message, "error");
    }
  }, [projectError, dashboardError]);

  const isProjectCompleted = (project) => {
    return project.tasksList.every((task) => task.completed);
  };

  useEffect(() => {
    if (selectedTab === "Tasks") {
      let filteredTasks = dashboardData?.data?.tasks;

      // Apply status filter if selected
      if (selectedStatus) {
        filteredTasks = filteredTasks?.filter(
          (item) => item?.status === selectedStatus
        );
      }

      // Apply priority filter if selected
      if (selectedPriority?.toUpperCase()) {
        filteredTasks = filteredTasks?.filter(
          (item) => item?.priority === selectedPriority?.toUpperCase()
        );
      }

      // Set filtered data only if either filter is active
      if (selectedStatus || selectedPriority) {
        setFilteredData(filteredTasks);
      } else {
        setFilteredData([]);
      }
    } else if (selectedTab === "Projects") {
      if (selectedStatus === "Completed") {
        setFilteredData(projectData?.data?.filter(isProjectCompleted));
      } else if (selectedStatus === "Incomplete") {
        setFilteredData(
          projectData?.data?.filter((project) => !isProjectCompleted(project))
        );
      } else {
        setFilteredData([]);
      }
    }
  }, [
    selectedStatus,
    selectedPriority,
    selectedTab,
    projectData,
    dashboardData,
  ]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setIsModalOpen(false);
        setIsEventModalOpen(false);
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleStatusChange = (value) => {
    console.log('Status change triggered with value:', value);
    console.log('Selected event:', selectedData);
    
    const { _id, ...patchData } = selectedData;
    const updatedTask = { ...patchData, status: value };
    
    console.log('Sending patch data:', updatedTask);
    
    patchTask(updatedTask, {
      onSuccess(res) {
        console.log('Patch successful:', res);
        notify("Task status updated successfully", "success");
      },
      onError: (err) => {
        console.error('Patch failed:', err);
        notify(err?.error?.message, "error");
      },
    });
  };

  return (
    <div className={className}>
      <div className="flex-column">
        <Card>
          <div className="flex-row justify-space-between  border-b-1 items-center">
            <div
              className={`flex-row justify-space-between ${
                isEventTab ? "w-30" : "w-60"
              } p-12`}
            >
              {DashboardTab?.map((item, index) => (
                <div
                  className={`cursor-pointer card-tab w-25 text-align-center  ${
                    item?.tabName === selectedTab && "card-tab-selected"
                  }`}
                  onClick={handleSelectedTab}
                >
                  <span className="white regularS">{item?.tabName}</span>
                </div>
              ))}
            </div>

            <div className={`flex-row justify-space-between m-4 items-center`}>
              {isEventTab && selectedTab === "Events" && (
                <div className="mt-1 items-center">
                  <GoogleCalendar
                    setGoogleEvents={setGoogleEvents}
                    googleCalendarStatus={googleCalendarStatus}
                  />
                </div>
              )}
              <Button
                className="flex-row mediumM"
                variety="filled"
                height={"48px"}
                onClick={handleCreate}
              >
                <AddPreIcon /> Create{" "}
                {selectedTab === "Projects"
                  ? "Project"
                  : selectedTab === "Tasks"
                  ? "Task"
                  : "Event"}
              </Button>
            </div>
          </div>
          <div className="card-second-wrapper content-container">
            <div className="flex-row justify-space-between items-center">
              <div className="flex-row justify-space-between p-12">
                {isEventTab
                  ? EventViewType?.filter((filterData) =>
                      selectedTab === "Tasks"
                        ? filterData?.name !== "Timeline View"
                        : filterData
                    )?.map((item, index) => (
                      <Button
                        onClick={handleSelectedViewType}
                        variety={`${
                          item?.name === selectedViewType
                            ? "filled"
                            : "outlined"
                        }`}
                      >
                        {item?.name}
                      </Button>
                    ))
                  : ViewType?.filter((filterData) =>
                      selectedTab === "Projects" || selectedTab === "Tasks"
                        ? filterData?.name !== "Calendar View"
                        : filterData
                    )?.map((item, index) => (
                      <Button
                        onClick={handleSelectedViewType}
                        variety={`${
                          item?.name === selectedViewType
                            ? "filled"
                            : "outlined"
                        }`}
                      >
                        {item?.name}
                      </Button>
                    ))}
              </div>
              {selectedTab === "Tasks" && (
                <div className="w-20 mt-1">
                  <FormSelect
                    control={control}
                    label="Priority"
                    id="priority"
                    options={priorityData}
                    onChange={(e) => {
                      if (e) {
                        setSelectedPriority(e.value);
                      } else {
                        setSelectedPriority("");
                      }
                    }}
                    // value={"Low"}
                    name="priority"
                    placeholder="Priority"
                    isClearable
                  />
                </div>
              )}

              {selectedTab === "Tasks" && (
                <div className="w-20 mt-1">
                  <FormSelect
                    control={control}
                    label="Status"
                    id="status"
                    options={[
                      { label: "To Do", value: "TODO" },
                      { label: "In Progress", value: "IN_PROGRESS" },
                      { label: "Blocked", value: "BLOCKED" },
                      { label: "Completed", value: "COMPLETED" }
                    ]}
                    onChange={(e) => {
                      if (e) {
                        setSelectedStatus(e.value);
                      } else {
                        setSelectedStatus("");
                      }
                    }}
                    name="status"
                    placeholder="Status"
                    isClearable
                  />
                </div>
              )}
              <div className="flex-row justify-space-between p-12 mt-1">
                {showSearch && (
                  // <BoxReveal
                  //   // width="100%"
                  //   boxColor={"transparent"}
                  //   duration={0.5}
                  // >
                  <Controller
                    name={"search"}
                    control={control}
                    render={({ field }) => (
                      <input
                        className="w-80 quick-add-task-container create-project-input search-input"
                        style={{
                          background: "var(--App-Background)",
                          marginRight: 8,
                        }}
                        placeholder="Search"
                        onChange={(e) => {
                          setSearchText(e.target.value);
                        }}
                      />
                    )}
                  />
                  // </BoxReveal>
                )}
                <div
                  className="quick-add-task-container align-center flex-row  p-8 cursor-pointer"
                  onClick={() => setShowSearch(!showSearch)}
                >
                  <SearchOutlined />
                </div>
                {/* <div className="quick-add-task-container p-8 align-center flex-row w-30 justify-space-between">
                  <span className="white">Tags</span>
                  <TagIcon />
                </div>
                <div className="quick-add-task-container p-8 align-center flex-row w-20 justify-space-between">
                  <select className="bg-transparent border-none white w-100">
                    <option>Status</option>
                  </select>
                </div>
                <div className="quick-add-task-container p-8 align-center flex-row justify-space-between w-20">
                  <select className="bg-transparent border-none white w-100">
                    <option>Date</option>
                  </select>
                </div> */}
              </div>
            </div>
            <div className="m-8">
              {selectedViewType === "List View" &&
                selectedTab === "Tasks" &&
                (selectedStatus || selectedPriority ? (
                  filteredData?.map((item) => (
                    <ListViewComponent
                      onClick={(e) => handleTaskClick(item)}
                      date={moment(item?.date).format("MMM D YYYY")}
                      title={item?.title}
                      completed={item?.completed}
                      status={item?.status}
                      onClickComplete={() => console.log("removed")}
                      setData={setData}
                      data={data}
                      setSelectedData={setSelectedData}
                      task={item}
                      patchTask={patchTask}
                    />
                  ))
                ) : (
                  <div className="flex-column">
                    <span
                      onClick={() =>
                        setShowTasksType({
                          ...showTasksType,
                          overdue: !showTasksType?.overdue,
                        })
                      }
                      className="boldL white cursor-pointer flex align-center"
                    >
                      {showTasksType?.overdue ? (
                        <ChevronDownIcon />
                      ) : (
                        <ChevronUpIcon />
                      )}
                      <span className="p-4">
                        Overdue Tasks ({overdueTasks?.length})
                      </span>
                    </span>
                    {showTasksType?.overdue && (
                      <div>
                        {overdueTasks
                          ?.filter((filterData) => !filterData?.completed)
                          ?.map((item, index) => (
                            <ListViewComponent
                              onClick={(e) => handleTaskClick(item)}
                              date={moment(item?.endDate).format("MMM D YYYY")}
                              title={item?.title}
                              duration={item}
                              completed={item?.completed}
                              status={item?.status}
                              onClickComplete={async () => {
                                await setSelectedData(item);
                                handleMarkAsComplete();
                              }}
                              setData={setData}
                              data={data}
                              setSelectedData={setSelectedData}
                              task={item}
                              patchTask={patchTask}
                              selectedData={selectedData}
                            />
                          ))}
                      </div>
                    )}
                    <span
                      onClick={() =>
                        setShowTasksType({
                          ...showTasksType,
                          upcoming: !showTasksType?.upcoming,
                        })
                      }
                      className="boldL white cursor-pointer flex align-center"
                    >
                      {showTasksType?.upcoming ? (
                        <ChevronDownIcon />
                      ) : (
                        <ChevronUpIcon />
                      )}
                      <span className="p-4">
                        Upcoming Tasks (
                        {
                          upcomingTasks?.filter(
                            (filterData) => !filterData?.completed
                          )?.length
                        }
                        )
                      </span>
                    </span>
                    {showTasksType?.upcoming && (
                      <div>
                        {upcomingTasks
                          ?.filter((filterData) => !filterData?.completed)
                          .map((item, index) => (
                            <ListViewComponent
                              onClick={(e) => handleTaskClick(item)}
                              date={moment(item?.endDate).format("MMM D YYYY")}
                              title={item?.title}
                              completed={item?.completed}
                              status={item?.status}
                              duration={item}
                              onClickComplete={async () => {
                                await setSelectedData(item);
                                handleMarkAsComplete();
                              }}
                              setData={setData}
                              data={data}
                              setSelectedData={setSelectedData}
                              task={item}
                              patchTask={patchTask}
                              selectedData={selectedData}
                            />
                          ))}
                      </div>
                    )}
                    {isEventTab && (
                      <div>
                        <span
                          onClick={() =>
                            setShowTasksType({
                              ...showTasksType,
                              completed: !showTasksType?.completed,
                            })
                          }
                          className="boldL white cursor-pointer flex align-center"
                        >
                          {showTasksType?.completed ? (
                            <ChevronDownIcon />
                          ) : (
                            <ChevronUpIcon />
                          )}
                          <span className="p-4">
                            Completed Tasks ({completedTasksData?.length})
                          </span>
                        </span>
                        {showTasksType?.completed && (
                          <div>
                            {completedTasksData.map((item, index) => (
                              <ListViewComponent
                                onClick={(e) => handleTaskClick(item)}
                                date={moment(item?.endDate).format(
                                  "MMM D YYYY"
                                )}
                                title={item?.title}
                                completed={item?.completed}
                                status={item?.status}
                                duration={item}
                                onClickComplete={async () => {
                                  await setSelectedData(item);
                                  handleMarkAsComplete();
                                }}
                                setData={setData}
                                data={data}
                                setSelectedData={setSelectedData}
                                task={item}
                                patchTask={patchTask}
                                selectedData={selectedData}
                              />
                            ))}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ))}
              {selectedViewType === "List View" && selectedTab === "Tasks" ? (
                data?.map((item, index) => {
                  if (item.isEditing) {
                    return (
                      <div className="quick-add-task-container flex-column m-8">
                        <input
                          className="border-none regularXL"
                          type="text"
                          placeholder="Task title"
                          value={item?.title}
                          onChange={(e) =>
                            handleEditTask(
                              index,
                              e.target.value,
                              item?.description,
                              item?.date
                            )
                          }
                        />
                        <textarea
                          placeholder="Description"
                          className="border-none bg-transparent p-12 white"
                          value={item?.description}
                          onChange={(e) =>
                            handleEditTask(
                              index,
                              item?.title,
                              e.target.value,
                              item?.date
                            )
                          }
                        />
                        <div>
                          <DatePicker
                            placeholderText="Select date"
                            selected={item?.date ? new Date(item?.date) : null}
                            onChange={(date) =>
                              handleEditTask(
                                index,
                                item?.title,
                                item?.description,
                                date
                              )
                            }
                            className="bg-transparent quick-add-task-container white m-8 w-20"
                          />
                        </div>
                        <div className="flex-row justify-space-between p-12 border-top-task-add-container">
                          <div className="w-50">
                            <FormSelect
                              className="m-t-6"
                              control={control}
                              label="category"
                              id="category"
                              placeholder="Select Project"
                              options={projectData?.data?.map((option) => {
                                return {
                                  value: option._id,
                                  label: option?.title,
                                  ...option,
                                };
                              })}
                              onChange={(e) => {
                                handleSelectProject(e?.value);
                              }}
                              // value={"Low"}
                              name="category"
                              isClearable
                            />
                          </div>

                          <div>
                            <Button
                              variety="outlined"
                              onClick={(e) => handleCancelTask(index)}
                            >
                              Cancel
                            </Button>
                            <Button
                              variety="filled"
                              onClick={(e) => handleSaveTask(index)}
                            >
                              Save
                            </Button>
                          </div>
                        </div>
                      </div>
                    );
                  }
                })
              ) : selectedViewType === "Calendar View" &&
                selectedTab === "Tasks" ? (
                <Calendar
                  data={data}
                  isTask
                  updateTask={updateTask}
                  deleteTask={handleDeleteTask}
                  setIsModalOpen={setIsModalOpen}
                  setIsCreateModalOpen={setIsCreateModalOpen}
                  setModalTitle={setModalTitle}
                  setIsEdit={setIsEdit}
                  selectedTab={selectedTab}
                  setSelectedData={setSelectedData}
                  onEventChange={handleEventChange}
                />
              ) : selectedViewType === "List View" &&
                selectedTab === "Events" ? (
                data?.map((item, index) => (
                  <EventListViewComponent
                    item={item}
                    title={item?.title}
                    date={moment(item?.startDate).format("MMM D YYYY")}
                    description={item?.eventDetails}
                    location={item?.location}
                    onClick={(e) => handleEventClick(item)}
                  />
                ))
              ) : selectedViewType === "Calendar View" &&
                selectedTab === "Events" ? (
                <Calendar
                  data={data}
                  updateTask={updateTask}
                  deleteTask={handleDeleteTask}
                />
              ) : selectedViewType === "List View" &&
                selectedTab === "Projects" ? (
                selectedStatus ? (
                  filteredData?.map((item, index) => {
                    return (
                      <div
                        className={`content-container ${
                          selectedTab === "Projects" ? "active" : ""
                        }`}
                      >
                        <div className="flex-row align-center cursor-pointer justify-space-between">
                          <div
                            className="flex-row align-center cursor-pointer"
                            // onClick={() => handleProjectTask(index)}
                          >
                            {" "}
                            <div
                              className="align-center"
                              onClick={() => handleProjectTask(index)}
                            >
                              {index === selectedProjectIndex ? (
                                <MinusIcon />
                              ) : (
                                <PlusIcon />
                              )}
                            </div>
                            <span
                              className="white p-8 boldM"
                              onClick={(e) => handleProjectClick(item)}
                            >
                              {item?.title}
                            </span>
                          </div>
                          <span className="green-400 regularS">
                            Status:{completedFilteredTasks(index)?.length}/
                            {filteredData?.[index].tasksList?.length}
                          </span>
                        </div>
                        {index === selectedProjectIndex && (
                          <>
                            {item?.tasksList?.map((task, index) => {
                              if (task?.isEditing) {
                                return (
                                  <QuickAddComponent
                                    onSaveProject={() => handleSaveTask(index)}
                                    handleEditTask={handleEditTask}
                                    item={task}
                                    index={index}
                                    projectCategoryData={projectCategoryData}
                                    handleSelectProject={handleSelectProject}
                                    handleCancelTask={() =>
                                      handleCancelTask(index)
                                    }
                                  />
                                );
                              } else {
                                return (
                                  <ListViewComponent
                                    date={
                                      task?.date
                                        ? moment(task?.date).format(
                                            "MMM D YYYY"
                                          )
                                        : moment(task?.endDate).format(
                                            "MMM D YYYY"
                                          )
                                    }
                                    title={task?.title}
                                    onClick={(e) => handleTaskClick(task)}
                                    completed={task?.completed}
                                    status={task?.status}
                                    duration={item}
                                    onClickComplete={async () => {
                                      await setSelectedData(item);
                                      handleMarkAsComplete();
                                    }}
                                    setData={setData}
                                    data={data}
                                    setSelectedData={setSelectedData}
                                    task={item}
                                    patchTask={patchTask}
                                    selectedData={selectedData}
                                  />
                                );
                              }
                            })}
                            <div
                              className="cursor-pointer p-12 flex-row align-center"
                              onClick={() => handleCreateTask(index)}
                            >
                              <PlusIconOutlined />
                              <span className="white">Create Task</span>
                            </div>
                          </>
                        )}
                      </div>
                    );
                  })
                ) : (
                  data?.map((item, index) => {
                    return (
                      <div
                        className={`content-container ${
                          selectedTab === "Projects" ? "active" : ""
                        }`}
                      >
                        <div className="flex-row align-center cursor-pointer justify-space-between">
                          <div
                            className="flex-row align-center cursor-pointer"
                            // onClick={() => handleProjectTask(index)}
                          >
                            {" "}
                            <div
                              className="align-center"
                              onClick={() => handleProjectTask(index)}
                            >
                              {index === selectedProjectIndex ? (
                                <MinusIcon />
                              ) : (
                                <PlusIcon />
                              )}
                            </div>
                            <span
                              className="white p-8 boldM"
                              onClick={(e) => handleProjectClick(item)}
                            >
                              {item?.title}
                            </span>
                          </div>
                          <span className="green-400 regularS">
                            Status:{completedTasks(index)?.length}/
                            {data?.[index].tasksList?.length}
                          </span>
                        </div>
                        {index === selectedProjectIndex && (
                          <>
                            {item?.tasksList?.map((task, index) => {
                              if (task?.isEditing) {
                                return (
                                  <QuickAddComponent
                                    onSaveProject={() => handleSaveTask(index)}
                                    handleEditTask={handleEditTask}
                                    item={task}
                                    index={index}
                                    projectCategoryData={projectCategoryData}
                                    handleSelectProject={handleSelectProject}
                                    handleCancelTask={() =>
                                      handleCancelTask(index)
                                    }
                                  />
                                );
                              } else {
                                return (
                                  <ProjectListViewComponent
                                    patchTask={patchTask}
                                    date={
                                      task?.date
                                        ? moment(task?.date).format(
                                            "MMM D YYYY"
                                          )
                                        : moment(task?.endDate).format(
                                            "MMM D YYYY"
                                          )
                                    }
                                    title={task?.title}
                                    onClick={(e) => handleTaskClick(task)}
                                    completed={task?.completed}
                                    selectedProjectIndex={selectedProjectIndex}
                                    setData={setData}
                                    data={data}
                                    setSelectedData={setSelectedData}
                                    task={task}
                                    refetch={refetchProjectData}
                                  />
                                );
                              }
                            })}
                            <div
                              className="cursor-pointer p-12 flex-row align-center"
                              onClick={() => handleCreateTask(index)}
                            >
                              <PlusIconOutlined />
                              <span className="white">Create Task</span>
                            </div>
                          </>
                        )}
                      </div>
                    );
                  })
                )
              ) : selectedViewType === "Timeline View" &&
                selectedTab === "Projects" ? (
                timelineData?.data && (
                  <GanttChart timelineData={timelineData?.data} />
                )
              ) : null}
            </div>
            {selectedTab !== "Projects" && selectedViewType === "List View" && (
              <div
                className="cursor-pointer p-12 flex-row align-center"
                onClick={handleCreateTask}
              >
                <PlusIconOutlined />
                <span className="white">Create Task</span>
              </div>
            )}
          </div>
        </Card>
        <Modal
          isOpen={isModalOpen}
          // className={"card-app-background align-center"}
          onClose={() => setIsModalOpen(false)}
          title={modalTitle}
          handleMark={handleMarkAsComplete}
          completed={selectedData?.completed}
          status={selectedData?.status}
          handleDeleteTask={
            selectedTab === "Projects" ? handleDeleteProject : handleDeleteTask
          }
          isEditButton={
            selectedTab === "Tasks" || selectedTab === "Projects"
              ? true
              : isEditButton
          }
          onEditClick={() => {
            setIsModalOpen(false);
            setIsCreateModalOpen(true);
            setModalTitle("Edit " + selectedTab);
            setIsEdit(true);
          }}
          completionStatus={true}
        >
          <ModalViewDetails
            selectedEvent={selectedData}
            selectedTab={selectedTab}
            completed={projectData?.data?.filter(isProjectCompleted)}
            patchTask={patchTask}
          />
        </Modal>

        <Modal
          isOpen={isEventModalOpen}
          // className={"card-app-background align-center"}
          onClose={() => setIsEventModalOpen(false)}
          title={modalTitle}
          completionStatus={false}
          handleMark={handleMarkAsComplete}
          completed={selectedData?.completed}
          status={selectedData?.status}
          handleDeleteTask={handleDeleteEvent}
          isEditButton={
            selectedTab === "Tasks" || selectedTab === "Projects"
              ? true
              : isEditButton
          }
          onEditClick={() => {
            setIsModalOpen(false);
            setIsCreateModalOpen(true);
            setModalTitle("Edit " + selectedTab);
            setIsEdit(true);
          }}
        >
          <EventModalViewDetails
            selectedEvent={selectedData}
            selectedTab={selectedTab}
          />
        </Modal>

        <CreateModal
          isOpen={isCreateModalOpen}
          // className={"card-app-background align-center"}
          onClose={() => setIsCreateModalOpen(false)}
          title={modalTitle}
          className={"w-2/5"}
        >
          {selectedTab === "Projects" ? (
            <CreateProjectForm
              setModal={setIsCreateModalOpen}
              refetch={refetchProjectData}
              isEdit={isEdit}
              selectedData={selectedData}
            />
          ) : selectedTab === "Tasks" ? (
            <CreateTaskForm
              setModal={setIsCreateModalOpen}
              selectedData={selectedData}
              isEdit={isEdit}
              refetch={refetchDashboardData}
              setJustUpdated={setJustUpdated}
              projectData={projectData?.data}
              setData={setData}
              dataList={data}
            />
          ) : (
            <CreateEventForm
              setIsCreateModalOpen={setIsCreateModalOpen}
              onSubmit={onSubmit}
            />
          )}
        </CreateModal>
        <Dialog
          show={confirmationDialog}
          action1ButtonLabel={"Cancel"}
          action2ButtonLabel={"Delete"}
          handelAction1={() => setConfirmationDialog(false)}
          handelAction2={handleDeleteConfirmationTask}
          isLoading={isButtonLoading}
        >
          <div className="flex-col justify-center align-middle">
            <div
              style={{
                background: "rgba(37, 31, 52, 1)",
                width: 100,
                height: 100,
                borderRadius: 100,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                top: 20,
                left: "40%",
              }}
            >
              <DeleteOutlined size={72} />
            </div>
            <div className="pt-28">
              <h3 className="white text-align-center ">
                You are about to delete a{" "}
                {selectedTab === "Tasks"
                  ? "task"
                  : selectedTab === "Projects"
                  ? "project"
                  : "event"}
              </h3>
              <h5 className="white text-align-center">
                This will delete your{" "}
                {selectedTab === "Tasks"
                  ? "task"
                  : selectedTab === "Projects"
                  ? "project"
                  : "event"}{" "}
                permanently.
              </h5>
            </div>
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default DashboardComponent;
