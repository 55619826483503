// Stepper.js
import React from "react";
import styled from "styled-components";
import {
  EventIcon,
  PaymentIcon,
  ProfessionalIcon,
  ServiceIcon,
} from "../../assets/svg/booking-icons";

const StepperContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const Step = styled.div`
  flex: 1;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ active, future }) =>
    active ? "#654DF6" : future ? "grey" : "#654DF6"};
  width: 48px;
  height: 48px;
  border-radius: 50%;
`;

const Line = styled.div`
  flex: 1;
  height: 2px;

  border-top: ${({ active, future }) =>
    active
      ? "1px solid #9E9CAE"
      : future
      ? "1px dotted #9E9CAE"
      : "1px solid #9E9CAE"};
`;

const TitleSpan = styled.span`
  color: #e6e6ea;
  font-size: 12px;
  font-weight: 300;
  line-height: 15.6px;
  margin-top: 8px;
`;

const StepperComponent = ({ currentStep, setCurrentStep }) => {
  const steps = [
    { title: "Service", icon: <ServiceIcon /> },
    { title: "Professional", icon: <ProfessionalIcon /> },
    { title: "Time", icon: <EventIcon /> },
    { title: "Payment", icon: <PaymentIcon /> },
  ];
  return (
    <StepperContainer>
      {steps.map((step, index) => (
        <React.Fragment key={index}>
          <Step
            onClick={() => {
              // if (index < currentStep - 1) {
              setCurrentStep(index + 1);
              // }
            }}
          >
            <IconWrapper
              active={index < currentStep - 1}
              future={index >= currentStep}
            >
              {step?.icon}
            </IconWrapper>
            <TitleSpan className={"typography-14"}>{step?.title}</TitleSpan>
          </Step>
          {index < steps.length - 1 && (
            <Line
              active={index < currentStep - 1}
              future={index >= currentStep}
            />
          )}
        </React.Fragment>
      ))}
    </StepperContainer>
  );
};

export default StepperComponent;
