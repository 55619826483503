import { useGoogleLogin } from "@react-oauth/google";
import { useState } from "react";
import Button from "../Button";
import { useMutationCreate } from "../../hooks/crud/mutation.create";
import { API_END_POINTS } from "../../utils/api-endpoint";
import "./GoogleCalendar.css";

const GoogleCalendar = ({ setGoogleEvents, googleCalendarStatus }) => {
  const [token, setToken] = useState(null);
  const [taskTitle, setTaskTitle] = useState("");
  const [taskDueDate, setTaskDueDate] = useState("");
  const [events, setEvents] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { mutate: authorizeGoogle } = useMutationCreate(
    API_END_POINTS.googleCalendarAuth
  );

  const login = useGoogleLogin({
    onSuccess: async (response) => {
      setToken(response.access_token);
      authorizeGoogle({ accessToken: response.access_token });
      // fetchEvents(response.access_token);
    },
    onError: (error) => console.error("Login failed:", error),
    scope:
      "https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/tasks https://www.googleapis.com/auth/calendar.readonly",
  });

  // Get today's date and calculate one week later
  const getStartOfWeek = () => {
    const now = new Date();
    const startOfWeek = new Date(now.setHours(0, 0, 0, 0));
    startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay()); // Set to the beginning of the week
    return startOfWeek.toISOString();
  };

  const getEndOfWeek = () => {
    const now = new Date();
    const endOfWeek = new Date(now.setHours(23, 59, 59, 999));
    endOfWeek.setDate(endOfWeek.getDate() - endOfWeek.getDay() + 6); // Set to the end of the week
    return endOfWeek.toISOString();
  };

  const handleCreateEvent = async () => {
    if (!token) {
      console.log("Please log in first");
      return;
    }

    const event = {
      summary: "New Task",
      description: "A new task created from React app",
      start: {
        dateTime: "2024-08-15T10:00:00-07:00", // Adjust date and time accordingly
        timeZone: "America/Los_Angeles",
      },
      end: {
        dateTime: "2024-08-15T10:30:00-07:00", // Adjust date and time accordingly
        timeZone: "America/Los_Angeles",
      },
    };

    try {
      const response = await fetch(
        "https://www.googleapis.com/calendar/v3/calendars/primary/events",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(event),
        }
      );

      const data = await response.json();
    } catch (error) {
      console.error("Error creating event:", error);
    }
  };

  const createTask = async () => {
    if (!token) {
      console.log("Please log in first");
      return;
    }

    const task = {
      title: taskTitle,
      due: taskDueDate ? new Date(taskDueDate).toISOString() : undefined, // Optional due date
    };

    try {
      const response = await fetch(
        "https://www.googleapis.com/tasks/v1/lists/@default/tasks",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(task),
        }
      );

      const data = await response.json();
      console.log("Task created:", data);
    } catch (error) {
      console.error("Error creating task:", error);
    }
  };

  const fetchEvents = async (token) => {
    if (!token) {
      console.log("Please log in first");
      return;
    }

    const startOfWeek = getStartOfWeek();
    const endOfWeek = getEndOfWeek();

    try {
      const response = await fetch(
        `https://www.googleapis.com/calendar/v3/calendars/primary/events`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.json();
      setEvents(data.items || []);

      setGoogleEvents(data.items || []);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  const handleSyncClick = () => {
    // Show confirmation dialog when the button is clicked
    setShowConfirmation(true);
  };

  const handleConfirmSync = () => {
    // Proceed with login after user confirms
    setShowConfirmation(false);
    login();
  };

  const handleCancelSync = () => {
    // Hide the confirmation dialog when user cancels
    setShowConfirmation(false);
  };

  return (
    <div>
      <Button
        variety="primary-outlined"
        className="primary-button  black"
        height={"48px"}
        // onClick={login}
        onClick={handleSyncClick}
        disabled={googleCalendarStatus?.isLinked}
      >
        {googleCalendarStatus?.isLinked
          ? "Google Calendar Synced"
          : "Sync Google Calendar"}
      </Button>

      {showConfirmation && (
        <div className="confirmation-dialog">
          <p>
            You will sync with your Google Calendar account. Are you sure about
            that?
          </p>
          <Button
            variety="filled"
            onClick={handleConfirmSync}
            // className="confirmation-button"
          >
            Yes, continue
          </Button>
          <Button
            variety="filled"
            onClick={handleCancelSync}
            className="cancel-button"
          >
            Cancel
          </Button>
        </div>
      )}
    </div>
  );
};

export default GoogleCalendar;
