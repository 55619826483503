export const EmptySocial = () => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M66.8434 53.3335C61.9334 63.2035 51.7734 70.0002 40.0001 70.0002C28.2267 70.0002 18.0667 63.2035 13.1567 53.3335"
        stroke="#00E6BE"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M66.8434 26.6667C61.9334 16.7967 51.7734 10 40.0001 10C28.2267 10 18.0667 16.7967 13.1567 26.6667"
        stroke="#00E6BE"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M33.3333 40.0002H10"
        stroke="#00E6BE"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M26.6665 33.3335L33.3332 40.0002L26.6665 46.6668"
        stroke="#00E6BE"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M46.6665 40.0002H69.9998"
        stroke="#00E6BE"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M53.3332 46.6668L46.6665 40.0002L53.3332 33.3335"
        stroke="#00E6BE"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
