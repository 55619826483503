import React, { useEffect } from "react";
import "./Dialog.css";
import Button from "../Button";

const Dialog = ({
  title,
  children,
  handelAction1,
  handelAction2,
  show,
  footer,
  action1ButtonLabel,
  action2ButtonLabel,
  modelStyle,
  className,
  isLoading,
}) => {
  useEffect(() => {
    if (show) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [show]);

  return (
    show && (
      <div>
        <div className={`${className} model-overlay`} />
        <div
          className="dialog"
          style={{
            height: "fit-content",
            ...modelStyle,
          }}
        >
          <div className="dialog-header">
            <h4 style={{ lineHeight: "1.5rem" }}>{title}</h4>
          </div>

          {children}

          {footer ?? (
            <div className="modal-footer">
              <Button
                type="submit"
                variety="filled"
                onClick={handelAction1}
                style={{
                  color: "var(--Genesis-White)",
                }}
              >
                {action1ButtonLabel}
              </Button>
              <Button
                style={{ backgroundColor: "var(--App-Red)" }}
                onClick={handelAction2}
                isLoading={isLoading}
              >
                {action2ButtonLabel}
              </Button>
            </div>
          )}
        </div>
      </div>
    )
  );
};

export default Dialog;
