import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router";
import loginImage from "../../../assets/png/login.png";
import { Logo } from "../../../assets/svg/Logo";
import { APP_ROUTES } from "../../../utils/app-routes";
import "../Signup/Signup.css";
import Button from "../../../components/Button";
import { useMutationCreate } from "../../../hooks/crud/mutation.create";
import { API_END_POINTS } from "../../../utils/api-endpoint";
import { GENESIS_USER_TOKEN, USER_DETAILS } from "../../../utils/constants";
import { notify } from "../../../components/Toast/ToastComponent";

const OtpVerification = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [otp, setOtp] = useState(Array(6).fill("")); // to hold OTP input

  const { mutate: verifyOtp } = useMutationCreate(
    API_END_POINTS.otpVerification
  );

  const { mutate: verifyForgotOtp } = useMutationCreate(
    API_END_POINTS.forgotPasswordVerify
  );

  const { mutate: resendOtp } = useMutationCreate(API_END_POINTS.resendOtp);
  //function to handle submit of OTP verification
  const handleSubmit = () => {
    const otpValue = otp.join(""); // joining all 6 digits to form OTP
    if (state?.isForgot) {
      verifyForgotOtp(
        { otp: otpValue, email: state?.email },
        {
          onSuccess(res) {
            navigate(APP_ROUTES.resetPassword);
          },
          onError: (error) => {
            notify(error?.error?.message, "error");
          },
        }
      );
      return;
    } else {
      verifyOtp(
        { otp: otpValue, email: state?.email },
        {
          onSuccess(res) {
            localStorage.setItem(
              GENESIS_USER_TOKEN,
              JSON.stringify(res?.token)
            );
            const userDetails = JSON.parse(localStorage.getItem(USER_DETAILS));
            userDetails.data.isVerified = true;
            localStorage.setItem(USER_DETAILS, JSON.stringify(userDetails));
            navigate(APP_ROUTES.paymentPlan);
          },
          onError: (error) => {
            notify(error?.error?.message, "error");
          },
        }
      );
    }
    // navigate(APP_ROUTES.login);
  };

  const handleResendOtp = () => {
    resendOtp(
      { email: state?.email },
      {
        onError: (error) => {
          notify(error?.error?.message, "error");
        },
      }
    );
  };
  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false; // to ensure that the input is a number
    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]); // update OTP
    // focus on next input
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const handleKeyDown = (element, index) => {
    // focus on previous input
    if (element.key === "Backspace" && otp[index] === "") {
      if (element.target.previousSibling) {
        element.target.previousSibling.focus();
      }
    }
  };
  return (
    <>
      <div className="w-full flex">
        <div className="login-banner w-50">
          <img className="w-full h-screen" src={loginImage} alt="Login"></img>
        </div>

        <div className="box">
          <div className="form-side">
            <div
              style={{
                height: "3.125rem;",
                width: "11.53rem",
                marginBottom: "3.12rem",
              }}
            >
              <Logo />
            </div>
            <div>
              <span className="h4 white">OTP Verification</span>
              <br />
              <span className="regularS gray-300">
                Enter your otp code sent to your email
              </span>
            </div>
            <div className="flex-row justify-space-between m-t-24">
              {Array(6)
                .fill("")
                .map((_, index) => (
                  <input
                    type="otp"
                    name="otp"
                    maxLength="1"
                    key={index}
                    value={otp[index]}
                    onChange={(e) => handleChange(e.target, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    onKeyUp={(e) => handleKeyDown(e, index)}
                    onFocus={(e) => e.target.select()}
                    style={{ textAlign: "center", color: "white" }}
                  />
                ))}
            </div>
            <div className="w-100 m-t-24">
              <Button
                className="w-100"
                variety="filled"
                onClick={handleSubmit}
                height="56px"
              >
                Verify
              </Button>
            </div>
            <div className="text-align-center cursor-pointer m-t-12">
              <span className="gray-300">Didn't receive a code?</span>
              <span
                className="green-400 cursor-pointer semiboldL"
                onClick={handleResendOtp}
              >
                {" "}
                Resend code
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OtpVerification;
