import { useMutation } from "react-query";
import { baseRepository } from "../../repositories/base";

export const useMutationCreate = (url, headers) => {
  const mutationRes = useMutation(
    (variables) =>
      baseRepository
        .post(url, variables, null, headers)
        .then((res) => res.data)
        .catch((err) => {
          throw err; // Ensure the error is thrown to be caught by react-query
        }),
    {
      onError: (error, variables, context) => {
        if (context?.onError) {
          context.onError(error, variables, context);
        }
      },
      onSuccess: (data, variables, context) => {
        if (context?.onSuccess) {
          context.onSuccess(data, variables, context);
        }
      },
    }
  );

  const mutate = (variables, options) => {
    mutationRes.mutate(variables, { ...options });
  };

  const invalidateQueries = () => {};

  const returnItem = {
    data: mutationRes.data,
    isLoading: mutationRes.isLoading,
    isSuccess: mutationRes.isSuccess,
    invalidateQueries,
    mutate,
    error: mutationRes.error, // Ensure error is passed correctly
    isError: mutationRes.isError, // Add isError to indicate if there is an error
  };

  return returnItem;
};
