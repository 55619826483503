import "../Signup/Signup.css";
import React, { useCallback, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import loginImage from "../../../assets/png/login.png";
import { Logo } from "../../../assets/svg/Logo";
import "react-image-crop/dist/ReactCrop.css";
import ReactCrop from "react-image-crop";
import Button from "../../../components/Button";
import { APP_ROUTES } from "../../../utils/app-routes";
import { API_END_POINTS } from "../../../utils/api-endpoint";
import { useMutationCreate } from "../../../hooks/crud/mutation.create";
import Dialog from "../../../../src/components/Dialog/Dialog";
import Cropper from "react-easy-crop";
import { Box, Modal } from "@mui/material";
import getCroppedImg from "./getCroppedImage";

const UploadProfileImage = () => {
  const navigate = useNavigate();
  const imgRef = useRef(null);
  const fileInputRef = useRef(); // Create a ref to the file input
  const [dragging, setDragging] = useState(false);
  const [preview, setPreview] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 }); // Crop state
  const [completedCrop, setCompletedCrop] = useState(null); // Completed crop state
  const [selectedFile, setSelectedFile] = useState(null);
  const [croppedArea, setCroppedArea] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const { mutate: uploadProfileImage } = useMutationCreate(
    API_END_POINTS.uploadProfileImage,
    { "Content-Type": "multipart/form-data" }
  );

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const file = e.dataTransfer.files[0]; // Get the first file that was dropped
      const url = URL.createObjectURL(file); // Create a URL for that file
      setPreview(url);
      setSelectedFile(file);
      e.dataTransfer.clearData();
    }
  };

  //   const onImageLoaded = (image) => {
  //     return image;
  //   };

  const onImageLoaded = useCallback((img) => {
    imgRef.current = img;
  }, []);

  const onCropChange = (crop) => {
    setCrop(crop);
  };

  const handleFileSelect = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const url = URL.createObjectURL(file);
      setPreview(url);
      setSelectedFile(file);
    }
  };

  const handleContainerClick = () => {
    // Programmatically click the file input when the container is clicked
    fileInputRef.current.click();
  };

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("profile-image", selectedFile);

    uploadProfileImage(formData, {
      onSuccess(res) {
        navigate(APP_ROUTES.onboardingQuestion);
      },
      onError: (err) => {},
    });
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  }, []);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setPreview(reader.result);
        setIsModalOpen(true);
      };
      reader.readAsDataURL(file);
      setSelectedFile(file);
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setPreview(null);
  };

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(preview, croppedArea, rotation);
      setCroppedImage(croppedImage);
      handleModalClose();
    } catch (e) {
      console.error(e);
    }
  }, [preview, croppedArea, rotation]);

  return (
    <>
      <div className="container">
        <div className="login-banner">
          <img className="image" src={loginImage} alt="Login"></img>
        </div>

        <div className="box">
          <div className="form-side">
            <div
              style={{
                height: "3.125rem;",
                width: "11.53rem",
                marginBottom: "3.12rem",
              }}
            >
              <Logo />
            </div>
            <div>
              <span className="h4 white">Upload Profile Picture</span>
            </div>
            <div
              className="dotted-image-container"
              onClick={handleContainerClick} // Handle container click
              onDrop={handleDrop}
              onDragOver={handleDragIn}
              onDragEnter={handleDrag}
              onDragLeave={handleDragOut}
            >
              <p className="white">Drag files here to upload</p>
              <p className="white">
                Alternatively you can select file clicking here
              </p>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              {croppedImage && (
                <img
                  src={croppedImage}
                  alt="Preview"
                  style={{
                    position: "absolute", // Position the image absolutely
                    top: 0, // Align the image to the top of the container
                    left: 0, // Align the image to the left of the container
                    width: "100%", // Make the image fill the container
                    height: "100%", // Make the image fill the container
                    objectFit: "cover",
                  }}
                />
              )}
            </div>
            <div className="button-container margin-24">
              <Button variety="primary" onClick={() => handleSubmit()}>
                Submit
              </Button>
              <p
                className="regularL green-400 cursor-pointer"
                onClick={() => navigate(APP_ROUTES.onboardingQuestion)}
              >
                Skip
              </p>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={isModalOpen}
        onClose={handleModalClose}
        // aria-labelledby="crop-image-modal"
        // aria-describedby="crop-image-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            height: 500,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div style={{ position: "relative", height: "80%" }}>
            <Cropper
              image={preview}
              crop={crop}
              zoom={zoom}
              rotation={rotation}
              aspect={1}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onRotationChange={setRotation}
              onCropComplete={onCropComplete}
              onMediaLoaded={(mediaSize) => {
                setZoom(300 / mediaSize.naturalHeight);
              }}
            />
          </div>

          <Button onClick={showCroppedImage}>Done</Button>
        </Box>
      </Modal>
    </>
  );
};
export default UploadProfileImage;
