import React, { useEffect } from "react";
import "./Model.css";
import { CrossIcon } from "../../assets/svg/CrossIcon";
import Button from "../Button";
import Sizes from "../../utils/break-points";
import useWindowSize from "../../hooks/ui/useWindowSize";

// js doc
/**
 * @param title
 * @param titleIcon
 * @param children
 * @param handelAction1
 * @param handelAction2
 * @param show
 * @param footer
 * @param onClose
 * @param action1ButtonLabel
 * @param action2ButtonLabel
 * @param bool isDismissable
 * @returns {JSX.Element}
 * @constructor
 */
const Modal = ({
  title,
  titleIcon,
  children,
  handelAction1,
  handelAction2,
  show,
  footer,
  onClose,
  action1ButtonLabel,
  action2ButtonLabel,
  isDismissable,
}) => {
  const { width } = useWindowSize();

  useEffect(() => {
    if (show) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [show]);

  return (
    show && (
      <div>
        <div
          className="model-overlay"
          onClick={() => {
            if (isDismissable) {
              onClose();
            }
          }}
        />

        <div className="modal">
          <div className="modal-close" onClick={onClose}>
            {/* <CrossIcon className={"white"} /> */}
          </div>
          <div className="modal-header m-t-48">
            {titleIcon}
            <h2 className="white">{title}</h2>
          </div>

          {children}
        </div>
      </div>
    )
  );
};

export default Modal;
