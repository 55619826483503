import moment from "moment/moment";
import React, { useEffect } from "react";
import styled from "styled-components";
import MiniCalendar from "../Services/MiniCalendar";
import Button from "../Button";
import { useDataQuery } from "../../hooks/crud/data.query";
import { API_END_POINTS } from "../../utils/api-endpoint";

const ButtonWrapper = styled(Button)`
  background-color: rgba(0, 229, 190, 1);
  color: rgba(14, 38, 46, 1);
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  margin-top: 22px;
`;
const ChooseEventTime = ({
  selectedSlots,
  currentStep,
  setCurrentStep,
  selectedProfessional,
  setSelectedSlots,
  selectedDate,
  setSelectedDate,
  handleSubmit,
  selectedHours,
  selectedService,
  availableSlotData,
}) => {
  // const { data: availableSlotData } = useDataQuery({
  //   url: `${API_END_POINTS.getAvailableSlots}/${selectedService?._id}/${selectedProfessional?._id}`,
  //   params: { date: selectedDate, requestedHours: selectedHours },
  // });

  const getEventSlot = () => {
    if (selectedDate) {
      //   dispatch(
      //     getBookingSlotsByProfessionalId({
      //       serviceId: selectedService?._id,
      //       professionalId: selectedProfessional?._id,
      //       date: selectedDate ?? selectedDate,
      //       requestedHours: selectedHours?.value,
      //     })
      //   )
      //     .then((res) => {})
      //     .catch((err) => {});
    }
  };

  useEffect(() => {
    getEventSlot();
  }, [selectedDate]);

  useEffect(() => {
    setSelectedDate(moment(new Date()).add(3, "days").format("YYYY-MM-DD"));
  }, []);

  return (
    <div>
      <MiniCalendar
        data={availableSlotData?.data}
        selectedSlots={selectedSlots}
        setSelectedSlots={setSelectedSlots}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        // isLoading={availableListLoading}
      />
      <ButtonWrapper
        disabled={!selectedSlots}
        onClick={() => {
          setCurrentStep(currentStep + 1);
          handleSubmit();
        }}
        variety="filled"
        className="w-100 p-12"
      >
        Submit
      </ButtonWrapper>
    </div>
  );
};

export default ChooseEventTime;
