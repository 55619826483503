import axios from "axios";
import { GENESIS_USER_TOKEN, USER_DETAILS } from "./constants";
import { jwtDecode } from "jwt-decode";
import { APP_ROUTES } from "./app-routes";

export const http = axios.create({
  baseURL: `${process.env.REACT_APP_API_ENDPOINT}`,
  timeout: 30000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

// Change request data
http.interceptors.request.use(
  (config) => {
    const userDetails = localStorage.getItem(USER_DETAILS);
    const genesisToken = localStorage.getItem(GENESIS_USER_TOKEN);

    let userDetailsJson = userDetails ? JSON.parse(userDetails) : undefined;
    const token = userDetailsJson?.token
      ? userDetailsJson?.token
      : JSON.parse(genesisToken);

    if (token) {
      let decodedToken = jwtDecode(token);
      let secondsNow = new Date().getTime() / 1000;

      if (secondsNow > Number(decodedToken?.exp)) {
        localStorage.removeItem(USER_DETAILS);
        window.location.reload();
      }
    }

    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Change response data/error here
http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      window.location.href = `${window.location.replace(APP_ROUTES.login)}`;
      localStorage.removeItem(USER_DETAILS);
    }
    const errorRes = formatError(error);
    return Promise.reject(errorRes);
  }
);

export default http;

const formatError = (error) => {
  let errorRes = {
    message: error?.message ? error?.message : "Something went wrong",
  };

  if (axios.isAxiosError(error)) {
    errorRes = {
      message: error?.response?.data?.message || "Something went wrong",
      status: error?.response?.status,
      data: error?.response?.data,
    };
  }

  return errorRes;
};
