export const Level1Icon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
    >
      <g filter="url(#filter0_i_496_10188)">
        <path
          d="M22.3974 1.77044C23.3915 1.20698 24.6085 1.20698 25.6026 1.77044L34.065 6.56691L42.4501 11.4973C43.4351 12.0765 44.0436 13.1305 44.0527 14.2731L44.13 24L44.0527 33.7269C44.0436 34.8695 43.4351 35.9235 42.4501 36.5027L34.065 41.4331L25.6026 46.2296C24.6085 46.793 23.3915 46.793 22.3974 46.2296L13.935 41.433L5.54992 36.5027C4.56491 35.9235 3.95643 34.8695 3.94735 33.7269L3.87002 24L3.94735 14.2731C3.95643 13.1305 4.56491 12.0765 5.54992 11.4973L13.935 6.56694L22.3974 1.77044Z"
          stroke="#1AFFD8"
          strokeWidth="1.5"
        />
      </g>
      <path
        d="M27.978 29.5005L29.8425 27.084C30.0255 26.847 30.0495 26.523 29.904 26.2605C29.7585 25.998 29.4735 25.851 29.172 25.8795L25.9695 26.2095L24.6795 23.433C24.4335 22.905 23.565 22.905 23.319 23.433L22.029 26.2095L18.8265 25.8795C18.5265 25.851 18.24 25.9995 18.0945 26.2605C17.949 26.5215 17.973 26.8455 18.156 27.084L20.0205 29.5005L18.156 31.917C17.973 32.154 17.949 32.478 18.0945 32.7405C18.24 33.003 18.528 33.1515 18.8265 33.1215L22.029 32.7915L23.319 35.568C23.4435 35.8305 23.7075 36 24 36C24.2925 36 24.5565 35.8305 24.6795 35.5665L25.9695 32.79L29.172 33.12C29.4735 33.15 29.7585 33 29.904 32.739C30.0495 32.478 30.0255 32.154 29.8425 31.9155L27.978 29.5005Z"
        fill="#1AFFD8"
      />
      <path
        d="M29.251 12H24.751V22.725L29.9755 20.349C30.598 20.0655 31.0015 19.4415 31.0015 18.756V13.7505C31.0015 12.7845 30.2155 12 29.251 12Z"
        fill="#1AFFD8"
      />
      <path
        d="M23.2505 12H18.7505C17.786 12 17 12.7845 17 13.7505V18.759C17 19.4415 17.402 20.0655 18.0215 20.3505L23.2505 22.74V12Z"
        fill="#1AFFD8"
      />
      <defs>
        <filter
          id="filter0_i_496_10188"
          x="3.12012"
          y="0.597839"
          width="41.7598"
          height="48.8043"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_496_10188"
          />
        </filter>
      </defs>
    </svg>
  );
};
