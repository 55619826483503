export const questionData = [
  {
    id: 1,
    label: "What's your artist name?",
    name: "artistName",

    placeholder: "Please enter your artist name",
    validationMessages: {
      blank: "Please enter your artist name",
      startsWithSpace: "Cannot start with space",
    },
    tooltipText: "Who are you?",
  },

  {
    id: 2,
    label: "When do you plan to release next?",
    name: "nextRelease",

    placeholder: "Please enter when you plan to release next",
    validationMessages: {
      blank: "Please enter when you plan to release next",
      startsWithSpace: "Cannot start with space",
    },
    tooltipText: "When do you want to release your next track?",
  },
  {
    id: 3,
    label: "How many songs do you want to release this year?",
    name: "numOfSongs",

    placeholder:
      "Please enter the number of songs you want to release this year",
    validationMessages: {
      blank: "Please enter how many songs you want to release this year",
      startsWithSpace: "Cannot start with space",
    },
    tooltipText: "How many songs do you want to release this year?",
  },
  {
    id: 4,
    label: "How many followers do you want to grow by this year?",
    name: "numOfFollowers",

    placeholder: "Please enter the number of target followers",
    validationMessages: {
      blank: "Please enter how mnay followers you want to grow by this year.",
      startsWithSpace: "Cannot start with space",
    },
    tooltipText: "How much do you want your fanbase/community to grow this year?",
  },
  {
    id: 5,
    label: "Are you able to make music start to finish on your own?",
    name: "musicStart",

    placeholder: "Yes/No",
    validationMessages: {
      blank: "Please enter Yes/No",
      startsWithSpace: "Cannot start with space",
    },
    tooltipText: "What are your capabilities of making a song from start to finish? Are there any gaps or weak points where you still need support? [example: mixing, mastering, writing, etc.]",
  },
  {
    id: 6,
    label: "Are you able to make video content on your own?",
    name: "videoContentOnOwn",

    placeholder: "Please enter if you can make video content on your own",
    validationMessages: {
      blank: "Please enter Yes/No",
      startsWithSpace: "Cannot start with space",
    },
    tooltipText: "What are your capabilities of making visual content from start to finish? Are there any gaps or weak points where you still need support? [example: graphic design, photography, video editing, etc.]",
  },
  {
    id: 7,
    label: "Who are your influences?",
    name: "influence",

    placeholder: "Please enter your influences",
    validationMessages: {
      blank: "Please enter your influences",
      startsWithSpace: "Cannot start with space",
    },
    tooltipText: "Over the course of time, you’ve listened to and watched many different musicians and influential people. Think back at who really impacted you and/or moved you emotionally during your developmental time.",
  },
  {
    id: 8,
    label: "What style(s) of music do you make?",
    name: "musicStyles",

    placeholder: "Please enter the style(s) of music you make",
    validationMessages: {
      blank: "Please enter the style(s) of music you make",
      startsWithSpace: "Cannot start with space",
    },
    tooltipText: "How would you describe the style of your music? Obviously we don’t want to unnecessarily constrain you to a certain sound, but this helps everyone understand the general direction of your music.",
  },
  {
    id: 9,
    label: "How would you describe your visual aesthetic?",
    name: "visualAesthetic",

    placeholder: "Please enter your visual aesthetic",
    validationMessages: {
      blank: "Please enter your visual aesthetic",
      startsWithSpace: "Cannot start with space",
    },
    tooltipText: "How does your visual aesthetic feel? How does it help support or amplify the feelings and messages in your music? Why do you feel this helps?",
  },
  {
    id: 10,
    label: "What were the defining moments that lead you to making music?",
    name: "definingMoments",

    placeholder:
      "Please enter the defining moments that lead you to making music",
      validationMessages: {
        blank: "Please enter the defining moments that lead you to making music",
        startsWithSpace: "Cannot start with space",
      },
      tooltipText: "Along with specific people, there may have been specific moments that impacted you and sparked a deep desire to create. Think back to any moments that may come to mind.",
  },
  {
    id: 11,
    label: "Who is your ideal fan?",
    name: "idealFan",

    placeholder: "Please describe your ideal fan",
    validationMessages: {
      blank: "Please describe your ideal fan",
      startsWithSpace: "Cannot start with space",
    },
    tooltipText: "With your message in mind, if you had a room full of people who were deeply passionate about your music, what would that look like? Who are they? What do they do? What do they look like? How do they act?",
  },
  {
    id: 12,
    label: "What messages do you want to share with your fans?",
    name: "messageSharingWithFans",

    placeholder: "Please enter the messages you want to share with your fans",
    validationMessages: {
      blank: "Please enter the the messages you want to share with your fans",
      startsWithSpace: "Cannot start with space",
    },
    tooltipText: "Along with all of your inspiration as a creative, you may have specific messages that come up consistently in your music and content. What are they? How do you want to share them?",
  },
];
