import React from "react";

export const DashboardIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        d="M4.41608 3.90308H10.474C10.674 4.10302 10.7868 4.21589 10.9868 4.41583V13.1053C10.7868 13.3052 10.674 13.4181 10.474 13.6181H4.41608C4.21613 13.4181 4.10326 13.3052 3.90332 13.1053V4.41583C4.10326 4.21589 4.21613 4.10302 4.41608 3.90308Z"
        fill="white"
        stroke="white"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M14.4996 3.90308H20.5575C20.7575 4.10302 20.8703 4.21589 21.0703 4.41583V8.247C20.8703 8.44694 20.7575 8.55981 20.5575 8.75976H14.4996C14.2996 8.55981 14.1868 8.44694 13.9868 8.247V4.41583L14.4996 3.90308Z"
        stroke="white"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M14.4996 11.7598H20.5575L21.0703 12.2725V20.962C20.8703 21.1619 20.7575 21.2748 20.5575 21.4747H14.4996L13.9868 20.962V12.2725C14.1868 12.0726 14.2996 11.9597 14.4996 11.7598Z"
        stroke="white"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M4.41608 16.618H10.474L10.9868 17.1308V20.962C10.7868 21.1619 10.674 21.2748 10.474 21.4747H4.41608C4.21613 21.2748 4.10326 21.1619 3.90332 20.962V17.1308C4.10326 16.9309 4.21613 16.818 4.41608 16.618Z"
        stroke="white"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
    </svg>
  );
};
