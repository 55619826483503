import { Level1Icon } from "../../assets/svg/BadgeLevelIcon";

export const BadgeData = [
  {
    id: 1,
    label: "Level 1",
    title: "Defining your brand",
    description: "This is level 1",
    icon: <Level1Icon />,
  },
  {
    id: 2,
    label: "Level 2",
    title: "Establishing a foundation",
    description: "This is level 2",
    icon: <Level1Icon />,
  },
  {
    id: 3,
    label: "Level 3",
    title: "Establishing a foundation",
    description: "This is level 3",
    icon: <Level1Icon />,
  },
];
