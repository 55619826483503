import React from "react";
import DatePicker from "react-datepicker";
import Button from "../Button";
export const QuickAddComponent = ({
  item,
  projectCategoryData,
  onSaveProject,
  handleEditTask,
  index,
  handleSelectProject,
  handleCancelTask,
}) => {
  return (
    <div className="quick-add-task-container flex-column m-8">
      <input
        className="border-none regularXL"
        type="text"
        placeholder="Task title"
        value={item?.title}
        onChange={(e) =>
          handleEditTask(index, e.target.value, item?.description, item?.date)
        }
      />
      <textarea
        placeholder="Description"
        className="border-none bg-transparent p-12 white"
        value={item?.description}
        onChange={(e) =>
          handleEditTask(index, item?.title, e.target.value, item?.date)
        }
      />
      <div>
        <DatePicker
          placeholderText="Select date"
          selected={item?.date ? new Date(item?.date) : null}
          onChange={(date) =>
            handleEditTask(index, item?.title, item?.description, date)
          }
          className="bg-transparent quick-add-task-container white m-8 w-20 "
        />
      </div>
      <div className="flex-row justify-space-between p-12 border-top-task-add-container right">
        {/* <select
          className="w-30  bg-transparent quick-add-task-container white"
          onChange={(e) => handleSelectProject(e.target.value)}
        >
          <option>Select Project to update</option>
          {projectCategoryData?.data?.map((item, index) => (
            <option value={item?._id} id={item?._id}>
              {item?.name}
            </option>
          ))}
        </select> */}
        <div className="w-50"></div>
        <Button variety="outlined" onClick={handleCancelTask}>
          Cancel
        </Button>
        <Button variety="filled" onClick={onSaveProject}>
          Save
        </Button>
      </div>
    </div>
  );
};
