import moment from "moment";
import { CalendarIcon } from "../../assets/svg/CalendarIcon";
import { CheckCircle } from "../../assets/svg/CheckCircle";
import { LocationIcon } from "../../assets/svg/LocationIcon";
import { AnimatedList } from "../magicui/animated-list";
import "./ListView.css";
import React, { useEffect, useRef, useState } from "react";
import { ClockIcon, Pencil1Icon, CircleIcon, CrossCircledIcon } from "@radix-ui/react-icons";
import { notify } from "../Toast/ToastComponent";
import { DatePicker } from "antd";
import { useMutationPatch } from "../../hooks/crud/mutation.patch";
import { API_END_POINTS } from "../../utils/api-endpoint";

export const ListViewComponent = ({
  title,
  date,
  duration,
  onClick,
  handleMark,
  completed,
  status,
  projectData,
  data,
  handleEditTask,
  selectedProjectIndex,
  setData,
  onClickComplete = () => {},
  // patchTask,
  setSelectedData,
  selectedData,
  task,
}) => {
  const [isFirstRender, setIsFirstRender] = useState(false);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [editedTitle, setEditedTitle] = useState(title);
  const [showEditIcon, setShowEditIcon] = useState(false);
  const [editedDate, setEditedDate] = useState();
  const [showDatePicker, setShowDatePicker] = useState(false);
  const inputRef = useRef(null);

  // update the task
  const { mutate: patchTask } = useMutationPatch(
    `${API_END_POINTS.tasks}/${task?._id}`
  );
  const calculateEstimatedTime = (selectedEvent) => {
    const startDate = new Date(selectedEvent?.startDate);
    const endDate = new Date(selectedEvent?.endDate);

    const timeDifference = endDate - startDate;

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );

    return { days, hours, minutes };
  };

  const estimatedTime = calculateEstimatedTime(duration);
  const displayEstimatedTime = () => {
    const { days, hours, minutes } = estimatedTime;
    const parts = [];
    if (days > 0) parts.push(`${days} days`);
    if (hours > 0) parts.push(`${hours} hours`);
    if (minutes > 0) parts.push(`${minutes} minutes`);
    return parts.length > 0 ? parts.join(", ") : "0 days";
  };

  const handleEditComplete = (index, title, description, date) => {
    setIsEditingTitle(false);
    const updatedTasks = { ...task };
    updatedTasks.title = editedTitle;
    // setData(updatedTasks);
    patchTask(updatedTasks, {
      onSuccess(res) {
        notify("Task updated successfully", "success");
      },
      onError: (err) => {
        notify(err?.error?.message, "error");
      },
    });
  };

  const handleEditDateComplete = (date) => {
    setShowDatePicker(false);
    const updatedTasks = { ...task };
    updatedTasks.endDate = date?.toISOString();
    // setData(updatedTasks);
    console.log("updated tasks", updatedTasks);
    patchTask(updatedTasks, {
      onSuccess(res) {
        notify("Task updated successfully", "success");
      },
      onError: (err) => {
        notify(err?.error?.message, "error");
      },
    });
  };

  useEffect(() => {
    const hasLoadedBefore = sessionStorage.getItem("hasLoadedBefore");

    if (!hasLoadedBefore) {
      setIsFirstRender(true);
      setTimeout(() => {
        sessionStorage.setItem("hasLoadedBefore", "true");
      }, 500);
    }
  }, [isFirstRender]);

  const isPastDate = new Date(date) < new Date();
  const dateClass = isPastDate ? "red" : "gray-300";

  const ListComponent = isFirstRender ? AnimatedList : "div";

  const selectedProject = projectData?.filter(
    (item) => data?.project === item?._id
  );

  return (
    <ListComponent>
      <div
        onClick={(e) => {
          if (!isEditingTitle) {
            onClick(e);
          }
        }}
        className="list-view-wrapper h-16 p-6 m-8 cursor-pointer relative rounded-md hover:bg-[rgba(38,43,75,1)]"
      >
        <div className="flex justify-between items-center h-full">
          {/* TITLE & EDIT ICON */}
          <div
            className="inline-flex items-center"
            onClick={(e) => {
              e.stopPropagation();
              setIsEditingTitle(true);
              setSelectedData(task);
            }}
            onMouseEnter={() => setShowEditIcon(true)}
            onMouseLeave={() => setShowEditIcon(false)}
          >
            {isEditingTitle ? (
              <input
                ref={inputRef}
                type="text"
                value={editedTitle}
                onChange={(e) => setEditedTitle(e.target.value)}
                onBlur={handleEditComplete}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleEditComplete();
                  }
                }}
                className="text-lg font-semibold bg-transparent text-white border-none border-white focus:outline-none p-0"
              />
            ) : (
              <span className="text-white text-lg font-semibold pl-4">
                {selectedProject?.[0]
                  ? selectedProject?.[0]?.title + " - " + editedTitle
                  : editedTitle}
              </span>
            )}
            {showEditIcon && !isEditingTitle && (
              <Pencil1Icon
                onClick={(e) => {
                  e.stopPropagation();
                  setIsEditingTitle(true);
                  setSelectedData(task);
                }}
                className="ml-2 cursor-pointer text-white"
              />
            )}
          </div>
          {/* DATE, DURATION & COMPLETE ICON */}
          <div className="flex-row items-center absolute right-0">
            <div className="flex items-center mr-4 w-32 justify-start"> {/* Add margin to the right */}
            {!showDatePicker ? (
            // Render the calendar icon and date span when showDatePicker is false
            <div
              className="flex items-center pr-4 relative"
              onClick={(e) => {
                e.stopPropagation();
                setShowDatePicker(true);
              }}
            >
              <CalendarIcon color={isPastDate ? "#EF4870" : "#D1D5DB"} />
              <span className={`pl-2 text-sm ${dateClass}`}>
                {editedDate
                  ? moment(editedDate?.toISOString())?.format("MMM DD YYYY")
                  : date}
              </span>
            </div>
          ) : (
            // Render the DatePicker when showDatePicker is true
            <div
              className="mt-2 z-10 p-2 rounded-md shadow-lg"
              onClick={(e) => e.stopPropagation()}
            >
              <DatePicker
                className="bg-transparent"
                selected={date}
                onChange={(newDate) => {
                  setSelectedData({
                    ...task,
                    endDate: editedDate?.toISOString(),
                  });
                  setEditedDate(newDate);
                  handleEditDateComplete(newDate);
                }}
              />
                </div>
              )}
            </div>
            {duration && (
              <div className="flex items-center w-24 justify-start"> {/* Add margin to the right */}
                <ClockIcon color={"var(--Genesis-Gray-300)"} />
                <span className="regularS white p-1">
                  {displayEstimatedTime()}
                </span>
              </div>
            )}
            {/* STATUS */}
            {!completed && (
              <div className="flex items-center w-32 justify-start">
                {status === "TODO" && <CircleIcon color="#06719A" />}
                {status === "IN_PROGRESS" && <ClockIcon color="#FF7700" />}
                {status === "BLOCKED" && <CrossCircledIcon color="#EF4870" />}
                <span className="regularS white p-1">
                  {status === "TODO" && "To Do"}
                  {status === "IN_PROGRESS" && "In Progress"}
                  {status === "BLOCKED" && "Blocked"}
                </span>
              </div>
            )}
            <div
              className="flex items-center cursor-pointer mr-4" // Add cursor pointer for interactivity
              onClick={(e) => {
                e.stopPropagation();
                onClickComplete();
              }}
            >
              <CheckCircle />
              <span className={`regularS p-1 ${completed ? "white" : "green-300"}`}>
                {completed ? "Completed" : "Mark as complete"}
              </span>
            </div>
          </div>
        </div>
      </div>
    </ListComponent>
  );
};

export const EventListViewComponent = ({
  title,
  date,
  duration,
  description,
  location,
  item,
  onClick,
  handleEditTask,
  selectedProjectIndex,
  setData,
  onClickComplete = () => { },
  patchTask,
  setSelectedData,
  task,
  refetch,
}) => {
  const [isFirstRender, setIsFirstRender] = useState(false);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [editedTitle, setEditedTitle] = useState(title);
  const [showEditIcon, setShowEditIcon] = useState(false);
  const [editedDate, setEditedDate] = useState(date);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    const hasLoadedBeforeEvent = sessionStorage.getItem("hasLoadedBeforeEvent");
    if (!hasLoadedBeforeEvent) {
      setIsFirstRender(true);
      setTimeout(() => {
        sessionStorage.setItem("hasLoadedBeforeEvent", "true");
      }, 500);
    }
  }, [isFirstRender]);
  const EventComponent = isFirstRender ? AnimatedList : "div";

  const truncateText = (text, maxLength) => {
    if (!text) return "";
    return text.length > maxLength
      ? text.substring(0, maxLength) + "..."
      : text;
  };

  const handleEditComplete = (index, title, description, date) => {
    setIsEditingTitle(false);
    // const updatedTasks = { ...task };
    // updatedTasks.title = editedTitle;
    // // setData(updatedTasks);
    // patchTask(updatedTasks, {
    //   onSuccess(res) {
    //     notify("Task updated successfully", "success");
    //   },
    //   onError: (err) => {
    //     notify(err?.error?.message, "error");
    //   },
    // });
  };

  const handleEditDateComplete = (date) => {
    if (editedDate === date) {
      setShowDatePicker(false);
      return;
    } else {
      setShowDatePicker(false);
      // const updatedData = data?.map((item, i) => {
      //   // If the item has a taskLists array, add the new task to it
      //   if (i === selectedProjectIndex && item?.tasksList) {
      //     const updatedTasks = [...item?.tasksList];
      //     // console.log("updatedTasks", updatedTasks);

      //     updatedTasks[i].endDate = date;
      //     // updatedTasks[index].description = description;
      //     // updatedTasks[index].date = date;
      //   }
      //   return item;
      // });

      // setData(updatedData);
      // patchTask(updatedData[selectedProjectIndex]?.tasksList?.[0], {
      //   onSuccess(res) {
      //     // if (selectedViewType === "List View") {
      //     //   setJustUpdated(true);
      //     // }
      //     // setIsModalOpen(false);
      //     // refetchDashboardData();
      //     notify("Task updated successfully", "success");
      //     refetch();
      //   },
      //   onError: (err) => {
      //     notify(err?.error?.message, "error");
      //   },
      // });
    }
    // handleEditTask();
    // Add any additional logic needed for successful edit here
  };
  return (
    <EventComponent>
      <div
        className="list-view-wrapper p-8 m-8 cursor-pointer"
        onClick={(e) => {
          if (!isEditingTitle) {
            onClick(e);
          }
        }}
      >
        <div className="flex-row justify-space-between">
          <div className="flex justify-between items-center">
            <div
              className="w-full relative"
              onMouseEnter={() => setShowEditIcon(true)}
              onMouseLeave={() => setShowEditIcon(false)}
            >
              {isEditingTitle ? (
                <input
                  ref={inputRef}
                  type="text"
                  value={editedTitle}
                  onChange={(e) => setEditedTitle(e.target.value)}
                  onBlur={handleEditComplete}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleEditComplete();
                    }
                  }}
                  className="text-lg font-semibold bg-transparent text-white border-none border-white focus:outline-none p-0"
                />
              ) : (
                <span className="text-white text-lg font-semibold">
                  {title}
                </span>
              )}
              {showEditIcon && !isEditingTitle && (
                <Pencil1Icon
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsEditingTitle(true);
                    setSelectedData(task);

                    // setSelectedData(data)
                  }}
                  className="absolute right-0 top-1/2 transform -translate-y-1/2 cursor-pointer text-white"
                />
              )}
            </div>
          </div>
          {/* <span className="regularS white">{title}</span> */}
          <div className="flex-row align-center w-40">
            <div className="flex-row align-center">
              <CalendarIcon />{" "}
              <span className="regularS p-4 red">
                {item?.isGoogleEvent
                  ? moment(item?.calendarData?.start?.dateTime).format(
                    "MMM DD YYYY"
                  )
                  : date}
              </span>
            </div>
            {showDatePicker && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className="absolute top-full mt-2 z-10 p-2 rounded-md shadow-lg"
              >
                <DatePicker
                  className="bg-transparent"
                  selected={new Date(date)}
                  value={
                    item?.isGoogleEvent
                      ? item?.calendarData?.start?.dateTime
                      : date
                  }
                  onChange={(newDate) => {
                    setSelectedData({ ...task, endDate: newDate });
                    setEditedDate(newDate);
                    handleEditDateComplete(newDate);
                    setShowDatePicker(false);
                  }}

                  // inline
                />
              </div>
            )}
            {location && (
              <div className="flex-row align-center">
                <LocationIcon />
                <span className="regularS p-4 white">
                  {truncateText(location, 20)}
                </span>
              </div>
            )}
            <span>{duration}</span>
          </div>
        </div>
        <div>
          <span className="white">{truncateText(description, 49)}</span>
        </div>
      </div>
    </EventComponent>
  );
};
