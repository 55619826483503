export const customStyle = {
  container: (provided, state) => ({
    ...provided,
    width: "100%",
    border: state.isFocused && "1px solid var(--Genesis-Green-400)",
    borderRadius: "0.375rem",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    overflow: "visible",
    padding: "0.1rem 1.8rem 0.688rem 1rem",
    color: "#1B1B1B",
    height: 40,
    background: "var(--App-Background)",
    borderRadius: "0.375rem",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    position: "absolute",
    top: state.hasValue || state.selectProps.inputValue ? -20 : "12%",
    backgroundColor:
      state.hasValue || state.selectProps.inputValue ? "white" : "transparent",
    transition: "top 0.1s, font-size 0.1s",
    fontSize: (state.hasValue || state.selectProps.inputValue) && "1rem",
    padding: 0,
    margin: 0,
    color: "#4f5d73",
    fontWeight: 400,
  }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "var(--App-Background)",
    border: state?.isFocused
      ? "1px solid rgba(158, 156, 174, 0.3)"
      : "1px solid rgba(158, 156, 174, 0.3)",
    borderRadius: "0.375rem",
    borderColor: state?.isFocused
      ? "rgba(158, 156, 174, 0.3)"
      : "rgba(158, 156, 174, 0.3)",
    boxShadow: "none",
    "&:hover": {
      border: "1px solid rgba(158, 156, 174, 0.3)",
    },
  }),
  input: (provided, state) => ({
    ...provided,
    padding: 0,
    margin: 0,
    color: "#fff",
  }),
  option: (provided, state) => ({
    ...provided,
    background: state?.isSelected ? "var(--Genesis-Green-400)" : null,
    "&:hover": {
      background: "var(--Genesis-Green-400)",
      opacity: "0.4",
      color: "black",
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "var(--Genesis-Gray-300)",
    fontSize: "1rem",
  }),
};
