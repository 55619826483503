import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutationCreate } from "../../hooks/crud/mutation.create";
import { API_END_POINTS } from "../../utils/api-endpoint";
import { APP_ROUTES } from "../../utils/app-routes";

const Callback = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { mutate: postSpotifyToken } = useMutationCreate(
    API_END_POINTS.storeSpotifyToken
  );

  useEffect(() => {
    const searchParams = location?.search;

    const accessToken = searchParams?.split("code=")?.[1].split("&")?.[0];
    if (accessToken) {
      postSpotifyToken({ token: accessToken });
      navigate(APP_ROUTES.social);
    }
  }, [location]);

  return (
    <div>
      <h3 className="white">Processing...</h3>
      {/* You can show a loading spinner or a message while the authorization process is in progress */}
    </div>
  );
};

export default Callback;
