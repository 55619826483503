import React, { useState } from "react";
import {
  CardElement,
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import Card from "../../components/Card/Card";
import Button from "../../components/Button";
import { Controller, useForm } from "react-hook-form";
import { useMutationCreate } from "../../hooks/crud/mutation.create";
import { API_END_POINTS } from "../../utils/api-endpoint";
import { TAX_RATE, USER_DETAILS } from "../../utils/constants";
import { APP_ROUTES } from "../../utils/app-routes";

const CARD_BRAND_TO_LOGO = {
  visa: "https://example.com/visa-logo.png",
  mastercard: "https://example.com/mastercard-logo.png",
  // Add more card brands and logos here
};

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#fff",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      width: "100px",
      "::placeholder": {
        color: "grey",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const INTRO_PLAN_PRICE_ID = "price_1NFj2oBdxkdqUWmP7QY1m3TQ";
const BASIC_PLAN_PRICE_ID = "price_1NEZ58BdxkdqUWmPdS7KWGgX";
const ENTERPRISE_PLAN_PRICE_ID = "price_1NEZ5RBdxkdqUWmP1KeU78aV";

const CheckoutForm = ({ selectedPlan, navigate }) => {
  const stripe = useStripe();
  const elements = useElements();
  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    register,
    watch,
  } = useForm({});
  const [cardBrand, setCardBrand] = useState(null);
  const [taxRate, setTaxRate] = useState(TAX_RATE);
  const { mutate: createSubcription } = useMutationCreate(
    API_END_POINTS.stripeSubscription
  );

  const userData = localStorage.getItem(USER_DETAILS);
  // Watch the value of the checkbox
  const isChecked = watch("checked", false);

  //total value after tax
  const totalAmount =
    selectedPlan?.price + (selectedPlan?.price * taxRate) / 100;
  const onSubmit = async (event) => {
    // event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardNumberElement = elements.getElement(CardNumberElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);
    const cardCvcElement = elements.getElement(CardCvcElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardNumberElement,
    });
    const parsedData = JSON.parse(userData);
    await createSubcription(
      {
        paymentMethod: paymentMethod?.id,
        name: parsedData?.data?.fullName,
        email: parsedData?.data?.emailAddress,
        priceId:
          selectedPlan?.planType === "Intro Plan"
            ? INTRO_PLAN_PRICE_ID
            : selectedPlan?.planType === "Basic Plan"
            ? BASIC_PLAN_PRICE_ID
            : ENTERPRISE_PLAN_PRICE_ID,
      },
      {
        onSuccess(res) {
          navigate(APP_ROUTES.paymentSuccess);
        },
      }
    );
    if (error) {
      console.log("[error]", error);
    } else {
      console.log("[PaymentMethod]", paymentMethod);
      // Send paymentMethod.id to your server for processing
    }
  };

  const handleCardNumberChange = (event) => {
    setCardBrand(event.brand);
    // console.log("event", event);
  };

  return (
    <form style={{ width: "676px" }} onSubmit={handleSubmit(onSubmit)}>
      <Card className={"flex-column p-12 card-second-wrapper"}>
        <div className="m-8">
          <span className="gray-300 regularL">Card number</span>
          <div
            className="b-r-8 m-t-8"
            style={{
              border: "0.6px solid rgba(158, 156, 174, 0.3)",
              background: "rgba(37, 31, 52, 1)",
              padding: "11px 16px",
            }}
          >
            <CardNumberElement
              options={CARD_ELEMENT_OPTIONS}
              onChange={handleCardNumberChange}
            />
          </div>
        </div>
        <div className="flex-row justify-space-between">
          <div className="m-8 w-48">
            <span className="gray-300 regularL">Expiration date</span>
            <div
              className="b-r-8 m-t-8"
              style={{
                border: "0.6px solid rgba(158, 156, 174, 0.3)",
                background: "rgba(37, 31, 52, 1)",
                padding: "11px 16px",
              }}
            >
              <CardExpiryElement options={CARD_ELEMENT_OPTIONS} />
            </div>
          </div>
          <div className="m-8 w-48">
            <span className="gray-300 regularL">Security Code</span>
            <div
              className="b-r-8 m-t-8"
              style={{
                border: "0.6px solid rgba(158, 156, 174, 0.3)",
                background: "rgba(37, 31, 52, 1)",
                padding: "11px 16px",
              }}
            >
              <CardCvcElement options={CARD_ELEMENT_OPTIONS} />
            </div>
          </div>
        </div>
        <div className="m-t-24 ">
          <label className="gray-300 regularXS align-center p-relative">
            <Controller
              name="checked"
              control={control}
              render={({ field }) => <input type="checkbox" {...field} />}
            />
            <div>
              By providing your card information, you allow GENESIS STUDIO to
              charge your card for future payments in accordance with their{" "}
              <a
                href={process.env.REACT_APP_TERMS_OF_SERVICE_URL}
                target="_blank"
                rel="noopener noreferrer"
                className="green-400 regularXS"
              >
                terms
              </a>
              .
            </div>
          </label>
        </div>
        <div className="quick-add-task-container p-12 m-t-24">
          <div className="flex-row justify-space-between">
            <span className="gray-300">Subtotal</span>
            <span className="white mediumM">${selectedPlan?.price}</span>
          </div>
          <div className="flex-row justify-space-between m-t-24">
            <span className="gray-300">Tax</span>
            <span className="white mediumM">{taxRate}%</span>
          </div>

          <div
            className="flex-row justify-space-between p-t-12 m-t-12"
            style={{ borderTop: "0.6px solid var(--Genesis-Gray-500)" }}
          >
            <span className="gray-300">Total</span>
            <span className="green-400 mediumM">${totalAmount}</span>
          </div>
        </div>

        {/* <CardExpiryElement />
      <CardCvcElement /> */}

        <Button
          disabled={!isChecked}
          className="m-t-24"
          variety="filled"
          height="48px"
        >
          Pay
        </Button>
      </Card>
    </form>
  );
};

export default CheckoutForm;
