import React, { useState } from "react";

import styled from "styled-components";
import BookingBanner from "../../assets/png/login.png";
import ChooseEventTime from "./ChooseEventTime";
import ChooseProfessionalStep from "./ChooseProfessionalStep";
import Payment from "./Payment";
import ServiceStep from "./ServiceStep";
import StepperComponent from "./StepperComponent";
import "./index.css";
import { useMutationCreate } from "../../hooks/crud/mutation.create";
import { API_END_POINTS } from "../../utils/api-endpoint";
import { useDataQuery } from "../../hooks/crud/data.query";

const MainDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const CardWrapperDiv = styled.div`
  width: 55%;
  background-color: #1f1a2b;
  border-radius: 8px;
  color: #fff;
`;
const SecondBannerContainer = styled.div`
  width: 40%;
`;

const StepperWrapper = styled.div`
  background-color: rgba(90, 75, 126, 0.1);
  height: 134px;
  border-bottom: 0.6px solid rgba(158, 156, 174, 0.12);
  display: flex;
  justify-content: center;
`;

const ContentWrapper = styled.div`
  margin-top: 50px;
  padding: 0 24px 24px 24px;
`;

const List = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedProfessional, setSelectedProfessional] = useState(null);
  const [selectedSlots, setSelectedSlots] = useState(null);
  const [selectedDate, setSelectedDate] = useState();
  const [selectedHours, setSelectedHours] = useState();

  const { mutate: postBooking, data: postBookingResponse } = useMutationCreate(
    API_END_POINTS.booking
  );

  const { data: availableSlotData } = useDataQuery({
    url: `${API_END_POINTS.getAvailableSlots}/${selectedService?._id}/${selectedProfessional?._id}`,
    params: { date: selectedDate, requestedHours: selectedHours },
  });

  // const { payload: availableListPayload, loading: availableListLoading } =
  //   useSelector((state) => state.availableSlots);

  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleSubmit = () => {
    // dispatch(
    postBooking({
      // professionalId: selectedProfessional?._id,
      // serviceId: selectedService?._id,
      startTime: selectedSlots,
      bookingDate: selectedDate,
      requestedHours: Number(selectedHours),
      serviceProfessionalId: availableSlotData?.data?.id,
      price: availableSlotData?.data?.price,
    });
    // );
  };

  const handleHoursChange = (e) => {
    setSelectedHours(e.target.value);
  };

  return (
    <MainDiv>
      <CardWrapperDiv title={"card"} borderless>
        <StepperWrapper>
          <StepperComponent
            currentStep={currentStep}
            onNext={handleNext}
            onPrev={handlePrev}
            setCurrentStep={setCurrentStep}
          />
        </StepperWrapper>
        <ContentWrapper>
          {currentStep === 1 ? (
            <ServiceStep
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              selectedService={selectedService}
              setSelectedService={setSelectedService}
              handleHoursChange={handleHoursChange}
            />
          ) : currentStep === 2 ? (
            <ChooseProfessionalStep
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              selectedProfessional={selectedProfessional}
              setSelectedProfessional={setSelectedProfessional}
              selectedService={selectedService}
            />
          ) : currentStep === 3 ? (
            <ChooseEventTime
              selectedService={selectedService}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              selectedProfessional={selectedProfessional}
              selectedSlots={selectedSlots}
              setSelectedSlots={setSelectedSlots}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              selectedHours={selectedHours}
              handleSubmit={handleSubmit}
              availableSlotData={availableSlotData}
            />
          ) : currentStep === 4 ? (
            <Payment
              availableListPayload={availableSlotData}
              postBookingResponse={postBookingResponse}
            />
          ) : null}
        </ContentWrapper>
      </CardWrapperDiv>
      <SecondBannerContainer>
        <img
          style={{ width: "100%" }}
          src={BookingBanner}
          alt="Booking Banner"
        />
      </SecondBannerContainer>
    </MainDiv>
  );
};

export default List;
