import React from "react";
import Button from "../Button";
import { CalendarIcon, ClockIcon, PersonIcon } from "@radix-ui/react-icons";
import { LocationIcon } from "../../assets/svg/LocationIcon";
import { Controller, useForm } from "react-hook-form";
import ReactDatePicker from "react-datepicker";
// import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  DatePickerInputComponent,
  TimePickerInputComponent,
} from "../Input/CustomInputComponent";
import { APP_ROUTES } from "../../utils/app-routes";
import FormSelect from "../Select/FormSelect";
import { priorityData } from "../CreateTask/constant";
import { TimePicker } from "antd";
const weeklyOptions = [
  { label: "Every week", value: "weekly" },
  { label: "Every 2 weeks", value: "bi-weekly" },
  { label: "Every month", value: "monthly" },
];

export const CreateEventForm = ({ setIsCreateModalOpen, onSubmit }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    reset,
    setValue,
  } = useForm({
    defaultValues: {},
    // resolver: yupResolver(),
  });

  return (
    <form className="w-100" onSubmit={handleSubmit(onSubmit)}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className="p-12 w-100">
          <div className="w-100">
            <label className="create-label regularM">Event Title</label>
            <Controller
              name="title"
              control={control}
              render={({ field }) => (
                <input
                  className="m-t-8 w-90 quick-add-task-container create-project-input"
                  placeholder="Add event title"
                  {...register("title")}
                />
              )}
            />
          </div>
          <div>
            <label className="create-label regularM">Event Description</label>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <input
                  placeholder="Add event description"
                  className="p-12 m-t-8 w-90 quick-add-task-container create-project-input"
                  // style={{ maxWidth: "95%" }}
                  {...register("eventDetails")}
                />
              )}
            />
          </div>
          <div className="flex-row justify-space-between w-90">
            <div className="flex-column w-45 m-t-8">
              <span className="gray-300 mediumM">Start date</span>

              <Controller
                name={`startDate`}
                control={control}
                render={({ field }) => (
                  // <ReactDatePicker
                  //   selected={field.value}
                  //   fixedHeight
                  //   onChange={(date) => field.onChange(date)}
                  //   className="m-t-8 w-40 quick-add-task-container create-project-input"
                  //   placeholderText="Due Date"
                  //   dateFormat={"dd/MM/yyyy"}
                  // />
                  <div>
                    <DatePickerInputComponent
                      selected={field.value}
                      onChange={(date) => field.onChange(date)}
                      icon={<CalendarIcon color="#fff" />}
                      placeholderText="Start Date"
                    />
                  </div>
                )}
              />
            </div>
            <div className="flex-column w-45 m-t-8">
              <span className="gray-300 mediumM">End date</span>

              <Controller
                name={`endDate`}
                control={control}
                render={({ field }) => (
                  <div>
                    <DatePickerInputComponent
                      selected={field.value}
                      onChange={(date) => field.onChange(date)}
                      icon={<CalendarIcon color="#fff" />}
                      placeholderText="End Date"
                      // {...register("endDate")}
                    />
                  </div>
                  // <ReactDatePicker
                  //   selected={field.value}
                  //   fixedHeight
                  //   onChange={(date) => field.onChange(date)}
                  //   className="m-t-8 w-40 quick-add-task-container create-project-input"
                  //   placeholderText="Due Date"
                  //   dateFormat={"dd/MM/yyyy"}
                  // />
                )}
              />
            </div>
          </div>
          <div className="flex-row justify-space-between w-90">
            <div className="flex-column w-45 m-t-8">
              <span className="gray-300 mediumM">Start Time</span>

              <Controller
                name={`time`}
                control={control}
                render={({ field }) => (
                  <div>
                    <TimePicker.RangePicker
                      className="quick-add-task-container z-9999 text-white focus:bg-transparent"
                      onChange={(date) => field.onChange(date)}
                      // {...register("startTime")}
                    />
                    {/* <TimePickerInputComponent
                      value={field.value}
                      onChange={(date) => field.onChange(date)}
                      icon={<ClockIcon color="#fff" />}
                      placeholderText="Start Time"
                    /> */}
                  </div>
                )}
              />
            </div>
            {/* <div className="flex-column w-45 m-t-8">
              <span className="gray-300 mediumM">End Time</span>

              <Controller
                name={`endTime`}
                control={control}
                render={({ field }) => (
                  <div>
                    <TimePickerInputComponent
                      value={field.value}
                      onChange={(date) => field.onChange(date)}
                      icon={<ClockIcon color="#fff" />}
                      placeholderText="End Time"
                    />
                  </div>
                )}
              />
            </div> */}
          </div>
          {/* <div className="flex-row align-center w-90 m-t-12">
            <div className="flex-row justify-space-between align-center">
              <label className="left white align-center">
                <Controller
                  name="rememberMe"
                  control={control}
                  render={({ field }) => <input type="checkbox" {...field} />}
                />
                <span className="regularS">Recurring Event</span>
              </label>
            </div>
            <div className="w-30 m-l-8">
              <FormSelect
                className="m-t-6"
                control={control}
                label="weekly"
                id="weekly"
                options={weeklyOptions}
                // onChange={(e) => setValue("weekly", e.value)}
                // value={"Low"}
                name="weekly"
                placeholder="Every week"
              />
            </div>
          </div> */}
          <div>
            <div className="flex-column w-45 m-t-8">
              <span className="gray-300 mediumM">Location</span>

              <Controller
                name="location"
                control={control}
                render={({ field }) => (
                  <input
                    className="m-t-8 w-90 quick-add-task-container create-project-input"
                    placeholder="Location"
                    {...register("location")}
                  />
                )}
              />
            </div>
          </div>
          <div className="flex-row justify-space-between m-8 p-12">
            <div></div>
            <div>
              <Button
                variety="outlined"
                onClick={() => setIsCreateModalOpen(false)}
              >
                Cancel
              </Button>
              <Button variety="filled">Create Event</Button>
            </div>
          </div>
        </div>
      </LocalizationProvider>
    </form>
  );
};
