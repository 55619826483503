import queryString from "query-string";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { SpotifyIcon } from "../../assets/svg/SpotifyIcon";
import Card from "../Card/Card";
import { InstagramIcon } from "../../assets/svg/InstagramIcon";
import { APP_ROUTES } from "../../utils/app-routes";
import NumberTicker from "../magicui/number-ticker";
import { BorderBeam } from "../magicui/border-beam";

const LinkSpan = styled.span`
  padding-left: 4px;
`;

export const MetricsCardComponent = ({
  platformName,
  audienceCount,
  isView,
  navigate,
  isLinked,
}) => {
  const CLIENT_ID = process.env.REACT_APP_SPOTIFY_CLIENT_ID;
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const AUTH_ENDPOINT = process.env.REACT_APP_SPOTIFY_AUTH_ENDPOINT;
  const RESPONSE_TYPE = "code";
  // const RESPONSE_TYPE = process.env.REACT_APP_SPOTIFY_RESPONSE_TYPE;
  const REDIRECT_URI = process.env.REACT_APP_SPOTIFY_REDIRECT_URI;
  const [type, setType] = useState("");
  const [platformNameColor, setPlatformNameColor] = useState("");
  const [iconName, setIconName] = useState(undefined);

  const typeAsPerPlatform = () => {
    if (platformName === "Spotify"?.toLowerCase()) {
      setType("Listeners");
      setPlatformNameColor("#1DB954");
      setIconName(<SpotifyIcon />);
    } else if (platformName === "Instagram"?.toLowerCase()) {
      setType("Followers");
      setPlatformNameColor("#D62976");
      setIconName(<InstagramIcon />);
    }
  };
  console.log("node env", process.env.NODE_ENV);
  const onClickLogin = async (type) => {
    switch (type) {
      case "Spotify".toLowerCase():
        const queryParams = {
          client_id: CLIENT_ID,
          response_type: RESPONSE_TYPE,
          redirect_uri:
            process.env.NODE_ENV === "development"
              ? "http://localhost:3000/callback"
              : REDIRECT_URI,
          scope: "user-read-private user-read-email", // Add any required scopes
          state: Math.random() * 100000,
        };
        window.location.href = `${AUTH_ENDPOINT}?${queryString.stringify(
          queryParams
        )}`;
        break;

      case "Instagram".toLowerCase():
        window.location.href = `https://www.facebook.com/v19.0/dialog/oauth?client_id=3712894485649552&display=popup&redirect_uri=${
          process.env.NODE_ENV === "development"
            ? "http://localhost:3000/callback/instacallback"
            : "https://genesis-dev.soundofgenesis.com/callback/instacallback"
        }&response_type=token&scope=email,instagram_manage_insights,pages_show_list,business_management`;

        break;
      default:
        break;
    }
  };
  const authHandler = (err, data) => {};
  const handleNavigation = (e) => {
    e.preventDefault();
    // history.push(`/social/${platformName?.toLowerCase()}/view`);
  };

  useEffect(() => {
    typeAsPerPlatform();
  }, [platformName]);

  return (
    <Card className="m-8 p-12 w-100  align-center flex-row p-relative hover:bg-slate-800">
      {iconName}
      <div
        className={`p-12 ${!isView && "cursor-pointer"}`}
        onClick={() =>
          !isView &&
          // isLinked &&
          navigate(APP_ROUTES.socialOverViewPage + "/" + platformName, {
            state: { isLinked: isLinked },
          })
        }
      >
        {/* <BorderBeam size={250} duration={12} delay={5} /> */}
        <span className="white text-capitalize">{platformName}</span>
        <div className="flex-row align-center">
          <span className="white">
            {!isLinked ? 0 : <NumberTicker value={audienceCount} />}
          </span>
          <span className="gray-300"> &nbsp;{type}</span>
        </div>
      </div>
      {!isView && (
        <div
          style={{ position: "absolute", top: 20, right: 20 }}
          className={`${isLinked ? "cursor-disabled" : "cursor-pointer"}`}
          onClick={() => !isLinked && onClickLogin(platformName)}
        >
          <span className="green-400">{isLinked ? "Linked" : "Link"}</span>
        </div>
      )}
    </Card>
  );
};
