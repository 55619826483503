import "../LoginPage/LoginPage.css";
import loginImage from "../../../assets/png/login.png";
import React, { useEffect, useState } from "react";
import { Controller, set, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "../../../components/Button";
import { Logo } from "../../../assets/svg/Logo";
import { useMutationCreate } from "../../../hooks/crud/mutation.create";
import { API_END_POINTS } from "../../../utils/api-endpoint";
import { APP_ROUTES } from "../../../utils/app-routes";
import {
  GENESIS_USER_EMAIL,
  GENESIS_USER_SIGNUP,
  GENESIS_USER_TOKEN,
  USER_DETAILS,
} from "../../../utils/constants";
import { useNavigate } from "react-router";
import { GoogleIcon } from "../../../assets/svg/GoogleIcon";
import { EyeNoneIcon, EyeOpenIcon } from "@radix-ui/react-icons";
import { useGoogleLogin } from "@react-oauth/google";
import { saveState } from "../../../utils/storageUtil";
import axios from "axios";
import { notify } from "../../../components/Toast/ToastComponent";

const Signup = () => {
  const navigate = useNavigate();
  const [headers, setHeaders] = useState({}); //[1
  const { mutate: googleAuth } = useMutationCreate(
    API_END_POINTS.googleSignIn,
    headers
  );

  const login = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      // const userInfo = await axios({
      //   url: "https://www.googleapis.com/oauth2/v3/userinfo",
      //   method: "GET",
      //   headers: {
      //     Authorization: `Bearer ${codeResponse.access_token}`,
      //   },
      // });
      setHeaders({ Authorization: `Bearer ${codeResponse.access_token}` });
      setTimeout(() => {
        googleAuth(
          {},
          {
            onSuccess(res) {
              if (
                res?.data?.isFirstTimeSocialLogin ||
                !res?.data?.isPaymentVerified
              ) {
                localStorage.setItem(USER_DETAILS, JSON.stringify(res));
                localStorage.setItem(GENESIS_USER_TOKEN, res?.token);
                saveState(GENESIS_USER_SIGNUP, true);
                saveState(GENESIS_USER_EMAIL, res?.data?.emailAddress);
                navigate(APP_ROUTES.paymentPlan, {
                  email: res?.data?.emailAddress,
                  token: res?.token,
                });
                notify("Please complete your payment to continue", "success");
              } else {
                saveState(GENESIS_USER_TOKEN, res?.token);
                saveState(GENESIS_USER_SIGNUP, false);
                saveState(GENESIS_USER_EMAIL, res?.data?.emailAddress);
                navigate(APP_ROUTES.root);
                localStorage.setItem(USER_DETAILS, JSON.stringify(res));
                notify(res?.message, "success");
              }
            },
          },
          {
            onError(err) {
              notify(err?.message, "error");
            },
          }
        );
      }, [400]);
    },
    onError: (error) => {
      notify(error.error_description, "error");
    },
  });
  return (
    <>
      <div className="w-full flex">
        <div className="login-banner w-50">
          <img className="w-full h-screen" src={loginImage} alt="Login"></img>
        </div>

        <div className="box">
          <div className="form-side">
            <div
              style={{
                height: "3.125rem;",
                width: "11.53rem",
                marginBottom: "3.12rem",
              }}
            >
              <Logo />
            </div>
            <div>
              <span className="h4 white">Create an account</span>
              <br />
              <span className="regularS gray-300">
                Enter your details to create your Genesis account
              </span>
              <Button
                type="submit"
                variety="secondary"
                className="flex-row margin-24"
                height="50px"
                onClick={login}
              >
                <GoogleIcon />
                Continue with Google
              </Button>
            </div>
            <div className="flex-row m-t-24 justify-space-between align-center">
              <hr className="solid gray-500 w-25" />
              <span className="gray-300 regularXS">
                or sign up with your email
              </span>
              <hr className="solid w-25" />
            </div>
            <div className="w-full">
              <SignUpForm navigate={navigate} />
            </div>
            <div
              className="text-align-center cursor-pointer m-t-12"
              onClick={() => navigate(APP_ROUTES.login)}
            >
              <span className="gray-300 regularL">
                Already have an account?
              </span>
              <span className="green-400 semiboldM"> Log In</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;

const schema = yup.object().shape({
  fullName: yup.string().required("Full Name is required"),
  email: yup.string().required("Email is required"),
  password: yup.string().required("Password is required"),
});

const SignUpForm = ({ navigate }) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [hasAgreedToTerms, setHasAgreedToTerms] = useState(false);
  const { mutate: signUpUserDetails, isLoading } = useMutationCreate(
    API_END_POINTS.register
  );
  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    register,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  });
  //
  // console.log("errors", isError);

  const handleAgreementChange = (event) => {
    setHasAgreedToTerms(event.target.checked);
  };

  const checkboxValue = watch("checked"); // This will get the current value of the checkbox

  const onSubmit = (data) => {
    setIsDisabled(true);
    signUpUserDetails(data, {
      onSuccess(res) {
        setIsDisabled(false);
        navigate(APP_ROUTES.otpVerification, { state: { email: data?.email } });
        localStorage.setItem(USER_DETAILS, JSON.stringify(res));
        notify(res?.message, "success");
      },
      onError: (err) => {
        setIsDisabled(false);
        // make sure something isn't stuck in local storage, like malformed token
        // this is signup so nothing should be in local storage at this point
        localStorage.clear();
        notify(err?.error?.message, "error");
      },
    });
  };

  useEffect(() => {
    setIsDisabled(!checkboxValue); // This will disable the button if the checkbox is not checked
  }, [checkboxValue]);

  return (
    <form className="m-t-24 w-full" onSubmit={handleSubmit(onSubmit)}>
      <div>
        <Controller
          name="fullname"
          control={control}
          render={({ field }) => (
            <input
              className="w-full mt-4"
              placeholder="Full Name"
              type="text"
              {...register("fullName")}
            />
          )}
        />
        <p
          style={{
            color: "var(--App-Red)",
          }}
        >
          {errors.fullName?.message}
        </p>
      </div>

      <div>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <input
              className="w-full mt-4"
              placeholder="Email"
              type="email"
              {...register("email")}
            />
          )}
        />
        <p
          style={{
            color: "var(--App-Red)",
          }}
        >
          {errors.email?.message}
        </p>
      </div>

      <div>
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <div style={{ position: "relative" }}>
              <input
                className="w-full mt-4"
                placeholder="Password"
                type={showPassword ? "text" : "password"}
                {...field}
                {...register("password")}
              />
              <span
                style={{
                  position: "absolute",
                  right: "15px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
                onClick={() => setShowPassword(!showPassword)}
              >
                {!showPassword ? (
                  <EyeOpenIcon color="var(--Genesis-Gray-300)" fontSize={42} />
                ) : (
                  <EyeNoneIcon color="var(--Genesis-Gray-300)" />
                )}{" "}
                {/* Change the icon based on the state variable */}
              </span>
            </div>
          )}
        />
        <p
          style={{
            color: "var(--App-Red)",
          }}
        >
          {errors.password?.message}
        </p>
      </div>

      <div className="remember-me-container mt-4">
        <label className="left gray-300 regularXS align-center">
          <Controller
            name="checked"
            control={control}
            render={({ field }) => (
              <input
                checked={hasAgreedToTerms}
                onChange={handleAgreementChange}
                type="checkbox"
                id="agreement"
                // {...field}
              />
            )}
          />
          I agree with Genesis Studio's&nbsp;
          <a
            href={process.env.REACT_APP_TERMS_OF_SERVICE_URL}
            target="_blank"
            rel="noopener noreferrer"
            className="green-400 regularXS"
          >
            User Agreement
          </a>{" "}
          &nbsp;and&nbsp;
          <a
            href={process.env.REACT_APP_PRIVACY_POLICY_URL}
            target="_blank"
            rel="noopener noreferrer"
            className="green-400 regularXS"
          >
            Privacy Policy
          </a>
        </label>
      </div>

      <Button
        disabled={!hasAgreedToTerms}
        type="submit"
        variety="filled"
        className="primary-button w-100 black"
        height="56px"
        isLoading={isLoading}
      >
        {"Continue"}
      </Button>
    </form>
  );
};
