import { EditOutlined } from "../../assets/svg/EditOutlined";
import { EngagementIcon } from "../../assets/svg/EngagementIcon";
import { GrowthIcon } from "../../assets/svg/GrowthIcon";
import { ImpressionIcon } from "../../assets/svg/ImpressionIcon";
import { UserIcon } from "../../assets/svg/UserIcon";

export const cardData = [
  {
    id: 1,
    title: "Total Post",
    value: "170",
    icon: <EditOutlined color="rgba(0, 229, 190, 1)" />,
  },
  { id: 2, title: "Impressions", value: "200", icon: <ImpressionIcon /> },
  { id: 3, title: "Profile Views", value: "200", icon: <GrowthIcon /> },
  { id: 4, title: "Engagement rate", value: "200", icon: <EngagementIcon /> },
];

export const genderData = [
  { id: 1, title: "Male", value: "10%" },
  { id: 2, title: "Female", value: "80%" },
  { id: 3, title: "User Specified", value: "1%" },
];

export const data = [
  {
    name: "July 1",
    value: 3,
  },
  {
    name: "July 2",
    value: 4,
  },
  {
    name: "July 3",
    value: 9,
  },
  {
    name: "July 4",
    value: 10,
  },
  {
    name: "July 5",
    value: 1,
  },
  {
    name: "July 6",
    value: 2,
  },
  {
    name: "July 7",
    value: 6,
  },
  {
    name: "July 8",
    value: 1,
  },
  {
    name: "July 9",
    value: 9,
  },
  {
    name: "July 10",
    value: 7,
  },
];

export const periodDataType = [
  { id: 1, label: "Today", value: "today" },
  { id: 2, label: "Weekly", value: "week" },
  { id: 3, label: "Monthly", value: "month" },
];
