export const TrashIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0005 5.00049V15.6255C15.0005 16.6613 14.1447 17.5005 13.1097 17.5005H6.85965C5.82382 17.5005 5.00049 16.6613 5.00049 15.6255V5.00049"
        stroke="#EF4870"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.25 4.99967H3.75"
        stroke="#EF4870"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.33301 2.49967H11.6663"
        stroke="#EF4870"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6667 8.33301V14.1663"
        stroke="#EF4870"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.33317 14.1663V8.33301"
        stroke="#EF4870"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
