import React from "react";

export const GoogleIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clip-path="url(#clip0_560_8493)">
        <path
          d="M15.545 6.55798C15.6383 7.09494 15.6848 7.63898 15.684 8.18398C15.684 10.618 14.814 12.676 13.3 14.069H13.302C11.978 15.292 10.158 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6568C0.842855 12.1565 0 10.1217 0 7.99998C0 5.87825 0.842855 3.84342 2.34315 2.34312C3.84344 0.842833 5.87827 -2.14298e-05 8 -2.14298e-05C9.98594 -0.0232798 11.9038 0.722811 13.352 2.08198L11.068 4.36598C10.2424 3.57895 9.1405 3.14797 8 3.16598C5.913 3.16598 4.14 4.57398 3.508 6.46998C3.17291 7.46348 3.17291 8.53947 3.508 9.53298H3.511C4.146 11.426 5.916 12.834 8.003 12.834C9.081 12.834 10.007 12.558 10.725 12.07H10.722C11.1389 11.7938 11.4955 11.436 11.7704 11.0182C12.0452 10.6003 12.2325 10.1312 12.321 9.63898H8V6.55898L15.545 6.55798Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_560_8493">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
