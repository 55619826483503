import React, { useEffect } from "react";
import Sidebar from "../Sidebar";
import Footer from "../Footer";
import Header from "../Header";
import {
  SidebarProvider,
  useIsSidebarOpen,
} from "../../hooks/ui/IsSidebarOpen";
import { Outlet, useNavigate } from "react-router-dom";
import useWindowSize from "../../hooks/ui/useWindowSize";
import { USER_DETAILS } from "../../utils/constants";
import { APP_ROUTES } from "../../utils/app-routes";
import { useDataQuery } from "../../hooks/crud/data.query";
import { API_END_POINTS } from "../../utils/api-endpoint";
import { routeToCorrectStep } from "src/pages/Auth/LoginPage/loginHelpers";

const PrivateLayout = ({ children }) => {
  const navigate = useNavigate();
  const isAuth = localStorage.getItem(USER_DETAILS);
  const userDetails = JSON.parse(isAuth);
  const { data: userData } = useDataQuery({ url: API_END_POINTS.profile });

  useEffect(() => {
    if (!(userDetails && userDetails.token)) {
      navigate(APP_ROUTES.login);
      localStorage.clear();
    }
    else {
      routeToCorrectStep(userDetails, navigate);
    }
  }, []);

  return (
    <>
      <SidebarProvider>
        <PrivateLayoutContent>
          <Outlet></Outlet>
        </PrivateLayoutContent>
      </SidebarProvider>
    </>
  );
};

export default PrivateLayout;

const PrivateLayoutContent = ({ children }) => {
  const { isSidebarOpen, setIsSidebarOpen } = useIsSidebarOpen();
  const { width } = useWindowSize();

  let result;
  if (width > 900) {
    if (isSidebarOpen) {
      result = width * 0.79;
    } else {
      result = width * 0.94;
    }
  } else {
    result = width * 0.9;
  }

  return (
    <div
      style={{
        display: "flex",
        backgroundColor: "#150F23",
        minHeight: "100vh",
      }}
    >
      <div
        style={{
          flex: isSidebarOpen ? 1 : 0.5,
          position: "fixed",
          top: 0,
          overflow: "auto",
        }}
      >
        <Sidebar />
      </div>

      <div
        className="ml-40 md:ml-72"
        style={{
          flex: isSidebarOpen ? 5 : 100,
          // marginLeft: 250,
          transition: "flex 0.5s ease-in-out, width 0.5s ease-in-out",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            marginRight: "2.5rem",
            marginLeft: "2.5rem",
            width: result,
          }}
        >
          <Header></Header>
          {children}
          <Footer></Footer>
        </div>
      </div>
    </div>
  );
};
