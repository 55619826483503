import React, { useEffect } from "react";
// import { Chart } from "react-google-charts";
import {
  Gantt,
  Task,
  EventOption,
  StylingOption,
  ViewMode,
  DisplayOption,
} from "gantt-task-react";
import "gantt-task-react/dist/index.css";
import { getStartEndDateForProject } from "../../utils/gantt-data";
import { ViewSwitcher } from "./ViewSwitcher";
import "./Gantt.css";
import { API_END_POINTS } from "../../utils/api-endpoint";
import { useDataQuery } from "../../hooks/crud/data.query";
import _ from "lodash";

export const GanttChart = ({ timelineData }) => {
  const [view, setView] = React.useState(ViewMode.Month);

  const [isChecked, setIsChecked] = React.useState(true);
  const {
    data: dashboardData,
    refetch: refetchDashboardData,
    error: dashboardError,
  } = useDataQuery({
    url: API_END_POINTS.dashboard,
    params: {},
  });
  function initTasks(apiResponse) {
    console.log('Full timelineData:', apiResponse); // See complete structure
    
    if (!apiResponse) return [];
    
    // If data is nested in a 'data' property
    const tasksData = Array.isArray(apiResponse) ? apiResponse : apiResponse.data;
    
    const tasks = tasksData.map((task) => {
      console.log('Raw task data:', task); // See individual task structure
      
      // Check if dates are nested differently
      const startDate = task.start
      const endDate = task.end
      
      console.log('Found dates:', { startDate, endDate });
      
      // Validate dates and log any issues
      console.log('Processing task:', task.title, {
        startDate,
        endDate
      });

      // Ensure valid dates with fallbacks
      let start, end;
      try {
        start = startDate ? new Date(startDate) : new Date();
        end = endDate ? new Date(endDate) : new Date();
        
        // Validate that dates are valid
        if (isNaN(start.getTime()) || isNaN(end.getTime())) {
          console.error('Invalid date for task:', task.title);
          // Fallback to current date if invalid
          start = new Date();
          end = new Date(new Date().getTime() + 86400000); // +1 day
        }

        // Ensure end is after start
        if (end < start) {
          end = new Date(start.getTime() + 86400000); // +1 day
        }
      } catch (error) {
        console.error('Date parsing error for task:', task.title, error);
        start = new Date();
        end = new Date(new Date().getTime() + 86400000);
      }

      return {
        start,
        end,
        name: task.name || 'Untitled Task',
        id: task.id,
        progress: Number(task.progress) || 0,
        type: task.project ? "task" : "project",
        project: task.project?._id?.toString() || null,
        displayOrder: Number(task.displayOrder) || 0,
        hideChildren: false
      };
    }).filter(task => {
      // Filter out any potentially invalid tasks
      const isValid = task.start && task.end && 
                     !isNaN(task.start.getTime()) && 
                     !isNaN(task.end.getTime());
      if (!isValid) {
        console.error('Filtering out invalid task:', task);
      }
      return isValid;
    });

    console.log('Processed tasks:', tasks); // Debug log
    return tasks;
  }

  const [tasks, setTasks] = React.useState(() => {
    try {
      return initTasks(timelineData);
    } catch (error) {
      console.error('Error initializing tasks:', error);
      return [];
    }
  });

  // Add effect to handle updates
  useEffect(() => {
    try {
      const newTasks = initTasks(timelineData);
      setTasks(newTasks);
    } catch (error) {
      console.error('Error updating tasks:', error);
    }
  }, [timelineData]);

  // Add validation before rendering
  if (!tasks.length) {
    return <span className="white">No valid tasks available for timeline view</span>;
  }

  const handleTaskDelete = (task) => {
    const conf = window.confirm("Are you sure about " + task.name + " ?");
    if (conf) {
      setTasks(tasks.filter((t) => t.id !== task.id));
    }
    return conf;
  };

  const handleProgressChange = async (task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
  };

  const handleDblClick = (task) => {};

  const handleClick = (task) => {};

  const handleSelect = (task, isSelected) => {};

  const handleExpanderClick = (task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
  };

  return (
    <div className="Wrapper">
      <ViewSwitcher
        onViewModeChange={setView}
        onViewListChange={setIsChecked}
        isChecked={isChecked}
        viewMode={view}
      />

      {!_.isEmpty(tasks) ? (
        <Gantt
          tasks={tasks}
          viewMode={view}
          listCellWidth="240px"
          columnWidth={65}
          rowHeight={50}
          headerHeight={50}
          barCornerRadius={4}
          projectBackgroundColor="rgba(68, 115, 218, 0.2)"
          projectProgressColor="#4473DA"
          projectProgressSelectedColor="#4473DA"
          barProgressColor="#4473DA"
          barBackgroundColor="rgba(68, 115, 218, 0.4)"
          gridLineColor="rgba(255, 255, 255, 0.1)"
          todayColor="rgba(255, 255, 255, 0.1)"
          rtl={false}
          onDelete={handleTaskDelete}
          onProgressChange={handleProgressChange}
          onDoubleClick={handleDblClick}
          onClick={handleClick}
          onSelect={handleSelect}
          onExpanderClick={handleExpanderClick}
        />
      ) : (
        <span className="white">
          You have not created any projects to be shown on timeline yet
        </span>
      )}
    </div>
  );
};
