import React from "react";

export const CalendarIcon = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M13.3332 1.66669V5.00002"
      stroke={color ? color : "#EF4870"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.66667 1.66669V5.00002"
      stroke={color ? color : "#EF4870"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.5 7.50004H17.5"
      stroke={color ? color : "#EF4870"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.8333 3.33337H4.16667C3.24583 3.33337 2.5 4.07921 2.5 5.00004V15.8334C2.5 16.7542 3.24583 17.5 4.16667 17.5H15.8333C16.7542 17.5 17.5 16.7542 17.5 15.8334V5.00004C17.5 4.07921 16.7542 3.33337 15.8333 3.33337Z"
      stroke={color ? color : "#EF4870"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.0111 10.6075C9.89605 10.6075 9.80272 10.7008 9.80355 10.8158C9.80355 10.9308 9.89688 11.0242 10.0119 11.0242C10.1269 11.0242 10.2202 10.9308 10.2202 10.8158C10.2202 10.7008 10.1269 10.6075 10.0111 10.6075"
      stroke={color ? color : "#EF4870"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.1776 10.6075C14.0626 10.6075 13.9692 10.7008 13.9701 10.8158C13.9701 10.9308 14.0634 11.0242 14.1784 11.0242C14.2934 11.0242 14.3867 10.9308 14.3867 10.8158C14.3867 10.7008 14.2934 10.6075 14.1776 10.6075"
      stroke={color ? color : "#EF4870"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.84406 13.9409C5.72906 13.9409 5.63573 14.0342 5.63656 14.1492C5.63656 14.2642 5.72989 14.3575 5.84489 14.3575C5.95989 14.3575 6.05323 14.2642 6.05323 14.1492C6.05323 14.0342 5.95989 13.9409 5.84406 13.9409"
      stroke={color ? color : "#EF4870"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.0111 13.9409C9.89605 13.9409 9.80272 14.0342 9.80355 14.1492C9.80355 14.2642 9.89688 14.3575 10.0119 14.3575C10.1269 14.3575 10.2202 14.2642 10.2202 14.1492C10.2202 14.0342 10.1269 13.9409 10.0111 13.9409"
      stroke={color ? color : "#EF4870"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
