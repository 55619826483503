import axios from "axios";
import { USER_DETAILS } from "../utils/constants";

const userDetails = localStorage.getItem(USER_DETAILS);
let userDetailsJson = userDetails ? JSON.parse(userDetails) : undefined;
const token = userDetailsJson?.token;

export const getUserProfile = async () => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/users/profile`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res?.data;
  } catch (err) {
    console.log("err", err);
  }
};
