export const PlusIconOutlined = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clip-path="url(#clip0_83_3341)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.41683 12.5002C7.41683 12.9144 7.75262 13.2502 8.16683 13.2502C8.58104 13.2502 8.91683 12.9144 8.91683 12.5002V8.91683H12.5002C12.9144 8.91683 13.2502 8.58104 13.2502 8.16683C13.2502 7.75262 12.9144 7.41683 12.5002 7.41683H8.91683V3.8335C8.91683 3.41928 8.58104 3.0835 8.16683 3.0835C7.75261 3.0835 7.41683 3.41928 7.41683 3.8335V7.41683H3.8335C3.41928 7.41683 3.0835 7.75262 3.0835 8.16683C3.0835 8.58104 3.41928 8.91683 3.8335 8.91683H7.41683V12.5002Z"
          fill="#1AFFD8"
        />
      </g>
      <defs>
        <clipPath id="clip0_83_3341">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
