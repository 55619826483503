export const cardData = [
  {
    id: 1,
    planType: "Intro Plan",
    subtitle: "Lorem ipsum dolor sit amet consectetur.",
    price: 50,
    duration: "month",

    pros: [
      { id: 1, title: "Project Management", available: true },
      { id: 2, title: "Calendar", available: true },
      { id: 3, title: "Task Management", available: true },
      { id: 4, title: "Milestone Tracking", available: true },
      {
        id: 5,
        title: "Social Media Metrics",
        available: true,
      },
      { id: 6, title: " Streaming Metrics", available: true },
    ],
  },
  {
    id: 2,
    planType: "Basic Plan",
    subtitle: "Lorem ipsum dolor sit amet consectetur.",
    price: 250,
    duration: "month",

    pros: [
      { id: 1, title: "All Intro Features", available: true },
      {
        id: 2,
        title: "Bi-Weekly Meetings With Dedicated Rep",
        available: true,
      },
      { id: 3, title: "Access to Discord", available: true },
    ],
  },
  {
    id: 3,
    planType: "Pro Plan",
    subtitle: "Lorem ipsum dolor sit amet consectetur.",
    price: 500,
    duration: "month",

    pros: [
      { id: 1, title: "All Intro & Basic Features", available: true },
      { id: 2, title: "Weekly Meetings With Dedicated Rep", available: true },
      { id: 3, title: "Dedicated Task Execution From Reps", available: true },
      { id: 4, title: "Exclusive Service Discounts", available: true },
    ],
  },
];
