import React from "react";

export const SocialIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
  >
    <path
      d="M16.893 4.48475L7.91699 4.48303C7.91699 4.48303 5.83253 5.98847 4.5 6.95086V19.0322C5.83253 19.9946 6.58446 20.5376 7.91699 21.5H16.952C18.3345 20.5376 19.1174 19.9946 20.5023 19.0322V6.95258C19.1174 5.99019 18.3369 5.44713 16.952 4.48475L16.893 4.48475Z"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M15.5193 3.5H9.30159C9.03875 3.5 8.82568 3.65388 8.82568 3.84371V4.88171C8.82568 5.07153 9.03875 5.22542 9.30159 5.22542H15.5193C15.7821 5.22542 15.9952 5.07153 15.9952 4.88171V3.84371C15.9952 3.65388 15.7821 3.5 15.5193 3.5Z"
      fill="#9E9CAE"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <mask id="path-3-inside-1_650_964" fill="white">
      <path d="M8.82342 8.67798H7.62891V9.54069H8.82342V8.67798Z" />
    </mask>
    <path
      d="M8.82342 8.67798H7.62891V9.54069H8.82342V8.67798Z"
      fill="#9E9CAE"
    />
    <path
      d="M7.62891 8.67798V7.17798H6.12891V8.67798H7.62891ZM8.82342 8.67798H10.3234V7.17798H8.82342V8.67798ZM8.82342 9.54069V11.0407H10.3234V9.54069H8.82342ZM7.62891 9.54069H6.12891V11.0407H7.62891V9.54069ZM7.62891 10.178H8.82342V7.17798H7.62891V10.178ZM7.32342 8.67798V9.54069H10.3234V8.67798H7.32342ZM8.82342 8.04069H7.62891V11.0407H8.82342V8.04069ZM9.12891 9.54069V8.67798H6.12891V9.54069H9.12891Z"
      fill="white"
      mask="url(#path-3-inside-1_650_964)"
    />
    <mask id="path-5-inside-2_650_964" fill="white">
      <path d="M17.19 8.67798H10.0205V9.54069H17.19V8.67798Z" />
    </mask>
    <path d="M17.19 8.67798H10.0205V9.54069H17.19V8.67798Z" fill="#9E9CAE" />
    <path
      d="M10.0205 8.67798V7.17798H8.52051V8.67798H10.0205ZM17.19 8.67798H18.69V7.17798H17.19V8.67798ZM17.19 9.54069V11.0407H18.69V9.54069H17.19ZM10.0205 9.54069H8.52051V11.0407H10.0205V9.54069ZM10.0205 10.178H17.19V7.17798H10.0205V10.178ZM15.69 8.67798V9.54069H18.69V8.67798H15.69ZM17.19 8.04069H10.0205V11.0407H17.19V8.04069ZM11.5205 9.54069V8.67798H8.52051V9.54069H11.5205Z"
      fill="white"
      mask="url(#path-5-inside-2_650_964)"
    />
    <mask id="path-7-inside-3_650_964" fill="white">
      <path d="M8.82342 11.2661H7.62891V12.1288H8.82342V11.2661Z" />
    </mask>
    <path
      d="M8.82342 11.2661H7.62891V12.1288H8.82342V11.2661Z"
      fill="#9E9CAE"
    />
    <path
      d="M7.62891 11.2661V9.76611H6.12891V11.2661H7.62891ZM8.82342 11.2661H10.3234V9.76611H8.82342V11.2661ZM8.82342 12.1288V13.6288H10.3234V12.1288H8.82342ZM7.62891 12.1288H6.12891V13.6288H7.62891V12.1288ZM7.62891 12.7661H8.82342V9.76611H7.62891V12.7661ZM7.32342 11.2661V12.1288H10.3234V11.2661H7.32342ZM8.82342 10.6288H7.62891V13.6288H8.82342V10.6288ZM9.12891 12.1288V11.2661H6.12891V12.1288H9.12891Z"
      fill="#9E9CAE"
      mask="url(#path-7-inside-3_650_964)"
    />
    <path d="M17.19 11.2661H10.0205V12.1288H17.19V11.2661Z" fill="#9E9CAE" />
    <path d="M8.82342 13.856H7.62891V14.7187H8.82342V13.856Z" fill="#9E9CAE" />
    <path d="M17.19 13.856H10.0205V14.7187H17.19V13.856Z" fill="#9E9CAE" />
    <path d="M8.82342 16.444H7.62891V17.3067H8.82342V16.444Z" fill="#9E9CAE" />
    <path d="M17.19 16.444H10.0205V17.3067H17.19V16.444Z" fill="#9E9CAE" />
  </svg>
);
