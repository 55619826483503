import React from "react";

export const LeftArrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
  >
    <path d="M5.5 8L11.5 4V12L5.5 8Z" fill="#5E656B" />
  </svg>
);
