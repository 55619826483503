import React, { FC } from "react";
import { Controller } from "react-hook-form";
import RSelect from "./ReactSelect";

const FormSelect = (props) => {
  const {
    control,
    options,
    name,
    rules,
    getOptionLabel,
    getOptionValue,

    isLoading,
    placeholder,
    ...rest
  } = props;
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field }) => (
        <RSelect
          {...field}
          className="mt-3"
          // @ts-ignore
          getOptionLabel={getOptionLabel}
          // @ts-ignore
          getOptionValue={getOptionValue}
          // @ts-ignore
          isLoading={isLoading}
          options={options}
          // @ts-ignore
          placeholder={placeholder}
          // 2ts-ignore
          {...rest}
        />
      )}
    />
  );
};

export default FormSelect;
