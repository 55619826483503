export const PlatformData = [
  {
    id: 1,
    platform: "Spotify",
    icon: "spotify",
    color: "#1DB954",
    description: "Music streaming service",
    link: "https://www.spotify.com/",
  },
  {
    id: 2,
    platform: "Apple Music",
    icon: "apple",
    color: "#000",
    description: "Music streaming service",
    link: "https://www.apple.com/apple-music/",
  },
  {
    id: 3,
    platform: "YouTube",
    icon: "youtube",
    color: "#FF0000",
    description: "Video sharing platform",
    link: "https://www.youtube.com/",
  },
];

export const DashboardTab = [
  { id: 1, tabName: "Tasks" },
  { id: 2, tabName: "Projects" },
  { id: 3, tabName: "Events" },
];

export const ViewType = [
  { id: 1, name: "List View" },
  { id: 2, name: "Calendar View" },
];

export const EventViewType = [
  { id: 1, name: "Timeline View" },
  { id: 2, name: "List View" },
];

export const ChartDropDown = [
  { id: 1, value: "Weekly", label: "Week" },
  { id: 2, value: "Monthly", label: "Month" },
  { id: 3, value: "Yearly", label: "Year" },
];
